import React, { useState } from 'react';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    TablePagination
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export interface IRows {
    id: string;
    values: React.ReactNode[];
}
[];
export interface TableProps {
    rows: IRows[];
    columns: string[];
    showPagination?: boolean;
    allowFilter?: boolean;
    allowStyledColumns?: boolean;
    // eslint-disable-next-line no-unused-vars
    onCellClick?: (rowId: string) => void;
    variant: TABLE_VARIANT;
}

const StyledTableHead = styled(TableHead)<{ showBorderRight: boolean }>(({ showBorderRight }) => ({
    '& .MuiTableCell-root': {
        backgroundColor: '#DDEAFF',
        borderRight: showBorderRight ? '2px solid #fff' : 'none',
        '&:last-child': {
            borderRight: 'none'
        }
    }
}));
const sortOrder: Record<string, 'asc' | 'desc' | 'default'> = {
    asc: 'desc',
    desc: 'default',
    default: 'asc'
};

export enum TABLE_VARIANT {
    // eslint-disable-next-line no-unused-vars
    BASIC = 'basic',
    // eslint-disable-next-line no-unused-vars
    STYLED_COLUMN = 'styled_column',
    // eslint-disable-next-line no-unused-vars
    EXHIBIT = 'exhibit'
}
export const DataTable = ({
    rows,
    columns,
    allowFilter = false,
    showPagination = false,
    onCellClick,
    variant = TABLE_VARIANT.BASIC
}: TableProps) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);
    const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' | 'default' }>({
        key: '',
        direction: 'default'
    });

    const handleSort = (column: string) => {
        const direction = (sortConfig && sortConfig.key === column && sortOrder[sortConfig.direction]) || 'asc';
        setSortConfig({ key: column, direction });
    };

    const sortedRows = rows.slice().sort((a, b) => {
        if (!sortConfig) return 0;
        const columnToSortBy = sortConfig.key;
        const index = columns.indexOf(columnToSortBy);
        let aValue = a.values[index];
        let bValue = b.values[index];

        if (aValue == null && bValue == null) return 0;
        if (aValue == null) return sortConfig.direction === 'asc' ? -1 : 1;
        if (bValue == null) return sortConfig.direction === 'asc' ? 1 : -1;

        if (typeof aValue === 'undefined' || aValue === null) aValue = '';
        if (typeof bValue === 'undefined' || bValue === null) bValue = '';

        if (aValue.toLocaleString().toLowerCase() < bValue.toLocaleString().toLowerCase())
            return sortConfig.direction === 'asc' ? -1 : 1;
        if (aValue.toLocaleString().toLowerCase() > bValue.toLocaleString().toLowerCase())
            return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
    });

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setCurrentPage(0);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const hasMatchingChild = (
        row: React.ReactPortal | React.ReactElement<unknown, string | React.JSXElementConstructor<any>>,
        searchTerm: string
    ) => {
        const children = Array.isArray(row.props.children) ? row.props.children : [row.props.children];
        return children.some(
            (child: unknown) => typeof child === 'string' && child.toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    const exhibitColumnsStyle = {
        height: '24px',
        lineHeight: '24px',
        padding: '0px 16px',
        fontSize: '8px',
        fontWeight: '600'
    };

    const exhibitBodyStyle = {
        height: '24px',
        lineHeight: '20px',
        fontSize: '10px',
        fontWeight: '400',
        textAlign: 'center',
        padding: '0px 16px',
        border: 0
    };
    return (
        <div>
            {allowFilter && (
                <TextField
                    label="Search"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{ marginBottom: '1rem' }}
                />
            )}
            <TableContainer>
                <Table>
                    {variant === TABLE_VARIANT.STYLED_COLUMN || variant === TABLE_VARIANT.EXHIBIT ? (
                        <StyledTableHead showBorderRight={variant === TABLE_VARIANT.STYLED_COLUMN}>
                            <TableRow>
                                {columns.map((column, index) => (
                                    <TableCell
                                        sx={{
                                            cursor: 'pointer',
                                            textAlign: 'center',
                                            ...(variant === TABLE_VARIANT.EXHIBIT ? exhibitColumnsStyle : {})
                                        }}
                                        onClick={() => handleSort(column)}
                                        key={index}
                                    >
                                        {column === sortConfig.key &&
                                            (sortConfig?.direction === 'asc' ? (
                                                <ArrowUpwardIcon fontSize="inherit" />
                                            ) : sortConfig?.direction === 'desc' ? (
                                                <ArrowDownwardIcon fontSize="inherit" />
                                            ) : undefined)}{' '}
                                        {column}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </StyledTableHead>
                    ) : (
                        <TableHead>
                            <TableRow>
                                {columns.map((column, index) => (
                                    <TableCell key={index}>{column}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                    )}
                    <TableBody>
                        {sortedRows
                            .filter(({ values }) => {
                                if (!allowFilter) return true;
                                return values.some((value) => {
                                    if (typeof value === 'string' || typeof value === 'number') {
                                        return `${value}`.toLowerCase().includes(searchTerm.toLocaleLowerCase());
                                    }
                                    return React.isValidElement(value) && hasMatchingChild(value, searchTerm);
                                });
                            })
                            .slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage)
                            .map(({ id, values }, index) => (
                                <TableRow
                                    key={id}
                                    sx={{
                                        ...(variant === TABLE_VARIANT.EXHIBIT && index % 2 === 1
                                            ? { background: '#F4F4F4' }
                                            : {})
                                    }}
                                >
                                    {values.map((column) => {
                                        return (
                                            <TableCell
                                                sx={{
                                                    ...(variant === TABLE_VARIANT.EXHIBIT ? exhibitBodyStyle : {})
                                                }}
                                                onClick={() => onCellClick?.(id)}
                                                key={column?.toString()}
                                            >
                                                {column}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {showPagination && (
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
        </div>
    );
};

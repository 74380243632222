import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { PATPalette, customPalette } from '../../../themes/palette';
import { useTranslation } from 'react-i18next';
import emotionStyled from '@emotion/styled';
import { ActionOption, MoreActionsMenu } from '../MoreActionsMenu';

export interface UniversalCardProps {
    header?: string;
    subcontent?: React.ReactNode;
    children: React.ReactNode | React.ReactNode[];
    headerIcon?: React.ReactNode | React.ReactNode[];
    cardSx?: object;
    onClick?: () => void;
    showSubcontent?: boolean;
    showOptional?: boolean;
    showViewAll?: boolean;
    // eslint-disable-next-line no-unused-vars
    onClickViewAll?: (flag: boolean) => void;
    actionMenuOptions?: ActionOption[];
}

export const UniversalCard = ({
    header,
    children,
    subcontent,
    cardSx,
    headerIcon,
    // onClick,
    showSubcontent,
    showOptional,
    showViewAll,
    onClickViewAll,
    actionMenuOptions
}: UniversalCardProps) => {
    const { t } = useTranslation();

    const StyledCard = styled(Card)({
        margin: 0,
        padding: '12px 24px',
        ...cardSx
    });

    const handleViewAll = () => {
        if (onClickViewAll) onClickViewAll(true);
    };

    const cardHeader = (
        <StyledHeaderDiv>
            <StyledHeading>
                {header} {showOptional && <span>(Optional)</span>}
            </StyledHeading>
        </StyledHeaderDiv>
    );

    return (
        <StyledCard>
            {header && (
                <>
                    <StyledCardHeader
                        avatar={headerIcon}
                        action={
                            actionMenuOptions ? (
                                <MoreActionsMenu options={actionMenuOptions} />
                            ) : undefined
                        }
                        title={cardHeader}
                    ></StyledCardHeader>
                    <StyledDivider />
                </>
            )}
            {(showSubcontent || showViewAll) && (
                <StyledSubHeaderSection>
                    {showSubcontent && <StyledSubcontent>{subcontent}</StyledSubcontent>}
                    {showViewAll && (
                        <StyledheaderViewAll onClick={handleViewAll}>
                            {t('VIEW_ALL_FUNDING_SOURCES')}
                        </StyledheaderViewAll>
                    )}
                </StyledSubHeaderSection>
            )}
            <StyledCardContent>{children}</StyledCardContent>
        </StyledCard>
    );
};

const StyledCardHeader = styled(CardHeader)({
    padding: '12px 0',
    '& .MuiCardHeader-root': {
        '& .MuiCardHeader-avatar': {
            marginRight: '12px'
        },
        '& .MuiSvgIcon-root': {
            fontWeight: '900'
        }
    },
    '& .MuiSvgIcon-root': {
        fill: PATPalette.neutral.grey[400]
    }
});

const StyledHeading = emotionStyled('h4')(({ theme }) => ({
    ...theme.typography.headers.h4,
    color: PATPalette.neutral.grey[500],
    margin: 0,

    '> span': {
        color: PATPalette.neutral.grey[300]
    }
}));

const StyledSubcontent = styled(Typography)(() => ({
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color: customPalette.universalCard.subTextFontColor,
    margin: 0
}));

const StyledheaderViewAll = styled('span')({
    fontSize: '13px',
    fontWeight: 600,
    fontStyle: 'normal',
    color: PATPalette.primary.skyBlue,
    paddingLeft: 5,
    cursor: 'pointer',
    lineHeight: '18px',
    margin: 0,
    fontVariantNumeric: 'lining-nums tabular-nums',
    fontFamily: 'TT Commons Pro',
    textDecorationLine: 'underline',
    textUnderlineOffset: '3px'
});

const StyledHeaderDiv = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
});

const StyledDivider = styled(Divider)({
    borderTop: '1px',
    marginBottom: '10px',
    borderColor: PATPalette.neutral.grey[100]
});

const StyledCardContent = styled(CardContent)({
    padding: 0,
    '&:last-child': {
        paddingBottom: 0
    }
});

const StyledSubHeaderSection = styled('div')({
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '10px'
});

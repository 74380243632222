import { isEmpty } from 'lodash';
import { RETIREMENT_CATEGORY_GOALS, WEALTH_CATEGORY_GOALS } from './constants';
import { AdviceClientType, GoalPriorityEnum, GoalTitleEnum, GoalTypeProps } from './types';

export const isEmailValid = (value: string) =>
    Boolean(value === '' || value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i));

export const isAlphaNumValid = (value: string) => value === '' || value.match(/^[a-zA-Z0-9]+$/);

export const isAlphaNumSpacesValid = (value: string) => value === '' || value.match(/^[a-zA-Z0-9\s]+$/);

export const isAlphaOnlyValid = (value: string) => value === '' || value.match(/^[a-zA-Z]+$/);

export const isAlphaSpacesOnlyValid = (value: string) => value === '' || value.match(/^[a-zA-Z\s]+$/);

// Regex for generic phone number added. TODO get confg from organization
// Allowed patterns
// (123) 456-7890
// 123-456-7890
// 123.456.7890
// 1234567890
// +31636363634
// 075-63546725
// 022-635463933
export const isPhoneNumberValid = (value: string) =>
    // eslint-disable-next-line no-useless-escape
    value === '' || value.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);

export const isNumberInRange = (val = 0, min = 0, max = 0) => val >= min && val <= max;

export const hex2rgba = (hex: any, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((x: string) => parseInt(x, 16));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const getFullMonthFromClientDOB = (dob: string) => {
    return new Date(dob?.split('-')[1]).toLocaleString('default', { month: 'long' });
};

export const getFullYearFromClientDOB = (dob: string) => {
    return dob?.split('-')[2];
};

export const isStageDisabled = (adviceClient: AdviceClientType, stage: string) => {
    // enable all stages after first stage is completed i.e., stages array has > 1 items
    return !adviceClient?.stages?.includes(stage) && !(adviceClient?.stages?.length > 1);
};

export const formatCurrency = (num: any, doNotAllowZero = true) => {
    if (num) {
        let value = num?.toString();
        value = value.replace(/[^.0-9]/g, '');

        if (doNotAllowZero) {
            value = value.replace(/\b0+/g, '');
        }

        let splittedValue = value.split('.');

        if (splittedValue[1]?.length > 2) {
            splittedValue[1] = splittedValue[1][0] + splittedValue[1][1];
            value = [splittedValue[0], splittedValue[1]].join('.');
        }

        if (splittedValue?.length > 2) {
            value = [splittedValue[0], splittedValue[1]].join('.');
        }

        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return value;
    }
    return '';
};

export const getMMYYYYfromTimeStamp = (date: string) => {
    const initialDate = date.split(/-/);
    const finalDate = [initialDate[1], initialDate[0], initialDate[2]].join('-');
    const timestamp = new Date(finalDate);
    const month = timestamp.getMonth() + 1;
    let formattedDate = (month > 9 ? month : '0' + month) + '/' + timestamp.getFullYear();
    return formattedDate;
};

export const getYYYYMMDDfromDate = (value: any, format: string = 'DD-MM-YYYY') => {
    return value.format(format);
};

export const getHeaderIconFromType = (goalTitle?: GoalTitleEnum) => {
    if (goalTitle)
        return [...RETIREMENT_CATEGORY_GOALS, ...WEALTH_CATEGORY_GOALS].find(
            (g: GoalTypeProps) => g.title === goalTitle
        )?.type;
    return WEALTH_CATEGORY_GOALS[0].type;
};

export const groupGoalsByPriority = (values: any, sortCallback: any) => {
    let sorted = values.reduce((a: any, b: any) => {
        const key = typeof sortCallback === 'function' ? sortCallback(b) : b[sortCallback];
        if (!a[key]) {
            a[key] = [b];
        } else {
            a[key] = [...a[key], b];
        }

        return a;
    }, {});
    return Object.values(GoalPriorityEnum)
        .map((gp) => sorted[gp])
        .filter((gp) => gp)
        .flat();
};

export function formatAdvisorName(data: any) {
    return !isEmpty(data) && data?.userType === 'admin' ? data?.adminName || '' : data?.advisorName || '';
}

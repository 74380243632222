import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { PATPalette } from '../../../themes/palette';

type Props = PropsWithChildren<{
    onClick(): void;
    disabled?: boolean;
}>;

export const DropdownItem = ({ onClick, disabled, children }: Props) => {
    return (
        <Container onClick={onClick} type="button" disabled={disabled}>
            {children}
        </Container>
    );
};

const Container = styled('button')<{ isSelected?: boolean }>(({ theme, isSelected = false }) => ({
    all: 'unset',
    background: '#FFF',
    border: 'none !important',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 8px',
    cursor: 'pointer',
    color: PATPalette.primary.skyBlue,
    ...theme.typography.body.link.small,

    ':hover, :focus': {
        background: PATPalette.secondary.skyBlue[100]
    },
    ':active': {
        background: PATPalette.secondary.skyBlue[200]
    },
    ':disabled': {
        cursor: 'not-allowed',
        color: PATPalette.neutral.grey[300],
        background: 'transparent'
    },
    ':first-of-type': {
        borderRadius: '6px 6px 0 0'
    },
    ':last-child': {
        borderRadius: '0 0 6px 6px'
    },
    ':only-child': {
        borderRadius: '6px'
    },
    ...(isSelected && {
        background: PATPalette.secondary.skyBlue[100]
    }),
    '> svg': {
        width: 16,
        height: 16,
        marginRight: 12
    }
}));

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { uipApiInstance } from '../../services';
import { logout } from '../auth/authSlice';
import { logEventToBackEnd } from '../global/globalSlice';
import { sortBy } from 'lodash';
import moment from 'moment';

export type FinancialAdvisor = {
    id?: any;
    advisorName: string;
    advisorEmail: string;
    advisorPhoneNumber: string;
    numberOfClients: string;
    numberOfGoals: string;
    createdOn: string;
};

export type ClientsDataType = {
    id?: any;
    advisorEmail: string;
    lastModified: string | number;
    clientFirstName: string;
    clientLastName: string;
    advisorName: string;
    clientEmail: string;
    goals: any;
    portfolioValue: string;
};

type InitialState = {
    financialAdvisors: Array<FinancialAdvisor>;
    advisorEmailId: string;
    clients: Array<ClientsDataType>;
    admins: Array<any>;
    loading: boolean;
    error?: null | undefined | any;
    showFinancialAdvisorRemoveModal: boolean;
    triggeredFinancialAdvisorRemoval: boolean;
    isUpdatingEULA?: boolean;
    selectedUser?: {
        id: string;
        advisorName?: string;
        advisorEmail?: string;
        advisorPhoneNumber?: string;
        numberOfClients?: number;
        numberOfGoals?: string | number;
        adminName?: string;
        adminEmail?: string;
        userType: string;
        adminPhoneNumber?: string;
        createdOn?: string;
    } | null;
    breadcrumbLabel: string;
};

const initialState: InitialState = {
    financialAdvisors: [],
    advisorEmailId: '',
    clients: [],
    admins: [],
    loading: false,
    error: null,
    showFinancialAdvisorRemoveModal: false,
    triggeredFinancialAdvisorRemoval: false,
    isUpdatingEULA: false,
    selectedUser: null,
    breadcrumbLabel: ''
};

type assignAdvisorToClientInput = {
    clientId: string;
    userId: string;
};

export const assignAdvisorToClient = createAsyncThunk(
    'admin/assignAdvisorToClient',
    async ({ clientId, userId }: assignAdvisorToClientInput, { dispatch, rejectWithValue }) => {
        try {
            await uipApiInstance({
                method: 'POST',
                url: '/api/advisorportal/associateclientgoalstofa',
                withCredentials: false,
                data: { clientId, userId }
            });

            await dispatch(fetchAllClients());

            return true;
        } catch (err) {
            console.log(err);
            dispatch(logEventToBackEnd('LINK_CLIENTS_TO_FA_API_ERROR'));
            return rejectWithValue(err);
        }
    }
);

export const fetchAllClients = createAsyncThunk('admin/fetchAllClients', async (_, { dispatch, rejectWithValue }) => {
    try {
        const response = await uipApiInstance({
            method: 'GET',
            url: '/api/advisorportal/getclients',
            withCredentials: false
        });

        const formattedData = response.data?.data?.map((d: any) => ({
            id: d._id,
            advisorName: d.advisorName || 'N/A',
            advisorId: d.userId,
            clientFullName: d.firstName + (d.lastName ? ' ' + d.lastName : ''),
            clientEmail: d.email,
            goals: d.goals,
            portfolioValue: d.portfolioValue,
            lastModified: d.lastUpdatedTime
        }));

        return formattedData;
    } catch (err) {
        console.log(err);
        dispatch(logEventToBackEnd('GET_CLIENTS_API_ERROR'));
        return rejectWithValue(err);
    }
});

const sortUsers = (a: any, b: any) => moment(a?.__meta?.timestamp).valueOf() - moment(b?.__meta?.timestamp).valueOf();

export const fetchAdminsAndAdvisors = createAsyncThunk(
    'admin/fetchAdminsAndAdvisors',
    async (_, { getState, dispatch, rejectWithValue }) => {
        try {
            const state: any = getState();

            const tenantId = state?.global?.globalConfig?.subdomain;

            const response = await uipApiInstance({
                method: 'POST',
                url: '/api/advice/getusers',
                withCredentials: false,
                data: {
                    noCache: true,
                    tenant: tenantId
                }
            });

            const admins = response.data?.[tenantId]?.admin || [];
            const advisors = response.data?.[tenantId]?.advisor || [];

            if (admins && advisors) {
                const restructuredData = {
                    admins: admins.sort(sortUsers).map((admin: any) => ({
                        id: admin.__meta.id,
                        adminName: admin.name,
                        adminEmail: admin.email,
                        adminPhoneNumber: admin.phoneNumber,
                        numberOfClients: admin.clients?.length || 0,
                        numberOfGoals: 'N/A',
                        userType: 'admin',
                        createdOn: admin.createdOn
                    })),
                    financialAdvisors: sortBy(advisors, [
                        function (o) {
                            return o.name.toLowerCase();
                        }
                    ]).map((finanacialAdvisor: any) => ({
                        id: finanacialAdvisor._id,
                        advisorName: finanacialAdvisor.name,
                        advisorEmail: finanacialAdvisor.email,
                        advisorPhoneNumber: finanacialAdvisor.phoneNumber,
                        numberOfClients: finanacialAdvisor.clients?.length || 0,
                        numberOfGoals: 'N/A',
                        userType: 'advisor',
                        createdOn: finanacialAdvisor.createdOn
                    }))
                };

                return restructuredData;
            } else {
                return {
                    admins: [],
                    financialAdvisors: [],
                    error: 'Error fetching admins'
                };
            }
        } catch (err) {
            console.log(err);
            dispatch(logEventToBackEnd('GET_USERS_API_ERROR'));
            return rejectWithValue(err);
        }
    }
);

export const getDashboardMetrics = createAsyncThunk('admin/metrics', async (_, { dispatch, rejectWithValue }) => {
    try {
        const response = await uipApiInstance({
            method: 'GET',
            url: '/api/advisorportal/metrics',
            withCredentials: false
        });

        return response.data;
    } catch (err) {
        console.log(err);
        dispatch(logEventToBackEnd('ADMIN_METRICS_API_ERROR'));
        return rejectWithValue(err);
    }
});

export const deactivateUser = createAsyncThunk(
    'admin/deactivateuser',
    async ({ userId, tenant }: { userId: string; tenant: string }, { dispatch, rejectWithValue }) => {
        try {
            const response = await uipApiInstance({
                method: 'POST',
                url: '/api/advice/deactivateuser',
                withCredentials: false,
                data: { id: userId, tenant }
            });

            return response.data;
        } catch (err) {
            console.log(err);
            dispatch(logEventToBackEnd('ADMIN_DELETE_USER_API_ERROR'));
            return rejectWithValue(err);
        }
    }
);

export const updateUser = createAsyncThunk('admin/updateUser', async (data: any, { dispatch, rejectWithValue }) => {
    try {
        const response = await uipApiInstance({
            method: 'POST',
            url: '/api/advisorportal/updateuser',
            withCredentials: false,
            data
        });
        return response.data.success;
    } catch (err) {
        console.log(err);
        dispatch(logEventToBackEnd('ADMIN_UPDATE_USER_API_ERROR'));
        return rejectWithValue(err);
    }
});

export const updateEula = createAsyncThunk('admin/updateEula', async (data: any, { dispatch, rejectWithValue }) => {
    try {
        const response = await uipApiInstance({
            method: 'POST',
            url: '/api/advisorportal/updateeula',
            withCredentials: false,
            data
        });
        return response.data.success;
    } catch (err) {
        console.log(err);
        dispatch(logEventToBackEnd('UPDATE_EULA_API_ERROR'));
        return rejectWithValue(err);
    }
});

export const checkIfUserExists = createAsyncThunk(
    'admin/checkIfUserExists',
    async (emailId: string, { dispatch, rejectWithValue }) => {
        try {
            const response = await uipApiInstance({
                method: 'POST',
                url: '/api/advisorportal/userexists',
                withCredentials: false,
                data: { email: emailId }
            });

            return response?.data?.data?.exists;
        } catch (error) {
            console.log(error);
            dispatch(logEventToBackEnd('USER_EXISTS_API_ERROR'));
            return rejectWithValue(error);
        }
    }
);

export const fetchAdvisorAdminFAClients = createAsyncThunk(
    'admin/fetchAdvisorAdminFAClients',
    async (financialAdvisorId: string, { dispatch, rejectWithValue }) => {
        try {
            console.log(financialAdvisorId);
            const response = await uipApiInstance({
                method: 'GET',
                url: '/api/advisorportal/getclients',
                withCredentials: false
            });

            const formattedData = response.data?.data
                ?.filter((ufd: any) => ufd?.__meta?.user === financialAdvisorId)
                ?.map((d: any) => ({
                    id: d._id,
                    advisorName: d.advisorName || 'N/A',
                    clientFullName: d.firstName + (d.lastName ? ' ' + d.lastName : ''),
                    clientEmail: d.email,
                    goals: d.goals,
                    portfolioValue: d.portfolioValue,
                    lastModified: d.lastUpdatedTime
                }));

            return formattedData;
        } catch (error) {
            console.log(error);
            dispatch(logEventToBackEnd('GET_CLIENTS_API_ERROR'));
            return rejectWithValue(error);
        }
    }
);

const advisorAdminClientsTable = createSlice({
    name: 'advisorAdminClientsState',
    initialState,
    reducers: {
        updateFinancialAdvisors: (state, action: PayloadAction<any>) => {
            state.financialAdvisors = action.payload;
        },
        updateFinancialAdvisorDeleteModalState: (state, action) => {
            state.showFinancialAdvisorRemoveModal = action.payload;
        },
        updateDeleteId: (state, action) => {
            state.advisorEmailId = action.payload;
        },
        updatedTriggeredFinancialAdvisorRemoval: (state, action) => {
            state.triggeredFinancialAdvisorRemoval = action.payload;
        },
        updateSelectedUser: (state, action: PayloadAction<any>) => {
            state.selectedUser = action.payload;
        },
        updateBreadcrumbLabel: (state, action: PayloadAction<any>) => {
            state.breadcrumbLabel = action.payload;
        },
        updateSelectedFinancialAdvisorClients: (state, action: PayloadAction<any>) => {
            state.clients = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(logout, () => initialState),
            builder.addCase(updateEula.pending, (state) => {
                state.isUpdatingEULA = true;
            }),
            builder.addCase(updateEula.fulfilled, (state) => {
                state.isUpdatingEULA = false;
            }),
            builder.addCase(updateEula.rejected, (state) => {
                state.isUpdatingEULA = false;
            }),
            builder.addCase(fetchAllClients.pending, (state) => {
                state.loading = true;
            }),
            builder.addCase(fetchAllClients.fulfilled, (state, action) => {
                state.loading = false;
                state.clients = action.payload;
                state.error = null;
            }),
            builder.addCase(fetchAllClients.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            }),
            builder.addCase(assignAdvisorToClient.pending, (state) => {
                state.loading = true;
            }),
            builder.addCase(assignAdvisorToClient.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
            }),
            builder.addCase(assignAdvisorToClient.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            }),
            builder.addCase(fetchAdminsAndAdvisors.pending, (state) => {
                state.loading = true;
            }),
            builder.addCase(fetchAdminsAndAdvisors.fulfilled, (state, action) => {
                state.loading = false;
                state.admins = action.payload.admins;
                state.financialAdvisors = action.payload?.financialAdvisors;
                state.error = null;
            }),
            builder.addCase(fetchAdminsAndAdvisors.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            }),
            builder.addCase(fetchAdvisorAdminFAClients.pending, (state) => {
                state.loading = true;
            }),
            builder.addCase(fetchAdvisorAdminFAClients.fulfilled, (state, action) => {
                state.loading = false;
                state.clients = action.payload;
                state.error = null;
            }),
            builder.addCase(fetchAdvisorAdminFAClients.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const {
    updateFinancialAdvisors,
    updateFinancialAdvisorDeleteModalState,
    updateDeleteId,
    updatedTriggeredFinancialAdvisorRemoval,
    updateSelectedUser,
    updateBreadcrumbLabel,
    updateSelectedFinancialAdvisorClients
} = advisorAdminClientsTable.actions;

export default advisorAdminClientsTable.reducer;

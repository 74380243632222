import { styled } from '@mui/material/styles';
import { Controller } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { TextboxInput } from '../../components/atoms';

const GridContainer: any = styled(Grid)(() => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    marginTop: '2px'
}));

const ErrorSpan = styled('span')(() => ({
    color: 'red',
    fontSize: '12px'
}));

export default function UserInfo({ onSubmit, handleSubmit, control, errors }: any) {
    return (
        <form id="add-user-form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <GridContainer container spacing={2}>
                <GridContainer item xs={12} sm={6} md={3} lg={3} sx={{ lineHeight: '20px' }}>
                    <Controller
                        name="firstName"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: {
                                value: true,
                                message: 'First name is required'
                            },
                            pattern: {
                                value: /^[0-9A-Za-zñáéíóúü_ ]*$/i,
                                message: 'Please enter a valid first name'
                            },
                            maxLength: {
                                value: 50,
                                message: 'Can only be 50 characters long'
                            },
                            minLength: {
                                value: 2,
                                message: 'Should be at least 2 characters long'
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <TextboxInput
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                inputRef={ref}
                                error={!!errors.firstName}
                                label="Advisor First Name"
                                type="text"
                                placeholder="Enter First Name"
                                fullWidth
                            />
                        )}
                    />
                    {errors.firstName && <ErrorSpan role="alert">{errors.firstName.message}</ErrorSpan>}
                </GridContainer>
                <GridContainer item xs={12} sm={6} md={3} lg={3} sx={{ lineHeight: '20px' }}>
                    <Controller
                        name="lastName"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: {
                                value: true,
                                message: 'Last name is required'
                            },
                            pattern: {
                                value: /^[0-9A-Za-zñáéíóúü_ ]*$/i,
                                message: 'Please enter a valid last name'
                            },
                            maxLength: {
                                value: 50,
                                message: 'Can only be 50 characters long'
                            },
                            minLength: {
                                value: 2,
                                message: 'Should be at least 2 characters long'
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <TextboxInput
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                inputRef={ref}
                                error={!!errors.lastName}
                                label="Advisor Last Name"
                                type="text"
                                placeholder="Enter Last Name"
                                fullWidth
                            />
                        )}
                    />
                    {errors.lastName && <ErrorSpan role="alert">{errors.lastName.message}</ErrorSpan>}
                </GridContainer>
                <GridContainer item xs={12} sm={6} md={3} lg={3} sx={{ lineHeight: '20px' }}>
                    <Controller
                        name="emailAddress"
                        control={control}
                        defaultValue=""
                        aria-invalid={errors?.emailAddress}
                        rules={{
                            required: {
                                value: true,
                                message: 'Email is required'
                            },
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: 'Please enter a valid email'
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <TextboxInput
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                inputRef={ref}
                                error={!!errors?.emailAddress}
                                label="Email Address"
                                type="email"
                                placeholder="Enter Email Address"
                                fullWidth
                            />
                        )}
                    />
                    {errors.emailAddress && <ErrorSpan role="alert">{errors?.emailAddress?.message}</ErrorSpan>}
                </GridContainer>
                <GridContainer item xs={12} sm={6} md={3} lg={3} sx={{ lineHeight: '20px' }}>
                    <Controller
                        name="phoneNumber"
                        control={control}
                        defaultValue=""
                        aria-invalid={errors?.phoneNumber}
                        rules={{
                            required: {
                                value: true,
                                message: 'Phone number is required'
                            },
                            pattern: {
                                value: /^\d+$/i,
                                message: 'Please enter a valid phone number'
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <TextboxInput
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                inputRef={ref}
                                error={!!errors?.phoneNumber}
                                label="Primary Phone Number"
                                type="phonenumber"
                                placeholder="Enter Primary Phone Number"
                                fullWidth
                            />
                        )}
                    />
                    {errors.phoneNumber && <ErrorSpan role="alert">{errors?.phoneNumber?.message}</ErrorSpan>}
                </GridContainer>
            </GridContainer>
        </form>
    );
}

import Highcharts from 'highcharts/highcharts';
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import { useRef } from 'react';
import highchartsMore from 'highcharts/highcharts-more';
import { PATPalette } from '../../../themes/palette';
import _last from 'lodash/last';
import { renderToString } from 'react-dom/server';
import { ProjectedWealthPathLabel } from '../../atoms/ProjectedWealthPathLabel';
import { GoalTypeEnum } from '../../../common/types';
import { UniversalCard } from '../UniversalCard/UniversalCard';
import './styles.scss';
import { Grid } from '@mui/material';

// Enables the tag <use> from Icon component
Highcharts.AST.allowedTags.push('use');

highchartsMore(Highcharts);

type ProjectedWealthPathData = {
    year: number;
    low: number;
    high: number;
    target: number;
    bankruptcyHigh: number;
    bankruptcyLow: number;
};

export type ProjectedWealthPathChartProps = {
    data: ProjectedWealthPathData[];
    plotLine?: {
        type: GoalTypeEnum;
        year: number;
    };
    bankruptcyYear?: number;
    lowPercentile: string;
    highPercentile: string;
    targetPercentile: string;
};

export const ProjectedWealthPathChart = ({
    bankruptcyYear,
    data,
    plotLine,
    lowPercentile,
    highPercentile,
    targetPercentile
}: ProjectedWealthPathChartProps) => {
    const chartComponentRef = useRef<HighchartsReactRefObject | null>(null);

    const firstYear = data?.[0]?.year;
    const lastYear = _last(data)?.year;

    const highAndLow = data
        ?.map(({ year, low, high }) => [year, low, high])
        .filter((item) => (bankruptcyYear ? item[0] <= bankruptcyYear : true));
    const probabilityOfSucess = data
        ?.map(({ year, target }) => [year, target])
        .filter((item) => (bankruptcyYear ? item[0] <= bankruptcyYear : true));
    const bankruptcyLine = data?.map(({ bankruptcyHigh }) => [bankruptcyYear, bankruptcyHigh]);

    const highAndLowBankruptcy = data
        ?.map(({ year, bankruptcyLow, bankruptcyHigh }) => [year, bankruptcyLow, bankruptcyHigh])
        .filter((item) => (bankruptcyYear ? item[0] >= bankruptcyYear : false));

    const series: Highcharts.SeriesOptionsType[] = [
        {
            name: `${targetPercentile} Probability of Success`,
            type: 'line',
            data: probabilityOfSucess,
            zIndex: 2,
            color: PATPalette.secondary.skyBlue[300],
            lineWidth: 3,
            marker: {
                enabled: false
            }
        },
        {
            name: 'High & Low',
            type: 'arearange',
            data: highAndLow,
            color: PATPalette.secondary.skyBlue[100],
            lineWidth: 0,
            marker: {
                enabled: false
            },
            zIndex: 0,
            states: {
                inactive: {
                    opacity: 1
                },
                hover: {
                    opacity: 1,
                    enabled: false
                }
            }
        }
    ];

    if (bankruptcyYear) {
        series.push(
            {
                name: 'No Income',
                type: 'arearange',
                data: highAndLowBankruptcy,
                color: PATPalette.secondary.berry[100],
                lineWidth: 0,
                marker: {
                    enabled: false
                },
                zIndex: 0,
                states: {
                    inactive: {
                        opacity: 1
                    },
                    hover: {
                        opacity: 1,
                        enabled: false
                    }
                }
            },
            {
                name: 'No Income',
                showInLegend: false,
                type: 'line',
                data: bankruptcyLine,
                zIndex: 2,
                color: PATPalette.primary.berry,
                lineWidth: 3,
                marker: {
                    enabled: false
                }
            }
        );
    }

    const options: Highcharts.Options = {
        chart: {
            style: {
                fontFamily: 'TT Commons Pro'
            }
        },
        title: {
            text: ''
        },
        xAxis: {
            width: '99%',
            crosshair: {
                dashStyle: 'Solid',
                zIndex: 2
            },
            type: 'linear',
            lineWidth: 0,
            left: 70,
            minPadding: 0,
            maxPadding: 0,
            min: firstYear,
            max: lastYear,
            allowDecimals: false,
            tickColor: 'rgba(0,0,0,0.5)',
            tickPosition: 'outside',
            labels: {
                distance: 20,
                style: labelStyle,
                formatter: ({ value }) => {
                    if (value?.toString()?.includes('.')) {
                        return '';
                    }

                    return value.toString();
                }
            },

            ...(!!plotLine && {
                plotLines: [
                    {
                        dashStyle: 'Dash',
                        color: PATPalette.neutral.grey[400],
                        width: 1,
                        value: plotLine.year,
                        zIndex: 10,
                        label: {
                            align: 'center',
                            rotation: 360,
                            useHTML: true,
                            verticalAlign: 'top',
                            y: -10,
                            formatter: () => {
                                return renderToString(
                                    <>
                                        <div className="plot-title">Drawdowns Begin</div>
                                        <ProjectedWealthPathLabel type={plotLine.type} />
                                    </>
                                );
                            }
                        }
                    }
                ]
            })
        },
        yAxis: {
            allowDecimals: false,
            crosshair: false,
            gridLineWidth: 0,
            tickmarkPlacement: 'on',
            tickWidth: 1,
            tickColor: 'rgba(0,0,0,0.5)',
            tickPosition: 'outside',
            title: {
                text: null
            },
            labels: {
                distance: 20,
                style: labelStyle,
                formatter: function () {
                    const value = Number(this.value);

                    if (value >= 1000000) {
                        return Highcharts.numberFormat(value / 1000000, 0) + 'M';
                    }

                    if (value > 1000) {
                        return Highcharts.numberFormat(value / 1000, 0) + 'K';
                    }

                    if (this.value !== 0) {
                        return this.value as string;
                    }

                    return '0k';
                }
            },
            minPadding: 1,
            maxPadding: 0
        },
        tooltip: {
            shared: true,
            outside: true,
            useHTML: true,
            followPointer: false,
            followTouchMove: false,
            backgroundColor: 'transparent',
            borderWidth: 0,

            formatter: function () {
                const { key: year } = this;

                const currentAreaData = data.find((item) => item.year === Number(year));

                const { high = 0, low = 0, target = 0 } = currentAreaData || {};

                // TODO: Migrate it to a component
                return `
                <div class="tooltip-chart-card">
                    <strong class="title">YEAR ${year}</strong>
                    <strong class="subtitle">PROJECTIONS</strong>
                    <div>
                        <div class="legend">
                        <div class="left">
                            <div
                            class="bar"
                            style="background: ${PATPalette.secondary.skyBlue[100]}"
                            ></div>
                            <span class="name">Low (${highPercentile})</span>
                        </div>

                        <strong class="amount">${formatMoney(high)}</strong>
                        </div>

                        <div class="legend">
                        <div class="left">
                            <div
                            class="line"
                            style="background: ${PATPalette.secondary.skyBlue[300]}"
                            ></div>
                            <span class="name">Target (${targetPercentile})</span>
                            <span class="chip">Need</span>
                            
                        </div>

                        <strong class="amount">${formatMoney(target)}</strong>
                        </div>

                        <div class="legend" style="margin-bottom: 0">
                        <div class="left">
                            <div
                            class="bar"
                            style="background: ${PATPalette.secondary.skyBlue[100]}"
                            ></div>
                            <span class="name">High (${lowPercentile}) </span>
                        </div>

                        <strong class="amount">${formatMoney(low)}</strong>
                        </div>
                    </div>
                </div>
                    `;
            }
        },
        legend: {
            enabled: true,
            align: 'right',
            verticalAlign: 'top',
            itemMarginBottom: 15,
            symbolHeight: 20,
            symbolWidth: 25,
            itemStyle: {
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '20px'
            }
        },
        series,
        credits: {
            enabled: false
        }
    };

    return (
        <Grid marginTop={3} marginBottom={3}>
            <UniversalCard header="Projected Wealth Plan">
                <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />
            </UniversalCard>
        </Grid>
    );
};

const labelStyle = {
    color: PATPalette.neutral.black,
    fontFamily: '"TT Commons Pro"',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '16px',
    letterSpacing: '0.12px'
};

// TODO: Localization
const formatMoney = (amount: number) =>
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
    }).format(amount);

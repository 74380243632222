import { useMemo } from 'react';
import { AllocationsOverTimeChart } from '../../components/molecules/AllocationsOverTimeChart';
import { useAppSelector } from '../../common/hooks';

export const AllocationsOverTimeTab = ({ portfolioPath }: { portfolioPath: Array<number> }) => {
    const { portfolio: allPortfolios } = useAppSelector((state) => state.global);
    const generateEquityArray = useMemo(
        () =>
            portfolioPath.map((yearPortfolioId) => {
                const { assets } = allPortfolios?.find((p) => p.portfolioId === yearPortfolioId) || { assets: [] };
                return assets.find((asset: any) => asset.type === 'Equity')?.totalAllocatedvalue || 0;
            }),
        [portfolioPath]
    );
    const generateFixedIncomeArray = useMemo(
        () =>
            portfolioPath.map((yearPortfolioId) => {
                const { assets } = allPortfolios.find((p) => p.portfolioId === yearPortfolioId) || { assets: [] };
                return assets.find((asset: any) => asset.type === 'Fixed Income')?.totalAllocatedvalue || 0;
            }),
        [portfolioPath]
    );
    const generateAlternativesArray = useMemo(
        () =>
            portfolioPath.map((yearPortfolioId) => {
                const { assets } = allPortfolios.find((p) => p.portfolioId === yearPortfolioId) || { assets: [] };
                return assets.find((asset: any) => asset.type === 'Alternatives')?.totalAllocatedvalue || 0;
            }),
        [portfolioPath]
    );
    const generateCashArray = useMemo(
        () =>
            portfolioPath.map((yearPortfolioId) => {
                const { assets } = allPortfolios.find((p) => p.portfolioId === yearPortfolioId) || { assets: [] };
                return assets.find((asset: any) => asset.type === 'Cash')?.totalAllocatedvalue || 0;
            }),
        [portfolioPath]
    );
    return (
        <AllocationsOverTimeChart
            startingYear={new Date().getFullYear()} // current year
            equity={generateEquityArray}
            fixedIncome={generateFixedIncomeArray}
            alternatives={generateAlternativesArray}
            cash={generateCashArray}
        />
    );
};

import { styled, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { ComboSelect, ChipMessage, Typography } from '../../components/atoms';
import { UniversalCard } from '../../components/molecules/UniversalCard/UniversalCard';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { ReactComponent as RiskToleranceIcon } from '../../assets/icons/risktoleranceicon.svg';
import { updateClient } from '../../features/client';
import { isStageDisabled } from '../../common/helper';
import { CLIENT_PROFILE_STAGE_KEYS } from '../../common/constants';
import { useTranslation } from 'react-i18next';
import { HeaderIcon } from './HeaderIcon';
import { PATPalette } from '../../themes/palette';
import { InfoTooltip } from '../../components/molecules/InfoTooltip';

export interface RiskToleranceCardProps {}

const ContentDiv = styled('div')(({ theme }) => ({
    maxWidth: '50%',
    margin: '0 auto',
    marginBlock: theme.spacing(7)
}));

const SubHeadingText = styled(Typography)(({ theme }) => ({
    lineHeight: theme.spacing(2.5),
    color: PATPalette.neutral.grey[400],
    fontWeight: '500',
    margin: 0,
    fontSize: '16px'
}));

export const RiskToleranceCard: React.FC<RiskToleranceCardProps> = ({}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { orgInfo } = useAppSelector((state) => state.global);
    const adviceClient = useAppSelector((state) => state.adviceClient);
    // getting options from organization
    const options = orgInfo?.riskProfileAex?.en;
    const defaultSelectedOption =
        adviceClient?.riskProfile !== ''
            ? adviceClient?.riskProfile
            : options?.length > 1
              ? options?.[0]?.value
              : null;
    const [selectedOption, setSelectedOption] = useState<string | null>(defaultSelectedOption);

    const syncRiskSelected = (selectedOption: string | null) => {
        if (selectedOption && options?.find((opt: any) => opt.key === selectedOption)) {
            // API call to persist riskProfile and stages
            const updatedAdviceClient = {
                ...adviceClient,
                riskProfile: selectedOption,
                stages: [...adviceClient?.stages, CLIENT_PROFILE_STAGE_KEYS[3]]
            };
            dispatch(updateClient(updatedAdviceClient));
        }
    };

    const handleSelectionChange = (value: string) => {
        setSelectedOption(value);
        syncRiskSelected(value);
    };

    const getPortfolioStrategiesAccessCount = () => {
        const riskObject = options?.find((opt: any) => opt.key === selectedOption);
        return riskObject?.portfolioStrategiesAccessCount;
    };

    const formattedOptions = options?.map((opt: any) => ({ value: opt?.key, label: opt?.label }));

    return (
        <UniversalCard
            header={t('CLIENT_PROFILE_RISK_TOLERANCE_HEADING')}
            headerIcon={
                <HeaderIcon
                    isDisabled={isStageDisabled(adviceClient, CLIENT_PROFILE_STAGE_KEYS[2])}
                    icon={<RiskToleranceIcon />}
                />
            }
            cardSx={
                isStageDisabled(adviceClient, CLIENT_PROFILE_STAGE_KEYS[2])
                    ? {
                          cursor: 'not-allowed',
                          '*': { 'pointer-events': 'none', filter: 'grayscale(100%)' }
                      }
                    : {}
            }
        >
            {selectedOption === options?.[0]?.value ? (
                <SubHeadingText theme={theme}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        {t('CLIENT_PROFILE_RISK_TOLERANCE_SUBHEADING')}
                        <InfoTooltip
                            width={381}
                            placement="left-start"
                            t="RISK_TOLERANCE_HELPER"
                        ></InfoTooltip>
                    </span>
                </SubHeadingText>
            ) : (
                <ChipMessage
                    message={selectedOption + ' Risk Tolerance has access to '}
                    boldInfo={getPortfolioStrategiesAccessCount() + ' portfolio strategies.'}
                />
            )}
            <ContentDiv>
                <Typography label={t('CLIENT_PROFILE_RISK_TOLERANCE_HELPER')} marginBottom={0} />
                <ComboSelect
                    options={formattedOptions}
                    selectedValue={selectedOption || ''}
                    onSelectChange={handleSelectionChange}
                />
            </ContentDiv>
        </UniversalCard>
    );
};

import { uipApiInstance } from '../index';

export const iws = async (data: any, headers: any) => {
    try {
        const response = await uipApiInstance({
            method: 'POST',
            url: '/api/advice/iws',
            withCredentials: false,
            data: data,
            headers: headers
        });
        if (response?.data?.response?.statusCode === 200) return response?.data?.response?.body;
        return response.data;
    } catch (err) {
        console.log(err);
        // dispatch(logEventToBackEnd('ADMIN_UPDATE_USER_API_ERROR'));
        // return rejectWithValue(err);
    }
};

import { useId } from 'react';
import styled from '@emotion/styled';
import { PATPalette } from '../../../themes/palette';
import { ReactComponent as PencilIcon } from '../../../assets/icons/pencil.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as ClientIcon } from '../../../assets/icons/client.svg';
import { ReactComponent as GoalIcon } from '../../../assets/icons/goal.svg';
import { ReactComponent as PlanDetailsIcon } from '../../../assets/icons/plan-details.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { Icon } from '../../atoms';
import { Dropdown } from '../../atoms/Dropdown';
import { DropdownItem } from '../../atoms/DropdownItem';
import { useDropdownState } from '../../../common/hooks/useDropdownState';

type Icons = 'edit' | 'delete' | 'client' | 'goal' | 'plan-details' | 'download';

export type ActionOption = {
    icon?: Icons;
    label: string;
    onClick(): void;
    disabled?: boolean;
};

type Props = {
    menuWidth?: number;
    options: ActionOption[];
    onOpen?(): void;
};

/**
 *
 * @param menuWidth the width size of the dropdown in pixels
 * @param options an array of ActionOption
 * @param onOpen a callback called every time the dropdown opens
 * @returns
 */
export const MoreActionsMenu = ({ menuWidth = 140, options = [], onOpen }: Props) => {
    const uniqId = useId();

    const { toggle, isActive, ...dropdownProps } = useDropdownState({ onOpen });

    return (
        <div>
            <Container id={uniqId}>
                <ClickableArea type="button" onClick={toggle} />
                {isActive && <IconBackground />}
                <Icon id="more-vertical" />
            </Container>

            <Dropdown {...dropdownProps}>
                <DropdownContent width={menuWidth} onClick={(e) => e.stopPropagation()}>
                    {options.map(({ label, onClick, icon, disabled }) => {
                        const Icon = icon && icons.get(icon);

                        return (
                            <DropdownItem
                                onClick={() => {
                                    onClick();
                                    toggle();
                                }}
                                key={`${uniqId}_${label}`}
                                disabled={disabled}
                            >
                                {icon && <Icon />}
                                <span>{label}</span>
                            </DropdownItem>
                        );
                    })}
                </DropdownContent>
            </Dropdown>
        </div>
    );
};

const Container = styled('div')(() => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: 8,
    height: 24,
    margin: '0 auto',
    '> svg': {
        zIndex: 1,
        color: PATPalette.neutral.grey[400],
        position: 'absolute'
    }
}));

const ClickableArea = styled('button')(() => ({
    all: 'unset',
    background: 'transparent',
    cursor: 'pointer',
    width: 30,
    height: 24,
    border: 'none',
    outline: 'none',
    position: 'absolute',
    zIndex: 2
}));

const IconBackground = styled('span')(() => ({
    position: 'absolute',
    width: '8px',
    height: '24px',
    background: PATPalette.neutral.grey[200],
    borderRadius: '100px',
    zIndex: 0
}));

export const DropdownContent = styled('div')<{
    maxHeight?: number;
    width?: number | string;
}>(({ maxHeight = 'unset', width }) => ({
    marginTop: '6px',
    width,
    display: 'flex',
    flexDirection: 'column',
    zIndex: 120,
    background: '#FFF',
    borderRadius: '6px',
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.05) !important',
    maxHeight,
    overflowY: 'auto',
    border: `1px solid ${PATPalette.neutral.grey[100]}`
}));

// TODO: what's the correct type?
const icons = new Map<Icons, any>([
    ['edit', PencilIcon],
    ['delete', DeleteIcon],
    ['client', ClientIcon],
    ['goal', GoalIcon],
    ['plan-details', PlanDetailsIcon],
    ['download', DownloadIcon]
]);

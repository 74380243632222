import { Theme } from '@mui/material';
import { PATPalette } from '../../themes/palette';

export default function Button(theme: Theme) {
    return {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'TT Commons Pro',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '20px',
                    borderRadius: '100px',
                    height: theme.spacing(5),
                    padding: theme.spacing(1, 4),
                    backgroundColor: PATPalette.primary.skyBlue,
                    color: PATPalette.neutral.white,
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText
                    },
                    '&.Mui-disabled': {
                        backgroundColor: PATPalette.neutral.lightGrey,
                        color: PATPalette.neutral.grey[300],
                        cursor: 'not-allowed'
                    }
                },
                text: {
                    backgroundColor: 'transparent',
                    color: theme.palette.primary.main
                },
                containedInherit: {
                    color: theme.palette.grey[800],
                    borderRadius: theme.spacing(6),
                    '&:hover': {
                        backgroundColor: theme.palette.primary.contrastText
                    }
                },
                outlinedInherit: {
                    border: `1px solid ${theme.palette.grey[500]}`,
                    borderRadius: theme.spacing(6),
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover
                    }
                },
                containedPrimary: {
                    boxShadow: 'none'
                },
                outlinedPrimary: {
                    backgroundColor: theme.palette.primary.contrastText,
                    color: theme.palette.primary.main,
                    border: `1px solid ${theme.palette.primary.main}`,
                    '&:hover': {
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.primary.contrastText,
                        border: `1px solid ${theme.palette.primary.main}`
                    }
                },
                containedSecondary: {
                    //variant='contained', color='secondary' for rectangle button
                    borderRadius: 0,
                    width: '-webkit-fill-available;'
                },
                outlinedSecondary: {
                    backgroundColor: theme.palette.secondary.contrastText,
                    color: theme.palette.secondary.dark,
                    border: `1px solid ${theme.palette.secondary.dark}`,
                    '&:hover': {
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.secondary.contrastText,
                        border: `1px solid ${theme.palette.secondary.main}`
                    }
                },
                sizeLarge: {
                    height: theme.spacing(6)
                }
            }
        }
    };
}

import styled from '@emotion/styled';

export type StatusVariant = 'draft' | 'active' | 'editing' | 'review';

const Container = styled('div')<Omit<VariantObject, 'label'>>(
    ({ borderColor, backgroundColor, theme, width, height }) => ({
        borderRadius: '100px',
        border: '1px',
        borderStyle: 'solid',
        borderColor,
        backgroundColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width,
        height,
        ...theme.typography.labels.smallDemibold,
        lineHeight: 'unset'
    })
);

type Props = {
    variant: StatusVariant;
};

export const Status = ({ variant }: Props) => {
    const { label, ...rest } = variants.get(variant) || {};

    if (!label) {
        return null;
    }

    return <Container {...rest}>{label}</Container>;
};

type VariantObject = {
    borderColor?: string;
    backgroundColor?: string;
    label?: string;
    width?: number;
    height?: number;
};

const variants = new Map<StatusVariant, VariantObject>([
    [
        'draft',
        {
            borderColor: '#595959',
            backgroundColor: '#E6E6E6',
            label: 'Draft',
            width: 73,
            height: 31
        }
    ],
    [
        'active',
        {
            borderColor: '#5291FF',
            backgroundColor: '#DDEAFF',
            label: 'Active',
            width: 73,
            height: 31
        }
    ],
    [
        'editing',
        {
            borderColor: '#FF6035',
            backgroundColor: '#FFE0D2',
            label: 'Editing',
            width: 73,
            height: 31
        }
    ],
    [
        'review',
        {
            borderColor: '#00BFB3',
            backgroundColor: '#CCF2F0',
            label: 'Review',
            width: 73,
            height: 31
        }
    ]
]);

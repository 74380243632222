/* eslint-disable operator-linebreak */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { authenticateUser } from '../../features/auth/authSlice';
import { LoginForm, MFAForm } from '../../components/organisms';
import { LOGIN } from '../../common/constants';
import { handleOpenEulaModal, logEventToBackEnd } from '../../features/global/globalSlice';
import { Footer } from '../../components/molecules/Footer/Footer';

interface FormInput {
    username: string;
    password: string;
    rememberMe: boolean;
}

const Login = () => {
    const [currentScreen, setCurrentScreen] = useState<'login' | 'MFA'>('login');
    const { oktaAuth, authState } = useOktaAuth();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isAuthenticated = authState?.isAuthenticated;
    const authLoading = useAppSelector((state) => state.auth.loading);
    const [currentLogginEmail, setCurrentLogginEmail] = useState<string>('');
    const [shouldRememberUsername, setshouldRememberUsername] = useState<boolean>(false);

    useEffect(() => {
        if (isAuthenticated && !authLoading) {
            navigate('/clients', { replace: true });
        }
    }, []);

    const navigatetoclients = () => {
        navigate('/clients');
    };

    const args = {
        primaryText: `Copyright © ${new Date().getFullYear()} Franklin Templeton. All Rights Reserved.`,
        links: [
            {
                label: 'Terms of Use',
                link: `${process.env.PUBLIC_URL}/assets/pdf/en/Digital_Advisor_Terms_of_Use.pdf`
            },
            { label: 'Privacy Notice', link: 'https://www.franklintempletonglobal.com/privacy' }
        ]
    };

    const onSubmit = async (values: FormInput) => {
        const res: any = await dispatch(authenticateUser({ oktaAuth, ...values }));

        if (res.payload.status === LOGIN.MFA_CHALLENGE || res.payload.status === 'MFA_ENROLL_ACTIVATE') {
            setCurrentScreen('MFA');
            setCurrentLogginEmail(values?.username);
            setshouldRememberUsername(values?.rememberMe);
        }

        if (res.payload.status === LOGIN.SUCCESS) {
            //Login to remember username
            values?.rememberMe
                ? localStorage.setItem('GOE_APP_REMEMBERED_EMAIL', values?.username)
                : localStorage.removeItem('GOE_APP_REMEMBERED_EMAIL');
            // if (res?.payload?.user?.freemium) {
            //     navigate('/add-goal');
            // } else

            dispatch(logEventToBackEnd('LOGIN_SUCCESS'));
            if (
                res?.payload?.user?.acceptedEulaVersion !== res?.payload?.user?.eula?.version ||
                res?.payload?.user?.acceptedUserAgreementVersion !== res?.payload?.user?.userAgreement?.version
            ) {
                navigatetoclients();
                dispatch(handleOpenEulaModal(true));
            } else {
                navigatetoclients();
            }
        }
    };

    switch (currentScreen) {
        case LOGIN.MFA:
            return (
                <MFAForm
                    currentLogginEmail={currentLogginEmail}
                    goBackToLogin={() => {
                        setCurrentScreen('login');
                    }}
                    rememberMe={shouldRememberUsername}
                />
            );
        default:
            return (
                <>
                    <LoginForm onSubmit={onSubmit} />
                    <Footer eulaVariant={false} showFooterWithoutOptions={true} {...args} />
                </>
            );
    }
};

export default Login;

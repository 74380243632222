import { styled, Typography } from '@mui/material';

type InfoBoxType = {
    title: string;
    children?: any;
};

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[600],
    marginBottom: '5px'
}));

export const InfoBox = ({ title, children }: InfoBoxType) => (
    <>
        <Title>{title}</Title>
        <div>{children}</div>
    </>
);

export default InfoBox;

import styled from '@emotion/styled';
import { useMemo } from 'react';
import { PATPalette } from '../../../themes/palette';
import { AdminFilterDropdown } from '../../molecules/AdminFilters/Dropdown';
import { useDropdownState } from '../../../common/hooks/useDropdownState';

type Props = {
    // eslint-disable-next-line
    onChangeItemsPerPage(itemsPerPage: number): void;
    // eslint-disable-next-line
    onChangePage(page: number): void;
    totalPages: number;
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
};

export const Pagination = ({
    onChangeItemsPerPage,
    onChangePage,
    totalPages,
    currentPage = 1,
    itemsPerPage,
    totalItems
}: Props) => {
    // TODO: Improve this logic
    const pagesList = useMemo(() => {
        const pages: number[] = [];

        if (currentPage === totalPages && currentPage > 3) {
            pages.push(currentPage - 2);
        }

        if (currentPage > 1) {
            pages.push(currentPage - 1);
        }

        pages.push(currentPage);

        if (currentPage < totalPages) {
            pages.push(currentPage + 1);
        }

        if (currentPage === 1 && totalPages > 2) {
            pages.push(3);
        }

        return pages;
    }, [currentPage, totalPages]);

    const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
    const lastItemIndex = useMemo(() => {
        const index = itemsPerPage * currentPage;

        if (index > totalItems) {
            return totalItems;
        }

        return index;
    }, [itemsPerPage, currentPage, totalItems]);

    const { toggle, ...dropdownProps } = useDropdownState();

    return (
        <>
            <Container>
                <Text style={{ marginRight: 8 }}>Rows per page:</Text>{' '}
                <NumberPerPage type="button" onClick={toggle}>
                    <Text>{itemsPerPage}</Text>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path d="M7 10.25L3.25 4.25L10.75 4.25L7 10.25Z" fill="#3769FF" />
                    </svg>
                </NumberPerPage>
                <Text style={{ margin: '0 24px' }}>
                    {firstItemIndex}-{lastItemIndex} of {totalItems}
                </Text>
                <PageNumberContainer>
                    {pagesList.map((page, i) => (
                        <PageNumber
                            key={`${page}_${i}`}
                            type="button"
                            isActive={page === currentPage}
                            onClick={() => onChangePage(page)}
                        >
                            {page}
                        </PageNumber>
                    ))}
                </PageNumberContainer>
            </Container>
            <AdminFilterDropdown
                {...dropdownProps}
                options={[
                    {
                        value: 10,
                        label: '10'
                    },
                    {
                        value: 20,
                        label: '20'
                    },
                    {
                        value: 30,
                        label: '30'
                    }
                ]}
                width={40}
                onSelectOption={({ value }) => {
                    onChangeItemsPerPage(value);
                    toggle();
                }}
                selectedOption={itemsPerPage}
            />
        </>
    );
};

const Container = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center'
}));

const Text = styled('span')(({ theme }) => ({
    ...theme.typography.numbers.small,
    color: PATPalette.primary.skyBlue,
    padding: 0,
    margin: 0
}));

const NumberPerPage = styled('button')(({}) => ({
    width: '37px',
    height: '20px',
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    padding: 0,
    gap: '4px',
    cursor: 'pointer',
    top: 0,
    justifyContent: 'space-between',
    zIndex: 1
}));

const PageNumberContainer = styled('div')(({}) => ({
    display: 'flex',
    gap: '12px'
}));

const PageNumber = styled('button')<{ isActive?: boolean }>(({ isActive = false, theme }) => ({
    background: isActive ? PATPalette.secondary.skyBlue[100] : '#FFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    borderRadius: '4px',
    padding: '10px 12px',
    color: isActive ? '#000' : PATPalette.primary.skyBlue,
    cursor: 'pointer',
    ...theme.typography.numbers.smallBold,
    ':hover': {
        background: PATPalette.neutral.lightGrey
    }
}));

import { Theme } from '@mui/material';

export default function Accordion(theme: Theme) {
    return {
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    padding: 0,
                    margin: 0
                },
                content: {
                    display: 'flex',
                    margin: 0,
                    justifyContent: 'space-between',
                    '&:has(+.Mui-expanded)': {
                        margin: `${theme.spacing(1)} 0`
                    }
                },
                expandIconWrapper: {
                    order: -1,
                    marginRight: theme.spacing(2)
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    padding: 0,
                    marginBottom: 0,
                    boxShadow: 'none',
                    borderBottom: `1px solid #BFBFBF`,
                    '&::before': {
                        backgroundColor: 'transparent',
                        height: 0
                    }
                },
                region: {
                    marginBottom: theme.spacing(2)
                }
            }
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding: 0,
                    margin: `${theme.spacing(1)} 0`
                }
            }
        }
    };
}

import { NavLink } from '../Link';
import { NavDropdown } from '../Dropdown';

import styled from '@emotion/styled';

const Title = styled('p')(() => ({
    all: 'unset',
    fontFamily: 'TT Commons Pro Condensed',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0.02em',
    color: '#000',
    marginBottom: '4px'
}));

const SubTitle = styled('p')(({ theme }) => ({
    ...theme.typography.labels.large,
    color: '#354560',
    marginBottom: '14px'
}));

const Link = styled('a')(({ theme }) => ({
    ...theme.typography.body.link.small,
    marginRight: 'auto',
    color: '#3769FF'
}));

type Props = {
    isDropdownActive: boolean;
    onToggleDropdown(): void;
};

export const NavContact = ({ isDropdownActive, onToggleDropdown }: Props) => {
    return (
        <>
            <NavLink onClick={onToggleDropdown} id="nav-contact-button">
                CONTACT US
            </NavLink>

            <NavDropdown
                isVisible={isDropdownActive}
                parentId="nav-contact-button"
                onClose={onToggleDropdown}
            >
                <Title>CONTACT US</Title>
                <SubTitle>By email</SubTitle>
                <Link>AdviceExperience@franklintempleton.com</Link>
            </NavDropdown>
        </>
    );
};

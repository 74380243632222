import { styled } from '@mui/material';
import { Typography } from '../Typography/Typography';

export interface ChipTypeProps {
    title?: string;
    type: 'Need' | 'Want' | 'Wish' | 'Dream' | 'onTrack' | 'offTrack' | 'atRisk';
}

const ChipMapper = {
    Need: { type: 'Need', backgroundColor: '#E7DFFE', title: 'Need' },
    Want: { type: 'Want', backgroundColor: '#DDEAFF', title: 'Want' },
    Wish: { type: 'Wish', backgroundColor: '#FFDDE6', title: 'Wish' },
    Dream: { type: 'Dream', backgroundColor: '#FFE0D2', title: 'Dream' },
    onTrack: { type: 'onTrack', backgroundColor: '#6DC09D', title: 'On Track' },
    offTrack: { type: 'offTrack', backgroundColor: '#FF4C57', title: 'Off Track' },
    atRisk: { type: 'atRisk', backgroundColor: '#FFB884', title: 'At Risk' }
};

const StyledGoalTypeContainer = styled('div')<ChipTypeProps>(({ theme, type }) => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.common.black,
    backgroundColor: `${type === ChipMapper[type].type ? ChipMapper[type].backgroundColor : 'inherit'}`,
    height: '26px',
    padding: `3px ${theme.spacing(2)}`,
    borderRadius: theme.spacing(3)
}));

export const Chip = ({ type }: ChipTypeProps) => {
    return (
        <StyledGoalTypeContainer type={type} aria-label={ChipMapper[type]?.title}>
            <Typography variant="body1" label={ChipMapper[type]?.title} />
        </StyledGoalTypeContainer>
    );
};

import { Theme } from '@mui/material';
import Button from './Button';
import CSSBaseline from './CSSBaseline';
import Checkbox from './Checkbox';
import HelperText from './HelperText';
import Input from './Input';
import Select from './Select';
import Typography from './Typography';
import Card from './Card';
import Chip from './Chip';
import Paper from './Paper';
import IconButton from './IconButton';
import Radio from './Radio';
import Table from './Table';
import Accordion from './Accordion';
import Icon from './Icon';
import MenuList from './MenuList';
import Tab from './Tab';

export function overrides(theme: Theme) {
    return Object.assign(
        Accordion(theme),
        Button(theme),
        Checkbox(theme),
        HelperText(),
        Chip(theme),
        CSSBaseline(theme),
        Card(theme),
        Input(theme),
        IconButton(theme),
        Icon(theme),
        Select(theme),
        MenuList(theme),
        Typography(theme),
        Paper(theme),
        Radio(theme),
        Table(theme),
        Tab(theme)
    );
}

import { CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { BasicButton } from '../../components/atoms';
import { Toast } from '../../components/molecules/Toast/Toast';
import UserInfo from '../add-new-financial-user';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { addUserDetails } from '../../services/userDetails';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const Container = styled(CardContent)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    fontStyle: 'normal',
    fontSize: '32px',
    color: theme.palette.primary.main,
    padding: '0',
    boxShadow: 'none',
    WebkitBoxShadow: 'none'
}));

const HeaderText = styled('span')(({ theme }) => ({
    color: theme.palette.common.black
}));

const ButtonContainer = styled('span')(() => ({
    display: 'inline-block',
    margin: '6px'
}));

const AccordianHeader = styled('div')(() => ({
    marginTop: '20px',
    textAlign: 'right'
}));

const HeaderContaier = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}));

interface FormInput {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
}

const AddFinancialUser = () => {
    const { t } = useTranslation();
    const financialAdvisors = useAppSelector((state) => state.advisorAdmin.financialAdvisors);
    const [addedAdvisorName, setAddedAdvisorName] = useState<string | undefined>(undefined);
    const dispatch = useAppDispatch();
    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
        reset,
        setError
    } = useForm<FormInput>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            emailAddress: ''
        }
    });

    const navigate = useNavigate();
    const tenant = useAppSelector((state) => state?.global?.globalConfig?.subdomain);
    const handleCancel = () => {
        navigate('/admin');
        reset();
    };
    const onSubmit = async (values: FormInput) => {
        const emailExist = financialAdvisors.some((advisor) => advisor.advisorEmail === values.emailAddress);
        if (emailExist) {
            setError('emailAddress', {
                type: 'required',
                message: t('ADVISOR_EMAIL_ALREADY_EXISTS')
            });
            return;
        }
        await dispatch(
            addUserDetails({
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.emailAddress,
                phoneNumber: values.phoneNumber,
                role: 'advisor',
                tenant,
                settings: {
                    freemium: false
                }
            })
        ).then((success: any) => {
            setAddedAdvisorName(`${values.firstName} ${values.lastName}`);
            reset();
            if (success.payload) {
                setTimeout(() => {
                    navigate('/admin');
                }, 3000);
            }
        });
    };
    return (
        <Container>
            {addedAdvisorName && (
                <Toast
                    heading={t('ADVISOR_ADDED_SUCCESSFULLY')}
                    type="confirm"
                    disappearTime={5}
                    messages={[`${addedAdvisorName} ${t('ADVISOR_ADDED_SUCCESSFULLY_MESSAGE')}`]}
                />
            )}
            <HeaderContaier>
                <HeaderText>Add New Advisor</HeaderText>
                <AccordianHeader>
                    <ButtonContainer>
                        <BasicButton form="add-user-form" variant="outlined" onClick={handleCancel}>
                            Cancel
                        </BasicButton>
                    </ButtonContainer>
                    <BasicButton type="submit" form="add-user-form" variant="outlined" disabled={!isValid}>
                        Add Advisor
                    </BasicButton>
                </AccordianHeader>
            </HeaderContaier>

            <UserInfo
                onSubmit={onSubmit}
                onCancel={handleCancel}
                handleSubmit={handleSubmit}
                control={control}
                errors={errors}
                reset={reset}
                isValid={isValid}
            />
        </Container>
    );
};

export default AddFinancialUser;

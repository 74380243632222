export interface IconProps {
    id: string;
    size?: number;
    fill?: string;
}

export const Icon = ({ id, size = 24, fill }: IconProps) => {
    return (
        <svg id={id} width={size} height={size} fill={fill} color={fill}>
            <use href={`/sprite.svg#${id}`} />
        </svg>
    );
};

import { Theme } from '@mui/material';

export default function IconButton(theme: Theme) {
    return {
        MuiIconButton: {
            styleOverrides: {
                root: {
                    margin: theme.spacing(0),
                    '&:hover': {
                        backgroundColor: 'transparent'
                    }
                }
            }
        }
    };
}

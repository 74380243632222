import styled from '@emotion/styled';
import { SearchInput } from './SearchInput';
import { SelectInput } from './SelectInput';
import { AdvisorConsoleStoreFilters } from 'features/advisorConsole/types';
import {
    typeOptions,
    statusOptions,
    riskProfileOptions,
    priorityOptions
} from '../../../common/constants';
import { OptionType } from './Dropdown';

type Props = {
    filters: AdvisorConsoleStoreFilters;
    // eslint-disable-next-line
    onChangeFilters(filters: AdvisorConsoleStoreFilters): void;
    clients: OptionType<string>[];
};

export const AdminFilters = ({ filters, onChangeFilters, clients }: Props) => {
    const { status, riskProfile, type, priority, client } = filters || {};

    return (
        <Container>
            <div
                style={{
                    minWidth: '200px',
                    width: '560px',
                    marginRight: '17px',
                    marginBottom: 'auto',
                    flex: 1
                }}
            >
                <SearchInput
                    value={client}
                    onChange={(value) => onChangeFilters({ ...filters, client: value })}
                    clients={clients}
                />
            </div>

            <SelectsContainer>
                <SelectInput
                    options={statusOptions}
                    label="Status"
                    name="filter-status"
                    value={status}
                    onChange={(value) => onChangeFilters({ ...filters, status: value })}
                />

                <SelectInput
                    options={riskProfileOptions}
                    label="Risk Profile"
                    name="filter-risk-profile"
                    value={riskProfile}
                    onChange={(value) => onChangeFilters({ ...filters, riskProfile: value })}
                />

                <SelectInput
                    options={typeOptions}
                    label="Type"
                    name="filter-type"
                    value={type}
                    onChange={(value) => onChangeFilters({ ...filters, type: value })}
                />

                <SelectInput
                    options={priorityOptions}
                    label="Priority"
                    name="filter-priority"
                    value={priority}
                    onChange={(value) => onChangeFilters({ ...filters, priority: value })}
                />
            </SelectsContainer>
        </Container>
    );
};

const Container = styled('div')(() => ({
    display: 'flex',
    marginBottom: '24px',
    overflowX: 'auto'
}));

const SelectsContainer = styled('div')(() => ({
    display: 'flex',
    gap: '8px'
}));

import styled from '@emotion/styled';
import { useState } from 'react';

const Container = styled('div')(({}) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    '> span': {
        fontFamily: 'TT Commons Pro',
        fontSize: '14px',
        fontWeight: 400,
        color: '#000',
        cursor: 'pointer'
    }
}));

// const Tooltip = styled('div')(() => ({
//     borderBottom: '1px dotted black',
//     borderRadius: '6px',
//     backgroundColor: 'black',
//     padding: '5px',
//     whiteSpace: 'nowrap', // Prevent text wrapping
//     '> span': {
//         fontFamily: 'TT Commons Pro',
//         fontSize: '10px',
//         fontWeight: 400,
//         color: 'white'
//     }
// }));

const Bar = styled('div')<{ color: string }>(({ color }) => ({
    width: '28px',
    height: '12px',
    borderRadius: '100px',
    background: color
}));

type Props = {
    label: string;
    color: string;
    descText?: string;
};

export const PlanSummaryProgressBarLegend = ({ label, color, descText }: Props) => {
    const [hover, setHover] = useState(false);
    const onHover = () => {
        setHover(true);
    };

    const onLeave = () => {
        setHover(false);
    };
    return (
        <Container>
            <Bar color={color} />
            {hover && descText ? (
                <span onMouseLeave={onLeave}>{descText}</span>
            ) : (
                <span onMouseEnter={onHover}>{label}</span>
            )}
        </Container>
    );
};

import { Icon } from '../../../atoms';
import styled from '@emotion/styled';
import { AdminFilterDropdown, OptionType } from '../Dropdown';
import { PATPalette } from '../../../../themes/palette';
import { useDropdownState } from '../../../../common/hooks/useDropdownState';

type Props = {
    label: string;
    value?: string | number;
    name: string;
    options: OptionType<string | undefined>[];
    // eslint-disable-next-line
    onChange(value?: string): void;
};

export const SelectInput = ({ label, value, name, options, onChange }: Props) => {
    const { toggle, ...dropdownProps } = useDropdownState();

    const valueLabel = options?.find((option) => option.value === value)?.label;

    const handleChange = (value: OptionType<string | undefined>['value']) => {
        onChange(value);
        toggle();
    };

    return (
        <>
            <Container id={name} onClick={toggle}>
                <Content>
                    <Label>{label}:</Label>
                    <Value>{valueLabel}</Value>
                </Content>
                <Icon id="chevron-down" size={12} fill={PATPalette.primary.skyBlue} />
            </Container>

            <AdminFilterDropdown
                {...dropdownProps}
                placement="bottom-end"
                options={options}
                width={263}
                onSelectOption={({ value }) => handleChange(value)}
                selectedOption={value}
            />
        </>
    );
};

const Container = styled('div')(() => ({
    display: 'flex',
    width: '176px',
    alignItems: 'center',
    gap: '4px',
    borderRadius: '6px',
    border: `1px solid ${PATPalette.neutral.grey[200]}`,
    background: PATPalette.neutral.white,
    height: '40px',
    padding: '0 12px',
    cursor: 'pointer'
}));

const Content = styled('div')(() => ({
    flex: 1,
    gap: '4px',
    display: 'flex',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
}));

const Label = styled('span')(({ theme }) => ({
    color: 'rgba(0, 0, 0, 0.70) !important',
    ...theme.typography.labels.large
}));

const Value = styled('span')(({ theme }) => ({
    color: '#000',
    ...theme.typography.labels.large
}));

import { Theme } from '@mui/material';

export default function Card(theme: Theme) {
    return {
        MuiCard: {
            styleOverrides: {
                root: {
                    border: '1px solid #E6E6E6',
                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
                    borderRadius: theme.spacing(2),
                    margin: `${theme.spacing(2)} 0`
                }
            }
        }
    };
}

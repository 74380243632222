import { Td, Tr } from '../Row';
import styled from '@emotion/styled';
import { Goal } from './Goal';
import { GoalTypeEnum } from 'common/types';
import { formatCurrency } from '../../../../utils';

const Text = styled('span')(({ theme }) => ({
    ...theme.typography.numbers.smallBold
}));

const ColumnCenter = styled(Td)(() => ({
    paddingLeft: 0,
    textAlign: 'center'
}));

export type SubRowProps = {
    goal: GoalTypeEnum;
    portfolioValue: number;
    probabilityofSuccess?: number;
    equityFixedIncome?: string;
    id: string;
    goalPriority: string;
    goalType?: string;
};

export const SubRow = ({
    goal,
    portfolioValue,
    probabilityofSuccess,
    equityFixedIncome,
    goalPriority
}: SubRowProps) => {
    return (
        <Tr isExpanded>
            <Td></Td>
            <Td></Td>
            <Td>
                <Goal type={goal} />
            </Td>
            {/* <Td></Td> */}
            <Td>
                <Text style={{ fontWeight: 500 }}>{goalPriority}</Text>
            </Td>
            <Td
                style={{
                    paddingLeft: 0,
                    paddingRight: 20,
                    textAlign: 'right'
                }}
            >
                <Text
                    style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: 4
                    }}
                >
                    ${formatCurrency(portfolioValue)}
                </Text>
            </Td>
            <Td></Td>
            <ColumnCenter>
                <Text
                    style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: 4
                    }}
                >
                    {probabilityofSuccess === undefined ? undefined : `${formatCurrency(probabilityofSuccess)}%`}
                </Text>
            </ColumnCenter>
            <ColumnCenter>
                <Text>{equityFixedIncome}</Text>
            </ColumnCenter>
            <Td style={{ padding: 0 }}></Td>
            <Td style={{ padding: 0 }}></Td>
        </Tr>
    );
};

// // TODO: Localization
// const formatMoney = (amount: number) =>
//     new Intl.NumberFormat('en-US', {
//         style: 'currency',
//         currency: 'USD',
//         maximumFractionDigits: 0
//     }).format(amount);

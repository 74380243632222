import { uipApiInstance } from '../index';

export const runpipe = async (data: any, headers: any) => {
    try {
        const response = await uipApiInstance({
            method: 'POST',
            url: '/api/advice/runpipe',
            withCredentials: false,
            data,
            headers
        });
        return response?.data;
    } catch (err) {
        console.log(err);
        // dispatch(logEventToBackEnd('ADMIN_UPDATE_USER_API_ERROR'));
        // return rejectWithValue(err);
    }
};

import { Grid } from '@mui/material';
import { useAppSelector } from '../../common/hooks';
import ColorPickerContainer from '../../components/organisms/SummaryTab/ColorPickerContainer';
import CompanyLogo from '../../components/organisms/SummaryTab/CompanyLogo';
import FinancialAdvisors from '../../components/organisms/SummaryTab/FinancialAdvisors';
import SummaryMetrics from '../../components/organisms/SummaryTab/SummaryMetrics';

export const AdminSummaryTab = () => {
    const globalConfig = useAppSelector((state) => state.global.globalConfig);
    const formattedGlobal = globalConfig;

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={7} lg={7}>
                <FinancialAdvisors />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
                <CompanyLogo formattedGlobal={formattedGlobal} />
                <ColorPickerContainer formattedGlobal={formattedGlobal} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <SummaryMetrics />
            </Grid>
        </Grid>
    );
};

import React, { useState } from 'react';
import { Grid, styled } from '@mui/material';
import { Chip } from '../../atoms/Chip/Chip';
import { Typography } from '../../atoms/Typography/Typography';
import { UniversalCard } from '../UniversalCard/UniversalCard';
import { useTranslation } from 'react-i18next';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';
import { ConfirmationCentered } from '../ConfirmationDialog/ConfirmationDialog';
import { useAppSelector, useAppDispatch } from '../../../common/hooks';
import { syncAdviceGoals } from '../../../features/client-goals';

export interface GoalSummaryChipProps {
    headerIcon: React.ReactNode | React.ReactNode[];
    goalType: string;
    isRetirement?: boolean;
    goal_name: string;
    priority: 'Need' | 'Want' | 'Wish' | 'Dream';
    goalAmount: number;
    target_date_data: string;
    retirement_age?: number;
    goalId: string;
    // eslint-disable-next-line no-unused-vars
    onGoalDelete: (goalId: string) => void;
    onEdit?:() => void;
}

const StyledDivLeft = styled('div')({
    display: 'flex',
    justifyContent: 'left',
    flexDirection: 'column',
    marginRight: '1rem'
});

const StyledDivRight = styled('div')({
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    width: '30%'
});

const StyledDivRetRight = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'left',
    flexDirection: 'column',
    marginLeft: '10px'
});

const StyledRow = styled('div')({
    marginTop: '10px',
    marginBottom: '5px'
});

const StyledDivPriority = styled('div')({
    display: 'flex',
    alignItems: 'left'
});

export const styledMainDiv = styled('div')({
    paddingTop: '0px'
});

export const GoalSummaryChip = ({
    goal_name,
    goalType,
    headerIcon,
    priority,
    goalAmount,
    target_date_data,
    retirement_age,
    isRetirement,
    goalId,
    onGoalDelete,
    onEdit
}: GoalSummaryChipProps) => {
    const { t } = useTranslation();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const dispatch = useAppDispatch();
    const adviceGoals = useAppSelector((state) => state.adviceGoals);
    console.log('adviceGoals in goal creation:', adviceGoals);
    const banner = adviceGoals?.global?.banner;
    const onConfirmDelete = () => {
        dispatch(
            syncAdviceGoals({
                ...adviceGoals,
                formFields: adviceGoals.formFields.filter((goal) => goal.id !== goalId),
                global: {
                    banner: { ...banner, visible: true, type: 'attention', isGoalSummary: true }
                }
            })
        );
        setShowDeleteModal(false);
        onGoalDelete(goalId);
    };

    return (
        <UniversalCard
            header={goalType}
            headerIcon={headerIcon}
            actionMenuOptions={[
                {
                    label: 'Edit',
                    onClick: () => {if(onEdit) onEdit()},
                    icon: 'edit'
                },
                {
                    label: 'Delete',
                    onClick: () => setShowDeleteModal(!showDeleteModal),
                    icon: 'delete'
                }
            ]}
            onClickViewAll={() => {}}
            showSubcontent={false}
        >
            <div>
                <StyledRow>
                    <Typography variant="label" label={t('GOAL_SUMMARY_DETAIL_TEXT')} />
                    <StyledDivPriority>
                        <StyledDivLeft>
                            <Typography variant="h3" label={goal_name} />
                        </StyledDivLeft>
                        <StyledDivRight>{priority && <Chip type={priority} />} &nbsp;</StyledDivRight>
                    </StyledDivPriority>
                </StyledRow>
                <StyledRow>
                    <Typography
                        variant="label"
                        label={
                            isRetirement ? t('GOAL_SUMMARY_TARGET_INCOME_TEXT') : t('GOAL_SUMMARY_TARGET_AMOUNT_TEXT')
                        }
                    />
                    <Typography variant="h3" label={<CurrencyFormatter amount={goalAmount} />} />
                </StyledRow>
                <StyledRow>
                    <StyledDivPriority>
                        <StyledDivLeft>
                            <Typography
                                variant="label"
                                label={
                                    isRetirement
                                        ? t('GOAL_SUMMARY_RETIREMENT_DATE_TEXT')
                                        : t('GOAL_SUMMARY_TARGET_DATE_TEXT')
                                }
                            />
                            <Typography variant="h3" label={target_date_data} />
                        </StyledDivLeft>
                        {isRetirement && retirement_age && (
                            <StyledDivRetRight>
                                <Typography variant="label" label={t('GOAL_SUMMARY_RETIREMENT_YEARS_TEXT')} />
                                <Typography variant="h3" label={retirement_age} />
                            </StyledDivRetRight>
                        )}
                    </StyledDivPriority>
                </StyledRow>
            </div>
            {showDeleteModal && (
                <Grid
                    width="100%"
                    height="100%"
                    position="absolute"
                    container
                    justifyContent="center"
                    alignItems="center"
                >
                    <ConfirmationCentered
                        cancelButtonLabel="Cancel"
                        confirmButtonLabel="Delete"
                        title="Are you sure you want to delete this goal?
                    This action can't be undone."
                        content=""
                        onCancellation={() => setShowDeleteModal(false)}
                        onConfirmation={() => onConfirmDelete()}
                        open
                    />
                </Grid>
            )}
        </UniversalCard>
    );
};

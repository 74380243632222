import {
    TableContainer,
    tableContainerClasses,
    TableCell,
    tableCellClasses,
    Table,
    TableHead,
    TableRow,
    TableBody
} from '@mui/material';
import styled from '@mui/system/styled';
import { RowData, ColumnDef, useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { BasicButton } from '../../atoms';

interface TableProps<K extends RowData> {
    data: K[];
    columns: ColumnDef<K>[];
    button: string;
    buttonOnClick: () => void;
}

export const StyledTableContainer = styled(TableContainer)(() => ({
    [`&.${tableContainerClasses.root}`]: {
        width: '100%',
        border: '1px solid #E5E5E5'
    }
}));

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.root}`]: {
        borderBottom: 'none'
    },
    [`&.${tableCellClasses.body}`]: {
        color: '#3768FF',
        fontWeight: '500',
        fontSize: '18px',
        lineHeight: '16px',
        borderBottom: 'none'
    },
    [`&.${tableCellClasses.head}`]: {
        color: '#797979',
        fontSize: '18px',
        lineHeight: '16px'
    }
}));
('');

const StyledTableHeadCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        color: '#797979',
        fontSize: '18px',
        lineHeight: '16px',
        borderBottom: 'none'
    }
}));

const ButtonContainer = styled('span')(() => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '25px',
    backgroundColor: 'white',
    position: 'sticky',
    bottom: '0',
    padding: '30px 0'
}));

export default function FinancialAdvisorTable<K>({ data, columns, button, buttonOnClick }: TableProps<K>) {
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel()
    });

    return (
        <StyledTableContainer sx={{ maxHeight: '440px' }}>
            <Table stickyHeader>
                <TableHead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <StyledTableHeadCell key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder ?
                                        null :
                                        flexRender(header.column.columnDef.header, header.getContext())}
                                </StyledTableHeadCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody>
                    {table.getRowModel().rows.map((row) => (
                        <TableRow key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <StyledTableCell key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <ButtonContainer>
                <BasicButton variant="contained" type="submit" onClick={buttonOnClick}>
                    {button}
                </BasicButton>
            </ButtonContainer>
        </StyledTableContainer>
    );
}

import { Theme } from '@mui/material';

export default function Chip(theme: Theme) {
    return {
        MuiChip: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(0.5),
                    height: '28px'
                },
                colorWarning: {
                    backgroundColor: '#FFB884'
                },
                colorSuccess: {
                    backgroundColor: '#6DC09D'
                },
                colorError: {
                    backgroundColor: '#FF4C57'
                }
            }
        }
    };
}

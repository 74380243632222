// import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
// import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { uipApiInstance } from '../index';

export const updateUserDetails = createAsyncThunk(
    'admin/updateUser',
    // async (data: any, { dispatch, rejectWithValue }) => {
    async (data: any) => {
        try {
            const response = await uipApiInstance({
                method: 'POST',
                url: '/api/advisorportal/updateuser',
                withCredentials: false,
                data
            });
            return response.data.success;
        } catch (err) {
            console.log(err);
            // dispatch(logEventToBackEnd('ADMIN_UPDATE_USER_API_ERROR'));
            // return rejectWithValue(err);
        }
    }
);

export const addUserDetails = createAsyncThunk('admin/addUser', async (data: any) => {
    try {
        const response = await uipApiInstance({
            method: 'POST',
            url: '/api/advice/adduser',
            withCredentials: false,
            data
        });
        return response.data.success;
    } catch (err) {
        console.log(err);
    }
});

import { fontFamily } from '../../common/constants';

export default function HelperText() {
    //This is to match the styles of default error message in forms
    return {
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    '&.Mui-error': {
                        color: 'red',
                        fontFamily,
                        fontWeight: 500,
                        fontSize: 'calc(1rem + (1 - 1) * ((100vw - 20rem) / (120 - 20)))',
                        lineHeight: 1.5,
                        letterSpacing: '0.00938em',
                        margin: '10px 0 0 0'
                    }
                }
            }
        }
    };
}

import { useOktaAuth } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { logout } from '../../../features/auth/authSlice';
import { logEventToBackEnd, updateGlobalLoaderState } from '../../../features/global/globalSlice';
import { Nav } from '../Nav';
import { advisorConsoleActions } from '../../../features/advisorConsole';
import { initializeGoals } from '../../../features/client-goals';
import { initializeAdviceClient } from '../../../features/client';
import { resetAdviceAdminReducer } from '../../../features/adviceAdmin';

export interface HeaderProps {
    eulaVariant?: boolean;
    showHeaderWithoutOptions?: boolean;
}

export const Header = ({ showHeaderWithoutOptions }: HeaderProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { authState, oktaAuth } = useOktaAuth();
    const isAuthenticated = authState?.isAuthenticated;
    const isAuthLoading = useAppSelector((state) => state.auth.loading);
    const { user } = useAppSelector((state) => state.auth);

    // Reset all reducer to their initial states
    const clearAllStateData = async () => {
        dispatch(advisorConsoleActions.resetAdviceConsoleReducer());
        dispatch(initializeGoals());
        dispatch(resetAdviceAdminReducer());
        dispatch(initializeAdviceClient());
    };

    const handleLogout = async () => {
        updateGlobalLoaderState(true);

        await dispatch(logEventToBackEnd('LOGOUT_SUCCESS'));
        dispatch(logout());
        oktaAuth.tokenManager.clear();
        clearAllStateData();
        navigate('/');
    };

    const handleAdminPortalNavigate = () => {
        navigate('/admin');
    };

    if (!(isAuthenticated && !isAuthLoading) && !showHeaderWithoutOptions) {
        return null;
    }

    const navItems = [
        {
            label: 'CONSOLE',
            to: '/clients'
        },
        {
            label: 'RESOURCES',
            to: '/resources',
            items: [
                {
                    label: 'TUTORIALS',
                    to: '/resources/tutorials'
                },
                {
                    label: 'ARTICLES',
                    to: '/resources/articles'
                },
                {
                    label: 'FAQ',
                    to: '/resources/faq'
                }
            ]
        }
    ];

    return <Nav items={navItems} user={user} onClickLogout={handleLogout} onClickAdmin={handleAdminPortalNavigate} />;
};
export default Header;

import { styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Typography } from '../../components/atoms/Typography/Typography';
import BreadcrumbsComponent from '../../components/molecules/Breadcrumbs/Breadcrumbs';
import { Tabs } from '../../components/molecules/Tabs/Tabs';
import SummaryUserForm from '../../components/organisms/SummaryUserForm/SummaryUserForm';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { updateBreadcrumbLabel } from '../../features/adviceAdmin';
import { ClientsViewOnly } from '../../containers/user-view/ClientsViewOnly';
import { useTranslation } from 'react-i18next';
import { formatAdvisorName } from '../../common/helper';

export const User = () => {
    const dispatch = useAppDispatch();
    const [tabIndex, setTabIndex] = useState(0);
    const { t } = useTranslation();

    const tabPanels = [
        { enabled: true, title: t('TEXT_ADMIN_TAB_1'), hidden: false, children: <SummaryUserForm /> },
        { enabled: true, title: t('TEXT_ADMIN_TAB_2'), hidden: false, children: <ClientsViewOnly /> },
        { enabled: true, title: t('TEXT_ADMIN_TAB_4'), hidden: false, children: 'Tab 3 content' }
    ];

    const handleTabChange = (event: any, newValue: React.SetStateAction<number> | any) => {
        const breadcrumbLabel: any = tabPanels[newValue]?.title;
        dispatch(updateBreadcrumbLabel(breadcrumbLabel));
        setTabIndex(newValue);
    };

    useEffect(() => {
        dispatch(updateBreadcrumbLabel(tabPanels[0]?.title));
    }, []);

    const navigate = useNavigate();
    const breadcrumbLabel = useAppSelector((state) => state.advisorAdmin.breadcrumbLabel);
    const selectedUser = useAppSelector((state) => state.advisorAdmin.selectedUser);

    const StyledBreadCrumbOne = styled(Typography)(() => ({
        color: 'blue',
        fontSize: '22px',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline 1px',
            textUnderlineOffset: '3px'
        }
    }));
    const StyledBreadCrumbTwo = styled(Typography)(() => ({
        color: 'blue',
        fontSize: '22px',
        cursor: 'pointer'
    }));
    const breadcrumbs = [
        <StyledBreadCrumbOne label={'Dashboard'} key="1" variant="body1" onClick={() => navigate('/admin')} />,
        <StyledBreadCrumbOne
            label={formatAdvisorName(selectedUser) || 'Please select a advisor'}
            key="2"
            variant="body1"
            onClick={() => {
                handleTabChange(null, 1);
            }}
        />,
        <StyledBreadCrumbTwo label={breadcrumbLabel} key="3" variant="body1" />
    ];

    return (
        <>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} />
            <Tabs tabPanels={tabPanels} value={String(tabIndex)} onChange={handleTabChange} />
        </>
    );
};

import { useEffect, useMemo, useState } from 'react';
import { DrawerComp, InfoTooltip } from '../../components/molecules';
import { styled } from '@mui/material/styles';
import { TextboxInput, ComboSelect, Typography, BasicDatePicker } from '../../components/atoms';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../common/hooks';
import { createGoal, updateBanner, updateGoal } from '../../features/client-goals';
import { WealthGoalsFormType, GoalPriorityEnum, GoalTypeProps } from '../../common/types/clients-entity';
import dayjs, { Dayjs } from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { WEALTH_GOAL } from '../../common/constants';
import { MonetarySource, Source } from '../../components/molecules/MonetarySource/MonetarySource';
import { formatCurrencyDollar, numberWithCommas, removeNonNumeric } from '../../utils';
import { getHeaderIconFromType, getYYYYMMDDfromDate } from '../../common/helper';
import { updateGlobalLoaderState } from '../../features/global/globalSlice';
import { GoalType } from '../../components/molecules/GoalType/GoalType';
import { Grid } from '@mui/material';

export interface WealthGoalProps {
    // eslint-disable-next-line no-unused-vars
    handleClose: (flag: boolean) => void;
    onSubmit?: () => void;
    loading?: boolean;
    heading?: string;
    subHeading?: string;
    selectedGoal?: boolean | GoalTypeProps;
    goalData?: any;
    open: boolean;
}

export const ItemContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '6px',
    '& a': {
        color: theme.palette.background.paper
    }
}));

export const Footer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    fontSize: '10px',
    height: '100%'
}));

export const ItemContainerSplit = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: '8px',
    '& a': {
        color: theme.palette.background.paper
    }
}));

const GoalFormWrapper = styled('div')(() => ({
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
    fontSize: '14px'
}));

export const ErrorSpan = styled(Typography)(() => ({
    color: 'red',
    display: 'flex',
    marginTop: '5px'
}));

export const SavedFundsWrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
}));

export const Wrap = styled('div')(() => ({
    position: 'relative'
}));

interface FrequencyProps {
    selected: boolean;
}

export const Frequency = styled('div')<FrequencyProps>(({ selected }) => ({
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    aspectRatio: '1/1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #E0E0E0',
    cursor: 'pointer',
    backgroundColor: selected ? '#E0E0E0' : 'transparent'
}));

export const Placeholder = styled('div')(() => ({
    height: '21px'
}));

export const WealthGoals = ({ handleClose, selectedGoal, open, goalData }: WealthGoalProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { orgInfo } = useAppSelector((state) => state.global);
    const clientData = useAppSelector((state) => state.adviceClient);
    const adviceGoals = useAppSelector((state) => state.adviceGoals || []);
    const banner = adviceGoals?.global?.banner;
    const investmentSourceData = useMemo(
        () =>
            clientData?.accounts.investmentsSources.reduce((acc: Record<string, Source>, curr) => {
                acc[curr.accountId] = {
                    accountNumber: curr.accountId,
                    accountName: curr.accountName,
                    frequency: curr.frequency,
                    isSelected: true,
                    isRCSelected: goalData
                        ? adviceGoals.formFields?.some((goal) =>
                              goal?.data?.sources?.investmentSource?.some(
                                  (inv: { accountNumber: string; isRCSelected: boolean }) =>
                                      inv.accountNumber === curr.accountId &&
                                      inv.isRCSelected &&
                                      goal?.goalId === goalData?.goalId
                              )
                          )
                        : false,
                    accountBalance: curr.amount,
                    recurringContributions: curr.recurringContributions,
                    disabled: goalData
                        ? adviceGoals.formFields?.some((goal) =>
                              goal?.data?.sources?.investmentSource?.some(
                                  (inv: { accountNumber: string; isRCSelected: boolean }) =>
                                      inv.accountNumber === curr.accountId &&
                                      inv.isRCSelected &&
                                      goal?.goalId !== goalData?.goalId
                              )
                          )
                        : adviceGoals.formFields?.some((goal) =>
                              goal?.data?.sources?.investmentSource?.some(
                                  (inv: { accountNumber: string; isRCSelected: boolean }) =>
                                      inv.accountNumber === curr.accountId && inv.isRCSelected
                              )
                          )
                };
                return acc;
            }, {}),
        [adviceGoals.formFields]
    );
    const incomeSourceData = useMemo(
        () =>
            clientData?.accounts.incomeSources.reduce((acc: Record<string, Source>, curr) => {
                acc[curr.accountId] = {
                    accountNumber: curr.accountId,
                    accountName: curr.sourceName,
                    isSelected: goalData
                        ? adviceGoals.formFields?.some((goal) =>
                              goal?.data?.sources?.incomeSource?.some(
                                  (inc: any) =>
                                      inc.accountNumber === curr.accountId &&
                                      inc.isSelected &&
                                      goal?.goalId === goalData?.goalId
                              )
                          )
                        : false,
                    accountBalance: curr.frequency === 'Annually' ? curr.amount : 12 * curr.amount,
                    disabled: goalData
                        ? adviceGoals.formFields?.some((goal) =>
                              goal?.data?.sources?.incomeSource?.some(
                                  (inc: any) =>
                                      inc.accountNumber === curr.accountId &&
                                      inc.isSelected &&
                                      goal?.goalId !== goalData?.goalId
                              )
                          )
                        : adviceGoals.formFields?.some((goal) =>
                              goal?.data?.sources?.incomeSource?.some(
                                  (inc: any) => inc.accountNumber === curr.accountId
                              )
                          )
                };
                return acc;
            }, {}),
        [adviceGoals.formFields]
    );

    const [incomeSource, setIncomeSourceData] = useState(incomeSourceData);
    const [investmentSource, setInvestmentSource] = useState(investmentSourceData);

    const {
        control,
        formState: { errors, isValid },
        trigger,
        reset,
        getValues,
        setValue
    } = useForm<WealthGoalsFormType>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            goalDate: null as Dayjs | null,
            goalType: 'Wealth Goal',
            goalId: '',
            goalName: '',
            goalPriority: undefined
        }
    });

    const incomeSourceSelected = Object.values(incomeSource).filter((source) => source.isSelected);
    const investmentSourceSelected = Object.values(investmentSource).filter((source) => source.isSelected);

    const submitFunds = async () => {
        try {
            const data = getValues();
            trigger();

            if (
                !goalData &&
                isValid &&
                clientData?._id &&
                typeof selectedGoal !== 'boolean' &&
                (incomeSourceSelected.length > 0 || investmentSourceSelected.length > 0)
            ) {
                const generatedId = uuidv4();
                data.goalType = WEALTH_GOAL;
                data.wealthGoalType = goalData ? goalData?.title : selectedGoal?.title;
                data.sources = {
                    incomeSource: incomeSourceSelected,
                    investmentSource: investmentSourceSelected
                };

                let amountVal = data['goalAmount'];
                var formatedAmountVal = Number(removeNonNumeric(amountVal));
                data['goalAmount'] = formatedAmountVal;
                data['goalDate'] = getYYYYMMDDfromDate(data['goalDate']);
                const nextIndex = !adviceGoals?.formFields ? 0 : adviceGoals.formFields.length + 1;
                const newFormField = {
                    goalId: generatedId,
                    data: {
                        ...data,
                        index: nextIndex,
                        goalId: generatedId,
                        createdAt: new Date().toISOString()
                    }
                };
                let originalGoalsData: typeof adviceGoals = JSON.parse(JSON.stringify(adviceGoals));
                const updatedGoalsData = Object.assign({}, originalGoalsData);
                updatedGoalsData.formFields = updatedGoalsData?.formFields
                    ? [...updatedGoalsData?.formFields, newFormField]
                    : [newFormField];
                updatedGoalsData.domainEmail = orgInfo?.orgEmail ?? '';
                updatedGoalsData.goeConfigEmail = orgInfo?.orgEmail ?? '';
                updatedGoalsData.riskProfile = clientData.riskProfile;
                updatedGoalsData.assignedClientId = clientData._id;
                updatedGoalsData.error = false;
                updatedGoalsData.loading = false;
                dispatch(updateGlobalLoaderState(true));
                if (!updatedGoalsData._id) {
                    await dispatch(createGoal(updatedGoalsData));
                } else {
                    await dispatch(updateGoal(updatedGoalsData));
                }
                dispatch(updateGlobalLoaderState(false));
                reset();
                handleClose(false);
            } else if (typeof selectedGoal !== 'boolean' && goalData) {
                handleEditClick();
            }
        } catch (error) {
            console.error('Error in creating goals', error);
        }
    };

    const handleGoalPriorityChange = (selectedValue: GoalPriorityEnum) => {
        setValue('goalPriority', selectedValue, { shouldValidate: true });
    };

    const convertToCommaFormat = (e: any, name: any) => {
        const value = Number(removeNonNumeric(e.target.value));
        setValue(name, `${'$' + numberWithCommas(value)}`);
    };

    const convertDateFormatReverse = (dateStr: string) => {
        // Regular expression to check if the date is in mm-dd-yyyy format
        const regex = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-(\d{4})$/;

        // Check if the dateStr matches the mm-dd-yyyy format
        if (!regex.test(dateStr)) {
            return dateStr;
        }

        // Split the input string by '-'
        const parts = dateStr.split('-');
        const month = parts[0];
        const day = parts[1];
        const year = parts[2];

        // Create the dd-mm-yyyy format
        const convertedDate = `${day}-${month}-${year}`;
        return convertedDate;
    };

    const handleEditClick = async () => {
        trigger();
        if (isValid) {
            dispatch(updateGlobalLoaderState(true));
            let originalGoalsData: typeof adviceGoals = JSON.parse(JSON.stringify(adviceGoals));
            const updatedGoalsData = Object.assign({}, originalGoalsData);
            const data = getValues();
            updatedGoalsData?.formFields.forEach((item) => {
                const itemData = item['data'];
                if (item.goalId === goalData?.goalId && item.goalType != 'retirement') {
                    itemData.sources = {
                        incomeSource: incomeSourceSelected,
                        investmentSource: investmentSourceSelected
                    };
                    let amountVal = data['goalAmount'];
                    var formatedAmountVal = Number(removeNonNumeric(amountVal));
                    itemData['goalAmount'] = formatedAmountVal;
                    itemData['goalDate'] =
                        typeof data['goalDate'] === 'string'
                            ? convertDateFormatReverse(data['goalDate'])
                            : getYYYYMMDDfromDate(data['goalDate']);
                    itemData['goalPriority'] = data['goalPriority'];
                    itemData['goalName'] = data['goalName'];
                }
            });
            await dispatch(updateGoal(updatedGoalsData));
            if (goalData) {
                dispatch(
                    updateBanner({
                        ...banner,
                        visible: true,
                        type: 'attention',
                        isGoalSummary: true,
                        message: t('GOAL_DELETE_BANNER_ATTENTION_MESSAGE_SUMMARY')
                    })
                );
            }
            reset();
            dispatch(updateGlobalLoaderState(false));
            handleClose(false);
        }
    };
    const convertDateFormat = (dateStr: string) => {
        // Regular expression to check if the date is in dd-mm-yyyy format
        const regex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{4})$/;

        // Check if the dateStr matches the dd-mm-yyyy format
        if (!regex.test(dateStr)) {
            return dateStr;
        }

        // Split the input string by '-'
        const parts = dateStr.split('-');
        const day = parts[0];
        const month = parts[1];
        const year = parts[2];

        // Create the mm-dd-yyyy format
        const convertedDate = `${month}-${day}-${year}`;
        return convertedDate;
    };

    useEffect(() => {
        if (goalData) {
            reset({
                goalAmount: goalData?.goalAmount,
                goalId: goalData?.goalId,
                goalPriority: goalData?.goalPriority,
                goalName: goalData?.goalName,
                goalDate: convertDateFormat(goalData?.targetDate) // it should be "01-20-2056" - mm-dd-yyyy
            });
        }
    }, []);

    return (
        <DrawerComp
            open={open}
            buttonlabel="Complete"
            header={goalData ? goalData?.title : typeof selectedGoal !== 'boolean' ? selectedGoal?.heading : ''}
            drawerIcon={
                <GoalType
                    type={
                        getHeaderIconFromType(
                            goalData
                                ? goalData?.title
                                : typeof selectedGoal !== 'boolean'
                                ? selectedGoal?.title
                                : 'Buy a House'
                        )!
                    }
                />
            }
            handleClose={
                goalData
                    ? handleEditClick
                    : () => {
                          reset();
                          handleClose(false);
                      }
            }
            onSubmit={submitFunds}
            disableSubmit={!isValid || (incomeSourceSelected.length === 0 && investmentSourceSelected.length === 0)}
        >
            <GoalFormWrapper>
                <ItemContainer sx={{ alignItems: 'flex-start', width: '100%' }}>
                    <Typography variant="body2" label={t('TEXT_GOAL_NAME')} />
                    <Controller
                        name="goalName"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <TextboxInput
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                inputRef={ref}
                                size="small"
                                error={!!errors.goalName}
                                type="text"
                                data-testid="wealth-goal-name"
                                placeholder={t('TEXT_GOAL_NAME')}
                                fullWidth
                            />
                        )}
                    />
                    {errors.goalName ? (
                        <ErrorSpan variant="subtitle2" role="alert" label={errors.goalName.message} />
                    ) : null}
                </ItemContainer>
                <ItemContainer sx={{ alignItems: 'flex-start', width: '100%' }}>
                    <Grid container alignItems="center">
                        <Typography variant="body2" label={`${t('TEXT_GOAL_PRIORITY')} *`} />
                        <InfoTooltip
                            message={
                                <Trans
                                    i18nKey="GOAL_PRIORITY_INFO_TEXT"
                                    components={{ strong: <strong />, br: <br /> }}
                                />
                            }
                        />
                    </Grid>
                    <Controller
                        name="goalPriority"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                            }
                        }}
                        render={({ field }) => (
                            <ComboSelect
                                {...field}
                                onSelectChange={handleGoalPriorityChange}
                                size="small"
                                selectedValue={field.value ?? ''}
                                options={Object.keys(GoalPriorityEnum).map((key) => ({
                                    label: GoalPriorityEnum[key as keyof typeof GoalPriorityEnum],
                                    value: GoalPriorityEnum[key as keyof typeof GoalPriorityEnum]
                                }))}
                                data-testid="goal-priority"
                            />
                        )}
                    />
                    {errors.goalPriority && (
                        <ErrorSpan variant="subtitle2" role="alert" label={errors.goalPriority.message} />
                    )}
                </ItemContainer>

                <ItemContainer sx={{ alignItems: 'flex-start', width: '100%' }}>
                    <Typography variant="body2" label={t('GOAL_SUMMARY_TARGET_DATE_TEXT')} />

                    <Controller
                        name="goalDate"
                        control={control}
                        rules={{
                            required: { value: true, message: t('TEXT_THIS_IS_REQUIRED_FIELD') },
                            validate: (value) => {
                                console.log('goalData', goalData);
                                if (!value) {
                                    return t('TEXT_THIS_IS_REQUIRED_FIELD');
                                }

                                const today = dayjs();
                                const oneYearFromToday = today.add(1, 'year');
                                const dateValue = dayjs(value);
                                if (!dateValue.isValid()) {
                                    return false;
                                }
                                if (dateValue.isAfter(today, 'day')) {
                                    return dateValue.isAfter(oneYearFromToday) || t('TEXT_DATE_AT_LEAST_ONE_YEAR');
                                } else {
                                    return false;
                                }
                            }
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <BasicDatePicker
                                enabled
                                type="single"
                                startDate={dayjs().add(1, 'day').format()}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                data-testid="goal-date"
                                minErrorMsg={t('TEXT_VALID_FUTURE_DATE')}
                            />
                        )}
                    />
                    {errors.goalDate && <ErrorSpan variant="subtitle2" role="alert" label={errors.goalDate.message} />}
                </ItemContainer>

                <ItemContainer sx={{ alignItems: 'flex-start', width: '100%' }}>
                    <Typography variant="body2" label={t('TEXT_GOAL_TARGET_AMOUNT')} />
                    <Controller
                        name="goalAmount"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                            },
                            validate: (value) => {
                                // Remove '$' and any commas, then convert to number
                                const numericValue = Number(value.toString().replace(/[$,]/g, ''));

                                if (isNaN(numericValue)) {
                                    return t('TEXT_INVALID_AMOUNT');
                                }

                                if (numericValue < 1) {
                                    return t('TEXT_VALID_TARGET_GOAL_AMOUNT_ERROR');
                                }

                                return true;
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <TextboxInput
                                onBlur={(e: any) => {
                                    if (e.target.value) {
                                        convertToCommaFormat(e, name);
                                    }
                                    onBlur();
                                }}
                                onChange={(e) => {
                                    e.target.value = formatCurrencyDollar(e.target.value);
                                    onChange(e);
                                }}
                                value={value}
                                inputRef={ref}
                                size="small"
                                error={!!errors.goalAmount}
                                type="text"
                                data-testid="goal-amount"
                                placeholder={t('TEXT_GOAL_TARGET_AMOUNT')}
                                fullWidth
                            />
                        )}
                    />
                    {errors.goalAmount ? (
                        <ErrorSpan variant="subtitle2" role="alert" label={errors.goalAmount.message} />
                    ) : null}
                </ItemContainer>

                <MonetarySource
                    title={
                        <Grid container alignItems="center">
                            <Typography label={t('INCOME_SOURCES')} variant="h5" />
                            <InfoTooltip message={t('INCOME_SOURCES_INFO_TEXT')} />
                        </Grid>
                    }
                    label={t('SOURCES_AVAILABLE_ANNUALLY')}
                    accountData={incomeSource}
                    onAccountChange={(sources) => {
                        setIncomeSourceData(sources);
                    }}
                    isOpen={true}
                />
                <MonetarySource
                    title={
                        <Grid container alignItems="center">
                            <Typography label={t('WEALTH_SOURCES')} variant="h5" />
                            <InfoTooltip message={t('WEALTH_SOURCES_INFO_TEXT')} />
                        </Grid>
                    }
                    label={t('ACCOUNTS_AVAILABLE')}
                    accountData={investmentSource}
                    onAccountChange={(sources) => {
                        setInvestmentSource(sources);
                    }}
                    disabled={true}
                    isOpen={true}
                />
                <div>
                    <Typography variant="body.largeBold" label={t('WEALTH_SPLITTER_TITLE')} />
                    <Typography variant="body.large" label={t('WEALTH_SPLITTER_TEXT')} />
                </div>
            </GoalFormWrapper>
        </DrawerComp>
    );
};

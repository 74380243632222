import { useEffect, useState } from 'react';
import { fetchClients } from '../../services/clients';
// import { CreateClientRelationship } from '../../components/organisms';
import AddIncomeSourcesDrawer from '../../containers/add-income-sources-drawer/index';
import { useAppSelector } from '../../common/hooks';

const ClientsTest = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [, setIsRelationship] = useState(false);
    const [isAddIncomeSourceDrawerOpen, setIsAddIncomeSourceDrawerOpen] = useState(false);
    const tenant = useAppSelector((state) => state?.global?.globalConfig?.subdomain);
    const getClients = async () => {
        const res = await fetchClients({ tenant });
        console.log(res);
    };
    useEffect(() => {
        getClients();
    }, []);

    return (
        <>
            <button
                onClick={() => {
                    setOpenDrawer(!openDrawer);
                    setIsRelationship(false);
                }}
            >
                Create Client
            </button>
            <button
                onClick={() => {
                    setOpenDrawer(!openDrawer);
                    setIsRelationship(true);
                }}
            >
                Create Relationship
            </button>

            {/* <CreateClientRelationship isRelationship={isRelationship} setOpenDrawer={setOpenDrawer} openDrawer={openDrawer}/> */}
            <button
                onClick={() => {
                    setIsAddIncomeSourceDrawerOpen(true);
                }}
            >
                Income Sources
            </button>
            <AddIncomeSourcesDrawer
                setOpenDrawer={setIsAddIncomeSourceDrawerOpen}
                openDrawer={isAddIncomeSourceDrawerOpen}
            />
        </>
    );
};

export default ClientsTest;

import { useState } from 'react';
import { styled } from '@mui/material';

import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-input-mask';

import { BasicButton, ComboSelect, TextboxInput, Typography, Avatar } from '../../atoms';
import { InfoBox } from '../../atoms/InfoBox/InfoBox';
import { ACCESS_LEVEL_DROPDOWN_OPTIONS } from '../../../common/constants';
import { updateUserDetails } from '../../../services/userDetails';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { toShortDate } from '../../../utils';
import { updateSelectedUser } from '../../../features/adviceAdmin';

export interface ISummaryUserFormProps {
    name: string;
    email: string;
    phoneNumber: string;
    accessLevel: string;
}

const StyledMainContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    border: `1px solid ${theme.palette.grey[600]}`,
    padding: '20px',
    width: '70%'
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    alignSelf: 'center',
    height: '120px',
    width: '120px',
    fontSize: '60px',
    backgroundColor: theme.palette.success.main
}));

const StyledFormContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '80%',
    marginLeft: '20px'
}));

const StyledTextboxInput = styled(TextboxInput)(({ theme }) => ({
    margin: '0',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
        marginBottom: '5px'
    }
}));

const StyledButtonContainer = styled('span')(() => ({
    display: 'flex',
    gap: '5px',
    margin: '6px',
    alignSelf: 'end'
}));

const StyledForm = styled('form')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
}));

const StyledFieldContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '5px'
}));

const StyledTitle = styled(Typography)(() => ({
    margin: '30px 0',
    fontSize: '22px',
    fontWeight: 600
}));

const ErrorSpan = styled('span')(({ theme }) => ({
    color: theme.palette.error.dark,
    fontSize: '12px'
}));

interface IFormValue {
    name: string;
    email: string;
    phoneNumber: string;
    accessLevel: string;
}

export const SummaryUserForm = () => {
    const dispatch = useAppDispatch();
    const [onEdit, setOnEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [formValue, setFormValue] = useState<IFormValue>();
    const tenant = useAppSelector((state) => state.global?.globalConfig?.subdomain);
    const selectedUser = useAppSelector((state) => state.advisorAdmin.selectedUser);

    const { t } = useTranslation();
    const createdOnDate = selectedUser?.createdOn ? toShortDate(selectedUser.createdOn) || '-' : '-';
    const avatarName =
        selectedUser?.userType === 'admin' ?
            selectedUser?.adminName?.substring(0, 1) :
            selectedUser?.advisorName?.substring(0, 1);
    const [advisorValues, setAdvisorValues] = useState<any>({
        ...selectedUser,
        fullName: selectedUser?.userType === 'admin' ? selectedUser?.adminName : selectedUser?.advisorName,
        advisorEmail: selectedUser?.userType === 'admin' ? selectedUser?.adminEmail : selectedUser?.advisorEmail,
        advisorPhoneNumber:
            selectedUser?.userType === 'admin' ? selectedUser?.adminPhoneNumber : selectedUser?.advisorPhoneNumber,
        accessLevel: selectedUser?.userType,
        createdOn: selectedUser?.createdOn
    });
    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm<IFormValue>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues:
            selectedUser?.userType === 'admin' ?
                {
                      name: selectedUser?.adminName,
                      email: selectedUser?.adminEmail,
                      phoneNumber: selectedUser?.adminPhoneNumber,
                      accessLevel: selectedUser?.userType
                  } :
                {
                      name: selectedUser?.advisorName,
                      email: selectedUser?.advisorEmail,
                      phoneNumber: selectedUser?.advisorPhoneNumber,
                      accessLevel: selectedUser?.userType
                  }
    });

    const onSubmit = async (data: IFormValue) => {
        setLoading(true);
        // setFormValue(data);
        await dispatch(
            updateUserDetails({
                firstName: data.email,
                phoneNumber: data.phoneNumber,
                role: data.accessLevel,
                tenant,
                id: selectedUser?.id,
                createdOn: createdOnDate
            })
        )
            .then((action: any) => {
                const { payload } = action;
                const { success, message } = payload;
                if (success) {
                    dispatch(
                        updateSelectedUser({
                            ...selectedUser,
                            userType: data.accessLevel,
                            ...(data.accessLevel !== 'admin' ?
                                {
                                      advisorName: data.name,
                                      advisorPhoneNumber: data.phoneNumber,
                                      advisorEmail: data.email
                                  } :
                                {
                                      adminName: data.name,
                                      adminPhoneNumber: data.phoneNumber,
                                      adminEmail: data.email
                                  })
                        })
                    );
                    if (message !== 'user updated successfully.') alert(message);
                }
            })
            .finally(() => {
                setAdvisorValues({
                    ...selectedUser,
                    fullName: data.name,
                    advisorPhoneNumber: data.phoneNumber,
                    advisorEmail: data.email,
                    accessLevel: data.accessLevel
                });
                setOnEdit(false);
                setLoading(false);
            });
    };

    const handleEditButtonClick = () => {
        setOnEdit(true);
    };

    const handleCancelButtonClick = () => {
        setOnEdit(false);
    };

    const getAccessLevelLabelByValue = (value: string | string[]) => {
        const options = ACCESS_LEVEL_DROPDOWN_OPTIONS(t);
        const labels = options.length > 0 && options.filter((option) => option.value === value);
        return labels && labels.length > 0 && labels[0].label;
    };

    const getAccessLevelValues = () => {
        const options = ACCESS_LEVEL_DROPDOWN_OPTIONS(t);
        const values = options.map((option) => ({ value: option.value, label: option.label }));
        return values;
    };

    return (
        <>
            <StyledTitle label={t('ADMIN_ADVICE_SUMMARY_HEADER')} />
            <StyledMainContainer>
                {avatarName && <StyledAvatar name={avatarName} />}
                <StyledFormContainer>
                    {!onEdit && advisorValues && (
                        <>
                            <StyledFieldContainer>
                                <InfoBox
                                    title={
                                        selectedUser?.userType === 'admin' ?
                                            t('ADMIN_SUMMARY_FIRST_INFO_TITLE') :
                                            t('ADMIN_ADVICE_SUMMARY_FIRST_INFO_TITLE')
                                    }
                                >
                                    {advisorValues?.fullName}
                                </InfoBox>
                                <InfoBox
                                    title={
                                        selectedUser?.userType === 'admin' ?
                                            t('ADMIN_SUMMARY_SECOND_INFO_TITLE') :
                                            t('ADMIN_ADVICE_SUMMARY_SECOND_INFO_TITLE')
                                    }
                                >
                                    {advisorValues?.advisorEmail}
                                </InfoBox>
                                <InfoBox title={t('ADMIN_ADVICE_SUMMARY_THIRD_INFO_TITLE')}>
                                    {advisorValues?.advisorPhoneNumber || '-'}
                                </InfoBox>
                                <InfoBox title={t('USER_SUMMARY_ACCESS_LEVEL_TITLE')}>
                                    {advisorValues.accessLevel && getAccessLevelLabelByValue(advisorValues.accessLevel)}
                                </InfoBox>
                                <InfoBox title={t('USER_SUMMARY_CREATE_ON_TITLE') + ` : ${createdOnDate}`} />
                            </StyledFieldContainer>
                            <StyledButtonContainer>
                                <BasicButton variant="outlined" onClick={handleEditButtonClick}>
                                    {t('BUTTON_EDIT')}
                                </BasicButton>
                            </StyledButtonContainer>
                        </>
                    )}
                    {onEdit && (
                        <StyledForm onSubmit={handleSubmit(onSubmit)}>
                            <StyledFieldContainer>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <StyledTextboxInput
                                            name="name"
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            value={value}
                                            label={t('ADMIN_ADVICE_SUMMARY_FIRST_INFO_TITLE')}
                                            type="text"
                                            error={!!errors.name}
                                            helperText={errors.name && 'Admin Full Name Required'}
                                            disabled
                                        />
                                    )}
                                />
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <StyledTextboxInput
                                            {...field}
                                            label={t('ADMIN_ADVICE_SUMMARY_SECOND_INFO_TITLE')}
                                            type="email"
                                            error={!!errors.email}
                                            helperText={errors.email && 'Please enter a valid Email'}
                                            disabled
                                        />
                                    )}
                                />
                                <Controller
                                    name="phoneNumber"
                                    control={control}
                                    rules={{
                                        required: false,
                                        pattern: {
                                            value: /^[2-9]{1}[0-9]{2}-[0-9]{3}-[0-9]{4}$/i,
                                            message: t('TEXT_ADMIN_ADVISOR_PHONE_NUMBER_VALIDATION')
                                        }
                                    }}
                                    render={({ field }) => (
                                        <MaskedInput
                                            mask={'999-999-9999'}
                                            value={field.value}
                                            onChange={field.onChange}
                                        >
                                            <StyledTextboxInput
                                                label={t('ADMIN_ADVICE_SUMMARY_THIRD_INFO_TITLE')}
                                                type="text"
                                                error={!!errors.phoneNumber}
                                            />
                                        </MaskedInput>
                                    )}
                                />
                                {errors.phoneNumber && <ErrorSpan role="alert">{errors.phoneNumber.message}</ErrorSpan>}
                                <Controller
                                    name="accessLevel"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Access level is required'
                                        }
                                    }}
                                    render={({ field }) => (
                                        <ComboSelect
                                            {...field}
                                            onSelectChange={field.onChange}
                                            selectedValue={field.value}
                                            placeholder={t('USER_SUMMARY_ACCESS_LEVEL_TITLE')}
                                            label={t('USER_SUMMARY_ACCESS_LEVEL_TITLE')}
                                            options={getAccessLevelValues()}
                                        />
                                    )}
                                />
                                {errors.accessLevel && <ErrorSpan role="alert">{errors.accessLevel.message}</ErrorSpan>}
                                <span>
                                    {t('USER_SUMMARY_CREATE_ON_TITLE')} : {createdOnDate}
                                </span>
                            </StyledFieldContainer>
                            <StyledButtonContainer>
                                <BasicButton variant="outlined" onClick={handleCancelButtonClick}>
                                    {t('BUTTON_CANCEL')}
                                </BasicButton>
                                <BasicButton variant="contained" type="submit" loading={loading}>
                                    {t('BUTTON_SAVE')}
                                </BasicButton>
                            </StyledButtonContainer>
                        </StyledForm>
                    )}
                </StyledFormContainer>
            </StyledMainContainer>
        </>
    );
};

export default SummaryUserForm;

import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { Typography } from '../../atoms/Typography/Typography';
import error from '../../../assets/icons/error.svg';
import confirm from '../../../assets/icons/confirm.svg';
import confirm_disable from '../../../assets/icons/confirm_disable.svg';
import close from '../../../assets/icons/close.svg';
import close_disabled from '../../../assets/icons/close_disabled.svg';
import error_disable from '../../../assets/icons/error_disable.svg';
import alert from '../../../assets/icons/alert.svg';
import alert_disable from '../../../assets/icons/alert_disable.svg';
import warning from '../../../assets/icons/warning.svg';
import warning_disable from '../../../assets/icons/warning_disable.svg';

export interface ToastProps {
    heading: string;
    type: 'error' | 'confirm' | 'alert' | 'warning';
    messages?: [] | Array<string>;
    disabled?: boolean;
    isTimer?: boolean;
    disappearTime?: number;
    handleClose?: () => void;
    handleTryAgain?: () => void;
}

export interface MainDivProps {
    color: string;
    disabled: boolean;
}

export interface DisabledProps {
    disabled: boolean;
}

const StyledMainDiv = styled('div')<MainDivProps>(({ disabled, color, theme }) => ({
    display: 'flex',
    padding: theme.spacing(2),
    gap: '8px',
    borderRadius: '6px',
    background: '#333',
    width: '392px',
    border: disabled ? 'none' : `2px solid ${color}`,
    position: 'fixed',
    left: 0,
    bottom: 0,
    zIndex: 999
}));

const StyledContentDiv = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '80%'
});

const StyledCloseDiv = styled('div')({
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'flex-start'
});

const StyledIconDiv = styled('div')({
    display: 'flex',
    width: '32px',
    padding: '1px 2.3px 1.2px 2px',
    justifyContent: 'center',
    alignItems: 'center'
});

const StyledHeader = styled(Typography)<DisabledProps>(({ disabled, theme }) => ({
    fontSize: theme.spacing(2),
    fontWeight: 500,
    color: disabled ? '#595959' : theme.palette.common.white,
    lineHeight: theme.spacing(2.4)
}));

const StyledContent = styled(Typography)<DisabledProps>(({ disabled, theme }) => ({
    fontSize: '13px',
    fontWeight: 400,
    color: disabled ? '#595959' : theme.palette.common.white,
    lineHeight: theme.spacing(2),
    letterSpacing: '0.13px'
}));

const StyledTryAgain = styled(Typography)<DisabledProps>(({ disabled, theme }) => ({
    fontSize: '13px',
    fontWeight: 400,
    color: disabled ? '#595959' : '#91B9FF',
    lineHeight: theme.spacing(2),
    letterSpacing: '0.13px',
    cursor: disabled ? 'not-allowed' : 'pointer'
}));

const StyledCloseImg = styled('img')({
    width: '18px',
    height: '18px'
});

export const Toast = ({
    heading,
    type,
    messages = [],
    handleClose,
    handleTryAgain,
    disabled = false,
    isTimer = false,
    disappearTime = 5
}: ToastProps) => {
    const [isShown, setIsShown] = useState(true);
    useEffect(() => {
        if (isTimer) {
            setTimeout(() => {
                setIsShown(!isShown);
            }, disappearTime * 1000);
        }
    }, [isTimer]);

    const IconMap = (type: any, disabled: any) => {
        let Icon = disabled ? error_disable : error;
        switch (type) {
            case 'warning':
                Icon = disabled ? warning_disable : warning;
                break;
            case 'confirm':
                Icon = disabled ? confirm_disable : confirm;
                break;
            case 'alert':
                Icon = disabled ? alert_disable : alert;
                break;
        }
        return Icon;
    };

    const colorMap = (type: any) => {
        let color = '#F04F74';
        switch (type) {
            case 'warning':
                color = '#FF6035';
                break;
            case 'confirm':
                color = '#00A096';
                break;
            case 'alert':
                color = '#5291FF';
                break;
        }
        return color;
    };

    return (
        <div>
            {isShown && (
                <StyledMainDiv color={colorMap(type)} disabled={disabled}>
                    <StyledIconDiv>
                        <img src={IconMap(type, disabled)} />
                    </StyledIconDiv>
                    <StyledContentDiv>
                        <StyledHeader label={heading} disabled={disabled} />
                        {messages.length > 0 && (
                            <>
                                {messages.map((message) => {
                                    return <StyledContent key={message} label={message} disabled={disabled} />;
                                })}
                                {handleTryAgain && (
                                    <StyledTryAgain onClick={handleTryAgain} label="Try again" disabled={disabled} />
                                )}
                            </>
                        )}
                    </StyledContentDiv>
                    <StyledCloseDiv>
                        <StyledCloseImg src={disabled ? close_disabled : close} onClick={handleClose} />
                    </StyledCloseDiv>
                </StyledMainDiv>
            )}
        </div>
    );
};

import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import styled from '@emotion/styled';
import { Grid, InputLabel } from '@mui/material';
import {
    BasicButton,
    ComboSelect,
    RadioOption,
    TextboxInput,
    Typography,
    BasicDatePicker
} from '../../components/atoms';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../common/hooks';
import Percent from '@mui/icons-material/Percent';
import dayjs, { Dayjs } from 'dayjs';
import { formatCurrencyDollar } from '../../utils';

const AddIncomeButtonContainer = styled('div')(() => ({
    marginTop: '24px'
}));

const AddIncomeButton = styled(BasicButton)(() => ({
    display: 'flex',
    margin: 'auto',
    fontSize: '14px',
    marginBottom: '18px'
}));

const ErrorSpan = styled('span')(({}) => ({
    color: 'red', // consume from theme later
    fontSize: '12px'
}));

const GridContainer = styled(Grid)(() => ({
    '.MuiFormControl-root': {
        width: '100%'
    }
}));

const StyledForm = styled('form')(() => ({
    marginTop: '-26px'
}));

export const AddIncomeSourcesForm = ({
    onSubmit,
    handleSubmit,
    control,
    errors,
    sourceOwnerOptions,
    keyId,
    convertToCommaFormat,
    mode = 'add'
}: any) => {
    const sourceTypeDropdownValues = useAppSelector((state) => state.global.orgInfo?.incomeSourceType);
    const { t, i18n } = useTranslation();
    const currentSourceTypeDropdownValues = sourceTypeDropdownValues[i18n.language] || [];
    const beginningYearValue = useWatch({ control, name: 'beginningYear' });
    const currentDate = dayjs();
    return (
        <StyledForm
            onSubmit={handleSubmit(onSubmit)}
            id="add-income-sources-form"
            data-testid="add-income-sources-form"
            key={keyId}
        >
            <Grid paddingTop={2}>
                <Typography variant="body1" color="neutral.grey.400" label={t('TEXT_REQUIRED_FIELDS')} />
            </Grid>
            <Grid container spacing={2} fontWeight="500">
                <GridContainer item xs={12} key="sourceNameContainer">
                    <Controller
                        name="sourceName"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: { value: true, message: t('REQUIRED_SOURCE_NAME') },
                            pattern: {
                                value: /^([a-zA-Z0-9 ]+)$/i,
                                message: t('SOURCE_NAME_VALIDATION_MESSAGE')
                            },
                            maxLength: {
                                value: 30,
                                message: t('NAME_MAX_LENGTH_VALIDATION')
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <>
                                <InputLabel id="source-name-input-label">{`${t('SOURCE_NAME')} *`}</InputLabel>
                                <TextboxInput
                                    error={!!errors.sourceName}
                                    type="text"
                                    placeholder={t('SOURCE_NAME')}
                                    fullWidth
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    value={value}
                                    inputRef={ref}
                                />
                            </>
                        )}
                    />
                    {errors.sourceName && <ErrorSpan role="alert">{errors.sourceName.message}</ErrorSpan>}
                </GridContainer>
                <GridContainer item xs={12} key="sourceTypeContainer">
                    <Controller
                        name="sourceType"
                        control={control}
                        rules={{
                            required: { value: true, message: t('REQUIRED_SOURCE_TYPE') }
                        }}
                        render={({ field: { onChange, value, name, onBlur } }) => (
                            <ComboSelect
                                onSelectChange={onChange}
                                onBlur={onBlur}
                                selectedValue={value}
                                name={`add-income-sources-${name}`}
                                label={`${t('SOURCE_TYPE')} *`}
                                placeholder={t('TEXT_SELECT')}
                                options={currentSourceTypeDropdownValues}
                            />
                        )}
                    />
                    {errors.sourceType && <ErrorSpan role="alert">{errors.sourceType.message}</ErrorSpan>}
                </GridContainer>
                <GridContainer item xs={12} key="sourceOwnerContainer">
                    <Controller
                        name="sourceOwner"
                        control={control}
                        rules={{
                            required: { value: true, message: 'Source owner is required' }
                        }}
                        render={({ field: { onChange, value, name, onBlur } }) => (
                            <RadioOption
                                onRadioChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                name={`add-income-sources-${name}`}
                                label={`${t('SOURCE_OWNER')} *`}
                                options={sourceOwnerOptions}
                            />
                        )}
                    />
                    {errors.sourceOwner && <ErrorSpan role="alert">{errors.sourceOwner.message}</ErrorSpan>}
                </GridContainer>
                <GridContainer item xs={7} key="amountContainer">
                    <Controller
                        name="amount"
                        control={control}
                        rules={{
                            required: { value: true, message: t('TEXT_THIS_IS_REQUIRED_FIELD') },
                            validate: {
                                weight: (value) => (value < 0 ? t('TEXT_AMOUNT_VALIDATION') : undefined)
                            }
                        }}
                        defaultValue={null}
                        render={({ field: { onChange, onBlur, value, ref, name } }) => (
                            <>
                                <InputLabel id="amount-input-label">{`${t('AMOUNT')} *`}</InputLabel>
                                <TextboxInput
                                    onBlur={(e: any) => {
                                        if (e.target.value) {
                                            convertToCommaFormat(e, name);
                                        }
                                        onBlur();
                                    }}
                                    onChange={(e) => {
                                        e.target.value = formatCurrencyDollar(e.target.value);
                                        onChange(e);
                                    }}
                                    value={value}
                                    name={`add-income-sources-${name}`}
                                    inputRef={ref}
                                    error={!!errors.amount}
                                    min={0}
                                    type="text"
                                    placeholder={t('AMOUNT')}
                                />
                            </>
                        )}
                    />
                    {errors.amount && <ErrorSpan role="alert">{errors.amount.message}</ErrorSpan>}
                </GridContainer>
                <GridContainer item xs={5} key="frequencyContainer">
                    <Controller
                        name="frequency"
                        control={control}
                        rules={{
                            required: { value: true, message: 'Frequency is required' }
                        }}
                        defaultValue={null}
                        render={({ field: { onChange, onBlur, value, name } }) => (
                            <ComboSelect
                                onSelectChange={onChange}
                                onBlur={onBlur}
                                selectedValue={value}
                                name={`add-income-sources-${name}`}
                                label={`${t('FREQUENCY')} *`}
                                placeholder="Select"
                                options={[
                                    { label: t('ANNUALLY'), value: 'Annually' },
                                    { label: t('MONTHLY'), value: 'Monthly' }
                                ]}
                            />
                        )}
                    />
                    {errors.frequency && <ErrorSpan role="alert">{errors.frequency.message}</ErrorSpan>}
                </GridContainer>
                <GridContainer item xs={12} key="beginningYearContainer">
                    <Controller
                        name="beginningYear"
                        control={control}
                        rules={{
                            required: { value: true, message: 'Beginning Year is required' },
                            validate: (value) => dayjs(value).isAfter(dayjs(), 'month')
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <>
                                <InputLabel id="beginning-year-input-label">{`${t('BEGINING_YEAR')} *`}</InputLabel>
                                <BasicDatePicker
                                    enabled
                                    type="single"
                                    isMmYyyy={true}
                                    startDate={currentDate.add(1, 'month').startOf('month').toString()}
                                    value={value ? dayjs(value) : null}
                                    onChange={(value: Dayjs) => onChange(value ? dayjs(value) : null)}
                                    onBlur={onBlur}
                                    data-testid="beginningYear"
                                    minErrorMsg={t('TEXT_VALID_DATE_ERROR')}
                                    customOpenIcon
                                />
                            </>
                        )}
                    />
                    {errors.beginningYear && <ErrorSpan role="alert">{errors.beginningYear.message}</ErrorSpan>}
                </GridContainer>
                <GridContainer item xs={12} key="endYearContainer">
                    <Controller
                        name="endYear"
                        control={control}
                        rules={{
                            required: { value: true, message: 'End Year is required' },
                            validate: (value) => dayjs(value).isAfter(dayjs(beginningYearValue), 'month')
                        }}
                        defaultValue={null}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <>
                                <InputLabel id="end-year-input-label">{`${t('END_YEAR')} *`}</InputLabel>
                                <BasicDatePicker
                                    enabled
                                    type="single"
                                    isMmYyyy={true}
                                    startDate={
                                        beginningYearValue
                                            ? beginningYearValue.add(1, 'month').startOf('month').toString()
                                            : currentDate.add(1, 'month').startOf('month').toString()
                                    }
                                    minErrorMsg={t('TEXT_VALID_DATE_ERROR')}
                                    value={value ? dayjs(value) : null}
                                    onChange={(value: Dayjs) => onChange(value ? dayjs(value) : null)}
                                    onBlur={onBlur}
                                    data-testid="endYear"
                                    customOpenIcon
                                />
                            </>
                        )}
                    />
                    {errors.endYear && <ErrorSpan role="alert">{errors.endYear.message}</ErrorSpan>}
                </GridContainer>
                <GridContainer item xs={12} key="escalateIncomeContainer">
                    <Controller
                        name="escalateIncome"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('ESCALATION_INCOME_REQUIRED')
                            },
                            max: {
                                value: 10,
                                message: t('TEXT_MAX_VALUE')
                            },
                            min: {
                                value: 0,
                                message: t('TEXT_MIN_VALUE')
                            }
                        }}
                        defaultValue={undefined}
                        render={({ field: { value, name, onBlur, onChange, ref } }) => (
                            <>
                                <InputLabel id="escalation-icome-input-label">{`${t(
                                    'ESCALATE_INCCOME'
                                )} *`}</InputLabel>
                                <TextboxInput
                                    onBlur={onBlur}
                                    onChange={(e) => onChange(parseInt(e.target.value))}
                                    value={value}
                                    name={`add-income-sources-${name}`}
                                    inputRef={ref}
                                    error={!!errors.escalateIncome}
                                    min={0}
                                    max={10}
                                    type="number"
                                    placeholder={t('ESCALATE_INCCOME')}
                                    icon={<Percent />}
                                    iconPlacement="end"
                                />
                            </>
                        )}
                    />
                    {errors.escalateIncome && <ErrorSpan role="alert">{errors.escalateIncome.message}</ErrorSpan>}
                </GridContainer>
            </Grid>

            <AddIncomeButtonContainer>
                <AddIncomeButton variant="outlined" color="primary" type="submit">
                    {t(mode === 'edit' ? 'SAVE_SOURCE' : 'ADD_ANOTHER_INCOME_SOURCE')}
                </AddIncomeButton>
            </AddIncomeButtonContainer>
        </StyledForm>
    );
};

import { CSSProperties, ReactNode, useId } from 'react';
import styled from '@emotion/styled';
import { PATPalette } from '../../../themes/palette';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info-blue.svg';
import { Typography } from '../../atoms';
import { Dropdown } from '../../atoms/Dropdown';
import { useDropdownState } from '../../../common/hooks/useDropdownState';
import { PopperPlacementType } from '@mui/material/Popper';

type Props = {
    width?: number;
    onOpen?(): void;
    placement?: PopperPlacementType;
    message?: ReactNode;
    t?: string;
    style?: CSSProperties;
};

/**
 *
 * @param message
 * @param onOpen a callback called every time the dropdown opens
 * @param placement the position of the modal
 * @returns
 */
export const InfoTooltip = ({
    onOpen,
    width,
    placement = 'right-start',
    message,
    style,
    t
}: Props) => {
    const uniqId = useId();

    const { toggle, ...dropdownProps } = useDropdownState({ onOpen });

    return (
        <Container
            style={style}
            id={uniqId}
            onMouseOver={toggle}
            onMouseLeave={() => {
                toggle();
            }}
        >
            <ButtonIcon onClick={toggle}>
                <InfoIcon />
            </ButtonIcon>

            <Dropdown placement={placement} {...dropdownProps}>
                <Content width={width}>
                    <Typography t={t} variant="labels.smallDemibold" color="secondary.skyBlue.400">
                        {message}
                    </Typography>
                </Content>
            </Dropdown>
        </Container>
    );
};

const Container = styled('div')(() => ({
    display: 'flex',
    width: ICON_SIZE + 6,
    height: ICON_SIZE
}));

const ButtonIcon = styled('div')(() => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: ICON_SIZE + 6,
    height: ICON_SIZE,
    '> svg': {
        zIndex: 1,
        position: 'absolute',
        width: ICON_SIZE,
        height: ICON_SIZE
    }
}));

const Content = styled('div')<{
    width?: number;
}>(({ width }) => ({
    width,
    display: 'flex',
    flexDirection: 'column',
    zIndex: 120,
    background: PATPalette.secondary.skyBlue[100],
    border: `1px solid ${PATPalette.primary.skyBlue}`,
    borderRadius: '4px',
    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.05) !important',
    padding: '20px',
    maxWidth: '312px'
}));

const ICON_SIZE = 15;

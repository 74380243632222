import { Icon } from '../../../../atoms/Icon/Icon';
import { GoalTypeEnum } from '../../../../../common/types';
import { RETIREMENT_CATEGORY_GOALS, WEALTH_CATEGORY_GOALS } from '../../../../../common/constants';
import styled from '@emotion/styled';

type Props = {
    type: GoalTypeEnum;
};

export const Goal = ({ type }: Props) => {
    const { backgroundColor, color, title, icon } = getGoal(type) || {};

    return (
        <Container>
            <IconContainer backgroundColor={backgroundColor} color={color}>
                <Icon id={icon!} fill="currentColor" />
            </IconContainer>
            <Text>{title}</Text>
        </Container>
    );
};

const Container = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center'
}));

const IconContainer = styled('div')<{ backgroundColor?: string; color?: string }>(
    ({ backgroundColor, color }) => ({
        display: 'flex',
        width: '32px',
        height: '32px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100px',
        backgroundColor,
        color,
        marginRight: '8px',

        '> svg': {
            width: '16px',
            height: '16px'
        }
    })
);

const Text = styled('span')(({ theme }) => ({
    ...theme.typography.body.overline,
    textTransform: 'uppercase',
    color: '#000'
}));

const getGoal = (type: GoalTypeEnum) => {
    const goalTypes = [...RETIREMENT_CATEGORY_GOALS, ...WEALTH_CATEGORY_GOALS];

    return goalTypes.find((goalType) => goalType.type === type);
};

import Typography, { TypographyProps, typographyClasses } from '@mui/material/Typography';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { fetchAdminsAndAdvisors, updateSelectedUser } from '../../features/adviceAdmin';
import { useTranslation } from 'react-i18next';
import FinancialAdvisorTable, {
    StyledTableContainer
} from '../../components/organisms/FinancialAdvisors/FinancialAdvisorTable';
import { CircularProgress, Link } from '@mui/material';
import { ADMIN_USER_ACCESS_KEYS } from '../../common/constants';
import { useNavigate } from 'react-router-dom';
import { updateSelectedFinancialAdvisorClients } from '../../features/adviceAdmin';

export const HeaderTypography = styled(Typography)<TypographyProps>(() => ({
    fontSize: '22px',
    marginRight: '20px',
    fontWeight: 600,
    marginBottom: '18px'
}));

const StyledTypography = styled(Typography)(() => ({
    [`&.${typographyClasses.root}`]: {
        fontSize: '22px',
        lineHeight: '16px',
        fontWeight: 600,
        color: '#000000',
        margin: '30px 0 20px 0'
    }
}));

export const AdminUserManagement = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const loading = useAppSelector((state) => state.advisorAdmin.loading);
    const admins = useAppSelector((state) => state.advisorAdmin.admins);
    const StyledLink = styled(Link)(() => ({
        textDecoration: 'none',
        cursor: 'pointer'
    }));

    useEffect(() => {
        dispatch(fetchAdminsAndAdvisors());
    }, []);

    const handleAdminAdvisorNavigate = async (admin: any) => {
        if (!isEmpty(admins)) {
            // TODO:: Fix the type for value below
            const payloadData = await admins.filter((value: any) => value.adminEmail === admin.adminEmail);
            dispatch(updateSelectedFinancialAdvisorClients(payloadData));
        }
        dispatch(updateSelectedUser(admin));
        navigate('/user');
    };

    const columns = [
        {
            accessorKey: ADMIN_USER_ACCESS_KEYS.ADMIN_NAME,
            id: ADMIN_USER_ACCESS_KEYS.ADMIN_NAME,
            header: t('ADMIN_SUMMARY_FIRST_INFO_TITLE'),
            cell: ({ row }: any) => (
                <StyledLink onClick={() => handleAdminAdvisorNavigate(row.original)}>
                    {`${row.original.adminName}`}
                </StyledLink>
            )
        },
        {
            accessorKey: ADMIN_USER_ACCESS_KEYS.ADMIN_EMAIL,
            id: ADMIN_USER_ACCESS_KEYS.ADMIN_EMAIL,
            header: t('ADMIN_SUMMARY_SECOND_INFO_TITLE'),
            cell: ({ getValue }: any) => getValue()
        },

        {
            accessorKey: ADMIN_USER_ACCESS_KEYS.REMOVE,
            id: 'remove',
            header: t('ADMIN_USER_REMOVE_BUTTON'),
            enableSorting: false,
            cell: () => <StyledLink>{t('ADMIN_USER_REMOVE_BUTTON')}</StyledLink>
        }
    ];

    const handleNewAdvisor = () => {
        navigate('/add-user', { state: { fromAdvisor: true } });
    };

    return (
        <>
            <StyledTypography variant="h2">{t('ADMIN_USER_TABLE_HEADER')}</StyledTypography>
            {loading ? (
                <StyledTableContainer
                    sx={{
                        minHeight: '440px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <CircularProgress />
                </StyledTableContainer>
            ) : (
                <FinancialAdvisorTable
                    data={admins}
                    columns={columns}
                    button={'Add New Admin'}
                    buttonOnClick={handleNewAdvisor}
                />
            )}
        </>
    );
};

import { useState } from 'react';

type Props = {
    onOpen?(): void;
};

export const useDropdownState = (props?: Props) => {
    const { onOpen } = props || {};

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isActive = !!anchorEl;

    const toggle = (event?: React.MouseEvent<HTMLElement>) => {
        if (!anchorEl) {
            onOpen?.();
        }

        if (!event) {
            return setAnchorEl(null);
        }

        setAnchorEl(event.currentTarget);
    };

    return {
        anchorEl,
        toggle,
        isActive,
        onClose: () => toggle(),
        setAnchorEl
    };
};

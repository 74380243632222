import styled from '@emotion/styled';
import { Logo } from '../../atoms/Logo/Logo';
import { NavUser } from './User';
import { NavLink } from './Link';
import { NavItem } from 'common/types/nav';
import { NavContact } from './Contact';
import { useState } from 'react';

const Background = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 100
}));

const Navbar = styled('nav')(() => ({
    background: '#3769FF',
    height: '60px',
    padding: '0 30px',
    display: 'flex',
    alignItems: 'center',
    '& > #logo': {
        marginRight: '85px'
    }
}));

const LinksContainer = styled('div')(() => ({
    display: 'flex',
    gap: '47px',
    alignItems: 'center',
    flex: 1,
    marginRight: '85px'
}));

type Props = {
    items: NavItem[];
    user: {
        loggedInUsername: string;
        userRole?: string;
        adminTitle?: string;
    };
    onClickAdmin(): void;
    onClickLogout(): void;
};

export const Nav = ({ items = [], user, onClickAdmin, onClickLogout }: Props) => {
    const [activeDropdown, setActiveDropdown] = useState<DropdownNames>();

    const onToggleDropdown = (name: DropdownNames) =>
        setActiveDropdown((oldName) => (oldName === name ? undefined : name));

    return (
        <>
            <Background>
                <Navbar>
                    <Logo />
                    <LinksContainer>
                        {items.map(({ label, ...item }, index) => (
                            <NavLink key={`${label}_${index}`} {...item}>
                                {label}
                            </NavLink>
                        ))}
                    </LinksContainer>

                    <NavUser
                        {...user}
                        isDropdownActive={activeDropdown === 'user'}
                        onToggleDropdown={() => onToggleDropdown('user')}
                        onClickAdmin={onClickAdmin}
                        onClickLogout={onClickLogout}
                    />

                    <NavContact
                        isDropdownActive={activeDropdown === 'contact'}
                        onToggleDropdown={() => onToggleDropdown('contact')}
                    />
                </Navbar>
            </Background>
        </>
    );
};

type DropdownNames = 'user' | 'contact';

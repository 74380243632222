import { Theme } from '@mui/material';

export default function Table(theme: Theme) {
    return {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    padding: `${theme.spacing(1)} ${theme.spacing(2)}`
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    verticalAlign: 'bottom'
                }
            }
        }
    };
}

import { useState } from 'react';

import styled from '@emotion/styled';
import { Box, Collapse, Avatar, Divider, Link, CollapseProps } from '@mui/material';
import { BoxProps } from '@mui/system';
import { ChevronRight, ChevronLeft } from '@mui/icons-material';

import { IconButton, Typography } from '../../atoms';
import { formatter, getInitials } from '../../../utils';
import { palette } from '../../../themes';
import { useTranslation } from 'react-i18next';

export type LeftPanelProps = {
    expanded?: boolean;
    data: {
        name: string;
        relationship: string | null;
        riskProfile: string;
        totalIncomeAvailable: number;
        totalRetirementAvailable: number;
        annualRetirementContributions: number;
        totalWealthAvailable: number;
        annualWealthContributions: number;
        goalAmount: number;
    };
    onEditIncomeClick?: () => void;
    onEditRiskProfile?: () => void;
    onEditInvestmentClick?: () => void;
};

const StyledLeftPanelContainer = styled(Box)<BoxProps & { expanded: boolean }>(({ expanded }) => ({
    width: expanded ? 200 : 72,
    background: '#F4F5F7',
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.05)',
    height: 'calc(100vh - 90px)',
    fontFamily: 'TT Commons Pro', // added for storybook component which can be removed later
    marginLeft: '-40px'
}));

const StyledHouseholdContainer = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    paddingBottom: '4px',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    gap: '4px',
    flexWrap: 'wrap'
}));

const StyledIncomeContainers = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
}));

const StyledCollapsedContainer = styled(Collapse)<CollapseProps & { in: boolean }>(({ in: open }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: open ? '100%' : 0,
    '& .MuiCollapse-wrapper': {
        height: '100%',
        width: '100%',
        '& .MuiCollapse-wrapperInner': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            width: '100%'
        }
    }
}));

const StyledExpandedContainer = styled(Collapse)<CollapseProps & { in: boolean }>(({ in: open }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: open ? '100%' : 0,
    '& .MuiCollapse-wrapper': {
        height: '100%',
        '& .MuiCollapse-wrapperInner': {
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 90px)',
            padding: '20px 24px'
        }
    }
}));

const StyledAvatar = styled(Avatar)(() => ({
    width: '32px',
    height: '32px',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '100%',
    color: palette.primary.main,
    backgroundColor: 'white'
}));

const StyledName = styled(Typography)(() => ({
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '18px',
    marginLeft: '8px'
}));

const StyledTitle = styled(Typography)(() => ({
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '18px',
    margin: 0
}));

// const StyledCalculatedRemaining = styled(Typography)<any>(({ calculatedRemaining }) => ({
//     fontSize: '13px',
//     fontWeight: 600,
//     lineHeight: '16px',
//     margin: 0,
//     color: calculatedRemaining > 0 ? palette.table.score[50] : palette.table.score[100]
// }));

const StyledLabel = styled(Typography)(() => ({
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    color: palette.noGoal.infoTextColor,
    margin: 0
}));

const StyledIconButtonText = styled(Typography)(() => ({
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '100%',
    color: palette.common.blue,
    margin: 0
}));

const StyledLink = styled(Link)(() => ({
    color: palette.common.blue,
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '18px',
    textDecorationLine: 'underline',
    cursor: 'pointer'
}));

const StyledAvatarContainer = styled(Box)(() => ({
    margin: '24px auto'
}));

const StyledIconButton = styled(IconButton)(() => ({
    width: '32px',
    height: '32px',
    color: palette.primary.main,
    marginBottom: '32px',
    marginTop: 'auto'
}));

const StyledSubheader = styled(Box)(() => ({
    display: 'flex',
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    gap: '4px',
    flexWrap: 'wrap',
    flexShrink: 1,
    marginTop: '20px'
}));

const StyledRiskProfile = styled(Box)(() => ({
    display: 'flex',
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    gap: '4px',
    flexWrap: 'wrap',
    flexShrink: 1,
    marginTop: 0
}));

const StyledFirstSection = styled(Box)(() => ({
    margin: '0 0 28px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
}));

export const LeftPanel = ({
    expanded = false,
    data,
    onEditIncomeClick,
    onEditRiskProfile,
    onEditInvestmentClick
}: LeftPanelProps) => {
    const { t } = useTranslation();
    const [expandedState, setExpandedState] = useState(expanded);
    // const calculatedRemaining = data?.goalAmount - data?.totalWealthAvailable;
    const handleClick = () => {
        setExpandedState(!expandedState);
    };

    // const renderTotalRetirementAvailable = () => {
    //     return (
    //         <StyledIncomeContainers>
    //             <StyledLabel label={t('TOTAL_RETIREMENT_AVAILABLE')} />
    //             <StyledTitle label={formatter.format(data?.totalRetirementAvailable)} />
    //         </StyledIncomeContainers>
    //     );
    // };
    // const renderAnnualRetirementContributions = () => {
    //     return (
    //         <StyledIncomeContainers>
    //             <StyledLabel label={t('ANNUAL_RETIREMENT_CONTRIBUTIONS')} />
    //             <StyledTitle label={formatter.format(data?.annualRetirementContributions)} />
    //         </StyledIncomeContainers>
    //     );
    // };

    return (
        <StyledLeftPanelContainer expanded={expandedState}>
            <StyledCollapsedContainer in={!expandedState} orientation="horizontal" timeout={1}>
                <StyledAvatarContainer>
                    <StyledAvatar>{getInitials(data?.name)}</StyledAvatar>
                </StyledAvatarContainer>
                <Divider sx={{ width: '100%' }} />
                <StyledIconButton iconName={<ChevronRight />} onClick={handleClick} />
            </StyledCollapsedContainer>
            <StyledExpandedContainer in={expandedState} orientation="horizontal" timeout={1}>
                <Box sx={{ display: 'flex', padding: '24px 0', marginBottom: '8px' }}>
                    <StyledAvatar>{getInitials(data?.name)}</StyledAvatar>
                    <StyledName label={data?.name} />
                </Box>
                <Divider sx={{ mx: '-24px' }} />
                <StyledFirstSection>
                    <StyledSubheader>
                        <StyledTitle sx={{ width: '68px' }} label={t('LABEL_PERSONAL_INFORMATION')} />
                        <StyledLink>{t('LABEL_EDIT')}</StyledLink>
                    </StyledSubheader>
                    <StyledHouseholdContainer>
                        <Box sx={{ width: '100%' }}>
                            <StyledLabel label={t('LABEL_HOUSEHOLD')} />
                        </Box>
                        {data?.relationship && (
                            <>
                                <StyledTitle sx={{ width: '88px' }} label={data?.relationship} />
                                <StyledLink>{t('LABEL_EDIT')}</StyledLink>
                            </>
                        )}
                    </StyledHouseholdContainer>
                    <Box>
                        <StyledLabel label={t('LABEL_RISK_PROFILE')} />
                        <StyledRiskProfile>
                            <StyledTitle label={data?.riskProfile} />
                            <StyledLink onClick={onEditRiskProfile}>{t('LABEL_EDIT')}</StyledLink>
                        </StyledRiskProfile>
                    </Box>
                </StyledFirstSection>
                <Divider sx={{ mx: '-24px' }} />
                <StyledFirstSection>
                    <StyledSubheader>
                        <StyledTitle label={t('LABEL_INCOME')} />
                        <StyledLink onClick={onEditIncomeClick}>{t('LABEL_EDIT')}</StyledLink>
                    </StyledSubheader>
                    <StyledIncomeContainers>
                        <StyledLabel label={t('LABEL_TOTAL_INCOME_AVAILABLE')} />
                        <StyledTitle label={formatter.format(data?.totalIncomeAvailable)} />
                    </StyledIncomeContainers>
                    <StyledSubheader>
                        <StyledTitle label={t('LABEL_WEALTH')} />
                        <StyledLink onClick={onEditInvestmentClick}>{t('LABEL_EDIT')}</StyledLink>
                    </StyledSubheader>
                    {/* TODO:: enable when Retirement is available  */}
                    {/* {false && renderTotalRetirementAvailable()} */}
                    {/* TODO:: enable when Retirement is available  */}
                    {/* {false && renderAnnualRetirementContributions()} */}
                    <StyledIncomeContainers>
                        <StyledLabel label={t('LABEL_TOTAL_WEALTH_AVAILABLE')} />
                        <StyledTitle label={formatter.format(data?.totalWealthAvailable)} />
                    </StyledIncomeContainers>
                    {/* TODO:: Not for Genesis👇 uncomment when needed */}
                    {/* {calculatedRemaining !== 0 && (
                        <Box>
                            <StyledCalculatedRemaining
                                calculatedRemaining={calculatedRemaining}
                                label={`${calculatedRemaining > 0 ? '+' : '-'} $${Math.abs(
                                    calculatedRemaining
                                )?.toLocaleString()} ${calculatedRemaining > 0 ? 'needed' : 'unused'}`}
                            />
                        </Box>
                    )} */}
                    <StyledIncomeContainers>
                        <StyledLabel label={t('LABEL_ANNUAL_WEALTH_CONTRIBUTIONS')} />
                        <StyledTitle label={formatter.format(data?.annualWealthContributions)} />
                    </StyledIncomeContainers>
                </StyledFirstSection>
                <Box sx={{ marginBottom: '12px', marginTop: 'auto' }}>
                    <IconButton
                        onClick={handleClick}
                        iconName={
                            <>
                                <ChevronLeft />
                                <StyledIconButtonText label={t('LABEL_COLLAPSE_CLIENT_DETAILS')} />
                            </>
                        }
                        sx={{ padding: 0, color: '#3769FF' }}
                    />
                </Box>
            </StyledExpandedContainer>
        </StyledLeftPanelContainer>
    );
};

import { Theme } from '@mui/material';

export default function Icon(theme: Theme) {
    return {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    marginRight: theme.spacing(0)
                }
            }
        }
    };
}

import { configureStore, combineReducers } from '@reduxjs/toolkit';
// import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import globalReducer from '../features/global/globalSlice';
import authReducer from '../features/auth/authSlice';
import advisorAdmin from '../features/adviceAdmin';
import adviceClient from '../features/client';
import adviceGoals from '../features/client-goals';
import advisorConsole from '../features/advisorConsole';

const persistConfig = {
    key: 'root',
    storage: storageSession
};

const reducers = combineReducers({
    global: globalReducer,
    auth: authReducer,
    advisorAdmin,
    adviceClient,
    adviceGoals,
    advisorConsole
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false
        })
});

export const persistor = persistStore(store);
export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { Theme } from '@mui/material';

export default function MenuList(theme: Theme) {
    return {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    lineHeight: 1,
                    margin: 0,
                    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
                    fontSize: theme.spacing(2),
                    fontWeight: `600 !important`,
                    minHeight: '42px !important'
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: 0
                }
            }
        }
    };
}

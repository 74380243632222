import styled from '@emotion/styled';
import { PATPalette } from '../../../../themes/palette';
import { Dropdown, DropdownProps } from '../../../atoms/Dropdown';
import { DropdownContent } from '../../MoreActionsMenu';

type Props = {
    options: OptionType<any>[];
    selectedOption?: OptionType['value'];
    // eslint-disable-next-line
    onSelectOption(option: OptionType<any>): void;
    filterText?: string;
    maxHeight?: number;
    width?: number;
} & DropdownProps;

export type OptionType<T = string | number> = {
    label: string;
    value: T;
};

export const AdminFilterDropdown = ({
    options = [],
    selectedOption,
    onSelectOption,
    filterText,
    maxHeight,
    width,
    ...dropdownProps
}: Props) => {
    return (
        <Dropdown {...dropdownProps}>
            <DropdownContent
                width={width}
                maxHeight={maxHeight}
                onClick={(e) => e.stopPropagation()}
            >
                {options.map((item, ix) => {
                    const { label, value } = item;
                    const matchFilterText =
                        !!filterText &&
                        label?.toLocaleLowerCase()?.startsWith(filterText?.toLowerCase());

                    const matchingText = label?.substring(0, filterText?.length);
                    const labelCut = label?.substring(filterText?.length!);

                    return (
                        <Option
                            key={`${value}_${ix}`}
                            isSelected={value === selectedOption}
                            onClick={() => onSelectOption(item)}
                        >
                            <span>
                                {matchFilterText ? (
                                    <>
                                        <strong>{matchingText}</strong>
                                        {labelCut}
                                    </>
                                ) : (
                                    label
                                )}
                            </span>
                        </Option>
                    );
                })}
            </DropdownContent>
        </Dropdown>
    );
};

const Option = styled('button')<{ isSelected?: boolean }>(({ theme, isSelected = false }) => ({
    ...theme.typography.labels.large,
    background: '#FFF',
    border: 'none',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 8px',
    cursor: 'pointer',
    color: '#000',
    ':hover': {
        background: PATPalette.neutral.grey[100]
    },
    ':first-child': {
        borderRadius: '6px 6px 0 0'
    },
    ':last-child': {
        borderRadius: '0 0 6px 6px'
    },
    ':only-child': {
        borderRadius: '6px'
    },
    ...(isSelected && {
        background: PATPalette.secondary.skyBlue[100]
    }),
    strong: {
        fontWeight: 700
    }
}));

import { styled } from '@mui/material';
import { Typography } from '../../components/atoms/Typography/Typography';
import { BasicButton } from '../../components/atoms';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoError } from '../../assets/icons/info-error.svg';

type Props = {
    onClickEdit(): void;
};

export const UnrealisticGoal = ({ onClickEdit }: Props) => {
    const { t } = useTranslation();

    return (
        <Container>
            <Typography
                variant="headers.h2"
                sx={{ display: 'flex', alignItems: 'center', gap: '4px', marginBottom: '10px' }}
            >
                <InfoError /> {t('UNREALISTIC_PLAN_TITLE')}
            </Typography>
            <Typography
                variant="body.large"
                t="UNREALISTIC_PLAN_DESCRIPTION"
                sx={{ maxWidth: '364px', textAlign: 'center', marginBottom: '25px' }}
            ></Typography>

            <BasicButton variant="contained" onClick={onClickEdit}>
                Edit goal details
            </BasicButton>
        </Container>
    );
};

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '600px'
});

import { uipApiInstance } from '../index';

export const goalcalculator = async (data: any, headers: any) => {
    try {
        const response = await uipApiInstance({
            method: 'POST',
            url: '/api/advice/goalcalculator',
            withCredentials: false,
            data,
            headers
        });
        return response?.data;
    } catch (err) {
        console.log(err);
    }
};

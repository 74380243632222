import { MouseEventHandler, PropsWithChildren, Ref, forwardRef } from 'react';
import styled from '@emotion/styled';
import { NavLink as RouterLink } from 'react-router-dom';

const styles = {
    fontFamily: 'TT Commons Pro Condensed',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0.02em',
    textDecoration: 'none',
    padding: 0,
    border: 0,
    color: '#DDEAFF',
    marginTop: '2px',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:hover': {
        color: '#fff'
    }
};

const Button = styled('button')(() => ({
    ...styles
}));

const ButtonLink = styled(RouterLink)(() => ({
    ...styles,
    '&.active': {
        borderBottom: '2px solid #fff',
        color: '#fff'
    }
}));

type Props = {
    onClick?: MouseEventHandler<HTMLButtonElement>;
    to?: string;
    style?: React.CSSProperties;
    id?: string;
};

const NavLink = forwardRef<HTMLAnchorElement | HTMLButtonElement, PropsWithChildren<Props>>(
    ({ onClick, children, to, ...restProps }, ref) => {
        if (to?.length) {
            return (
                <ButtonLink
                    ref={ref as Ref<HTMLAnchorElement>}
                    to={to}
                    className={({ isActive }) => (isActive ? 'active' : undefined)}
                    {...restProps}
                >
                    {children}
                </ButtonLink>
            );
        }

        return (
            <Button onClick={onClick} ref={ref as Ref<HTMLButtonElement>} {...restProps}>
                {children}
            </Button>
        );
    }
);

NavLink.displayName = 'NavLink';

export { NavLink };

import TargetImage from '../../../assets/icons/target_rounded.svg';
import { Typography } from '../../atoms';
import styled from '@emotion/styled';

type Props = {
    title: string;
    infoText?: string;
};

const Container = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBlock: '4rem'
}));

const TargetIcon = styled('img')(({}) => ({
    width: '80px',
    height: '80px',
    marginBottom: '17px'
}));

const TitleText = styled(Typography)(({}) => ({
    fontSize: '22px',
    lineHeight: '20px',
    fontWeight: 600,
    margin: '14px 0'
}));

const InfoText = styled(Typography)(({ theme }: any) => ({
    margin: '0',
    textAlign: 'center',
    lineHeight: '20px',
    color: theme.palette.noGoal.infoTextColor
}));

export const NoGoal = ({ title, infoText }: Props) => {
    return (
        <Container>
            <TargetIcon src={TargetImage} />
            <TitleText label={title} />
            {infoText && <InfoText label={infoText} />}
        </Container>
    );
};

/* eslint-disable operator-linebreak */
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { isEmpty, merge } from 'lodash';
import { ErrorInfo, memo, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Header } from './components/molecules/Header/Header';
import { useAppDispatch, useAppSelector } from './common/hooks';
import ErrorBoundaryComponent from './components/organisms/ErrorBoundary';
import ScrollToTop from './routes/scrollToTop';
import { breakpoints, palette, typography } from './themes';
// import ErrorPopup from './components/molecules/error-modal';
import AppRoutes from './routes';
// import EulaContainer from './containers/eula';
import { OKTA_DEVICE_TOKEN_KEY } from './common/constants';
import { Spinner } from './components/atoms';
import {
    getTenantBasedConfig,
    logEventToBackEnd,
    updateGlobalLoaderState,
    fetchPortfolio,
    fetchGoeConfig
} from './features/global/globalSlice';
import oktaAuth from './services/configs/oktaAuth';
import { Footer } from './components/molecules/Footer/Footer';
import { overrides } from './themes/overrides';
import { syncAdviceClient } from './features/client';
import { syncAdviceGoals } from './features/client-goals';
import { getCurrentYear } from './utils';
import Timeout from './components/molecules/Timeout';

const MainSectionContainer: any = styled('main')(({ isAuthenticated }: any) => {
    if (!isAuthenticated) {
        return {
            minHeight: '74vh'
        };
    }

    return {
        minHeight: '100vh',
        margin: '0',
        marginTop: '60px'
    };
});

const makeUseMemoRun = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

function App() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    // const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [updatedPalette, setUpdatedPalette] = useState(palette);
    const [isChanged, setIsChanged] = useState(makeUseMemoRun);
    const { i18n, t } = useTranslation();

    const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

    const { multiTenancyRefresh, globalLoader } = useAppSelector((state) => ({
        ...state.global
    }));

    const globalConfig = useAppSelector((state) => state.global.globalConfig);
    const adviceClient = useAppSelector((state) => state.adviceClient);
    const adviceGoals = useAppSelector((state) => state.adviceGoals);
    const portfolio = useAppSelector((state) => state.global.portfolio);
    const goeConfig = useAppSelector((state) => state.global.goeConfig);

    useLayoutEffect(() => {
        if (isEmpty(globalConfig?.styles)) {
            dispatch(updateGlobalLoaderState(true));
        }
    }, []);

    useEffect(() => {
        // Get market portfolios
        if (isEmpty(portfolio)) {
            dispatch(fetchPortfolio());
        }
    }, [isAuthenticated]);

    useEffect(() => {
        // Get Goe Config
        if (isEmpty(goeConfig)) {
            dispatch(fetchGoeConfig());
        }
    }, [isAuthenticated]);

    useEffect(() => {
        // Get tenant info for current DNS: document?.location?.hostname
        dispatch(getTenantBasedConfig({ tenantId: document?.location?.hostname, i18n }));

        // Check if device token is set and set a new one if its not set per domain
        const deviceToken = localStorage.getItem(OKTA_DEVICE_TOKEN_KEY);

        if (!deviceToken) {
            const uuid = uuidv4();

            localStorage.setItem(OKTA_DEVICE_TOKEN_KEY, uuid.substring(0, 32));
        }
    }, []);

    useEffect(() => {
        // Update subdomain tenant to adviceClient and adviceGoals in redux store so that
        // all clients & goals related api calls automatically use current tenant
        dispatch(syncAdviceClient({ ...adviceClient, tenant: globalConfig?.subdomain || 'default' }));
        dispatch(syncAdviceGoals({ ...adviceGoals, tenant: globalConfig?.subdomain || 'default' }));
    }, [globalConfig?.subdomain]);

    useEffect(() => {
        if (globalConfig?.styles) {
            const updated = merge(updatedPalette, globalConfig?.styles?.config);
            setUpdatedPalette(updated);
            setIsChanged(makeUseMemoRun);
        }
    }, [globalConfig?.styles?.config, multiTenancyRefresh]);

    const memoizedThemeValue = useMemo(
        () => ({
            palette: updatedPalette,
            typography,
            breakpoints,
            shape: { borderRadius: 2 }
        }),
        [isChanged, updatedPalette]
    );

    const theme = createTheme(memoizedThemeValue);
    theme.components = overrides(theme);

    // useEffect(() => {
    //     if (!isEmpty(error) && showErrorModal) {
    //         setShowErrorPopup(showErrorModal);
    //     } else {
    //         setShowErrorPopup(showErrorModal as any);
    //     }
    // }, [error, showErrorModal]);

    const customAuthHandler = () => {
        navigate('/');
    };

    const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
        navigate(toRelativeUrl(originalUri || '', window.location.origin), { replace: true });
    };

    return (
        <ThemeProvider theme={theme}>
            <ErrorBoundary
                FallbackComponent={ErrorBoundaryComponent}
                onError={(error: Error, info: ErrorInfo) => {
                    console.log(error, info);
                    dispatch(logEventToBackEnd('UI_CRASH'));
                }}
            >
                <Security
                    oktaAuth={oktaAuth}
                    onAuthRequired={customAuthHandler}
                    restoreOriginalUri={restoreOriginalUri}
                >
                    <Spinner enabled={globalLoader || adviceClient?.loading || adviceGoals?.loading} />
                    <Header />
                    <CssBaseline />
                    <MainSectionContainer id="main" isAuthenticated={isAuthenticated}>
                        <ScrollToTop />
                        <AppRoutes />
                        {/* <ErrorPopup open={showErrorPopup} loading={loading} /> */}
                        {/* <EulaContainer /> */}
                    </MainSectionContainer>
                    <Footer
                        primaryText={
                            <Trans
                                i18nkey="DESCRIPTION_FOOTER_TWO"
                                defaults={t('DESCRIPTION_FOOTER_TWO')}
                                values={{ YEAR: getCurrentYear() }}
                                components={{ bold: <strong /> }}
                            />
                        }
                        links={[
                            {
                                label: 'Terms of Use',
                                link: `${process.env.PUBLIC_URL}/assets/pdf/en/Digital_Advisor_Terms_of_Use.pdf`
                            },
                            {
                                label: 'Privacy Notice',
                                link: 'https://www.franklintempletonglobal.com/privacy'
                            }
                        ]}
                        eulaVariant={true} // to hide the detailed footer
                    />
                    {isAuthenticated && <Timeout />}
                    {/* <SnackBar /> */}
                </Security>
            </ErrorBoundary>
        </ThemeProvider>
    );
}

export default memo(App);

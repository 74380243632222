import { styled } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RETIREMENT_CATEGORY_GOALS, WEALTH_CATEGORY_GOALS } from '../../common/constants';
import { getHeaderIconFromType, getMMYYYYfromTimeStamp, groupGoalsByPriority } from '../../common/helper';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { GoalTypeProps } from '../../common/types/clients-entity';
import { ComboSelect, Typography } from '../../components/atoms';
import { NoGoal } from '../../components/molecules';
import { GoalSummaryChip } from '../../components/molecules/GoalSummaryChip/GoalSummaryChip';
import { GoalType } from '../../components/molecules/GoalType/GoalType';
import { WealthGoals } from '../../containers/wealth-goals-drawer';
import { RetirementGoals } from '../../containers/retirement-goals-drawer';
import { syncAdviceGoals } from '../../features/client-goals';

const GoalsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(4)
}));
const PageContainer = styled('div')(() => ({
    width: '100%'
}));
const DisplayRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row'
}));
const GoalsChipsSection = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    gap: theme.spacing(1),
    marginTop: theme.spacing(5)
}));
const FilterContainer = styled('div')(() => ({
    maxWidth: '20%',
    marginLeft: 'auto'
}));

export const CreateGoalsStep = () => {
    const { t } = useTranslation();
    const adviceGoals = useAppSelector((state) => state.adviceGoals);
    const { formFields } = adviceGoals;
    const [displayedFormFields, setDisplayedFormFields] = useState(formFields || []);
    const dispatch = useAppDispatch();
    const [showSelectedGoalTypeDrawer, setShowSelectedGoalTypeDrawer] = useState<GoalTypeProps | undefined>();
    const [showRetirementGoalDrawer, setShowRetirementGoalDrawer] = useState<GoalTypeProps | undefined>();
    const focusedType = useMemo(() => showSelectedGoalTypeDrawer?.type, [showSelectedGoalTypeDrawer]);
    const [currentGoalData, setCurrentGoalData] = useState<any>();
    const [yearsInRetirement, setYearsInRetirement] = useState('0');
    const sortFormFieldsByPriority = () => {
        const sorted = groupGoalsByPriority([...formFields], ({ data }: { data: any }) => data.goalPriority);
        setDisplayedFormFields(sorted);
    };
    const handleFilterChange = (value: string) => {
        switch (value) {
            case 'Priority':
                sortFormFieldsByPriority();
                break;
            case 'Last Modified':
                setDisplayedFormFields(formFields);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        window.history.replaceState(null, '', '/creategoals');
    }, []);

    const goalDeleted = (goalId: string) => {
        const newFormFields = displayedFormFields.filter((goal) => goal.data.goalId !== goalId);
        dispatch(
            syncAdviceGoals({
                ...adviceGoals,
                formFields: newFormFields,
                global: {
                    banner: {
                        visible: true,
                        type: 'attention',
                        isGoalSummary: true,
                        message: t('GOAL_DELETE_BANNER_ATTENTION_MESSAGE_SUMMARY')
                    }
                }
            })
        );
    };

    function calculateYearsBetweenDates(startDate: any, dateStr: string) {
        const [day, month, year] = dateStr.split('-').map(Number);
        const endDate = new Date(year, month - 1, day);
        const start = new Date(startDate);
        const end = new Date(endDate);
        let years = end.getFullYear() - start.getFullYear();
        let months = end.getMonth() - start.getMonth();
        if (months < 0) {
            years--;
            months += 12;
        }
        const fractionalYears = years + months / 12;
        // console.log("fractionalYears",fractionalYears)
        return fractionalYears.toFixed(1);
    }

    // console.log("currentGoalData",currentGoalData,Boolean(showSelectedGoalTypeDrawer))

    return (
        <>
            <PageContainer style={{ position: 'relative' }}>
                <DisplayRow>
                    <GoalsContainer>
                        <Typography
                            label={t('ADD_RETIREMENT_GOALS')}
                            variant="h4"
                            marginBottom={'1rem'}
                            marginLeft={'0.5rem'}
                        />
                        <DisplayRow>
                            {RETIREMENT_CATEGORY_GOALS.map((g: GoalTypeProps, key) => {
                                // Change retirement goals props at src/common/constants.ts
                                return (
                                    <GoalType
                                        type={g.type}
                                        key={key}
                                        size="large"
                                        onClickHandler={() => setShowRetirementGoalDrawer(g)}
                                    />
                                );
                            })}
                        </DisplayRow>
                    </GoalsContainer>{' '}
                    <GoalsContainer>
                        <Typography
                            label={t('ADD_WEALTH_GOALS')}
                            variant="h4"
                            marginBottom={'1rem'}
                            marginLeft={'0.5rem'}
                        />
                        <DisplayRow>
                            {WEALTH_CATEGORY_GOALS.map((g: GoalTypeProps, key) => {
                                // Change wealth goals props at src/common/constants.ts
                                return (
                                    <GoalType
                                        type={g.type}
                                        key={key}
                                        size="large"
                                        onClickHandler={() => setShowSelectedGoalTypeDrawer(g)}
                                        isFocused={focusedType}
                                        adviceGoals={adviceGoals}
                                    />
                                );
                            })}
                        </DisplayRow>
                    </GoalsContainer>
                </DisplayRow>
                <FilterContainer>
                    {displayedFormFields?.length > 0 && (
                        <ComboSelect
                            preText="Sort By:"
                            options={[
                                { label: 'Last Modified', value: 'Last Modified' },
                                { label: 'Priority', value: 'Priority' }
                            ]}
                            selectedValue="Last Modified"
                            onSelectChange={handleFilterChange}
                        />
                    )}
                </FilterContainer>
                {displayedFormFields?.length > 0 ? (
                    <GoalsChipsSection>
                        {displayedFormFields.map((goals: any) => {
                            return (
                                <GoalSummaryChip
                                    key={goals.goalId}
                                    headerIcon={
                                        <GoalType
                                            type={getHeaderIconFromType(goals?.data?.wealthGoalType) || 'buyahouse'}
                                        />
                                    }
                                    goalType={goals?.data?.wealthGoalType}
                                    goalId={goals?.data?.goalId}
                                    onGoalDelete={goalDeleted}
                                    onEdit={() => {
                                        let data = {};
                                        if (goals.data.goalType === 'WEALTH_GOAL') {
                                            data = {
                                                goalAmount:
                                                    goals?.data?.goalAmount || goals?.data?.targetedRetirementIncome,
                                                goalId: goals.data?.goalId,
                                                goalPriority: goals.data?.goalPriority,
                                                goalName: goals.data?.goalName,
                                                targetDate: goals?.data?.goalDate || goals?.data?.targetDate,
                                                title: goals?.data?.wealthGoalType
                                            };
                                        } else {
                                            const yearsInRetirement = calculateYearsBetweenDates(
                                                goals?.data?.planStartRetirement,
                                                goals?.data?.targetDate
                                            );
                                            setYearsInRetirement(yearsInRetirement);
                                            data = {
                                                goalAmount: goals?.data?.goalAmount,
                                                goalId: goals?.data?.goalId,
                                                goalPriority: goals?.data?.goalPriority,
                                                targetedRetirementIncome: goals?.data?.targetedRetirementIncome,
                                                goalName: goals?.data?.goalName,
                                                yearsInRetirement: yearsInRetirement,
                                                planStartRetirement: goals?.data?.planStartRetirement,
                                                frequency: 'Annually',
                                                title: goals?.data?.wealthGoalType
                                            };
                                        }
                                        setCurrentGoalData(data);
                                        goals.data.goalType === 'WEALTH_GOAL'
                                            ? setShowSelectedGoalTypeDrawer(goals.data)
                                            : setShowRetirementGoalDrawer(goals.data);
                                    }}
                                    goal_name={goals?.data?.goalName}
                                    priority={goals?.data?.goalPriority}
                                    goalAmount={goals?.data?.goalAmount || goals?.data?.targetedRetirementIncome}
                                    target_date_data={getMMYYYYfromTimeStamp(
                                        goals?.data?.goalDate || goals?.data?.targetDate
                                    )}
                                />
                            );
                        })}
                    </GoalsChipsSection>
                ) : (
                    <NoGoal title={t('NO_GOALS')} infoText={t('NO_GOALS_INFOTEXT')} />
                )}
            </PageContainer>
            {currentGoalData ? (
                <>
                    <RetirementGoals
                        selectedGoal={showRetirementGoalDrawer}
                        handleClose={() => setShowRetirementGoalDrawer(undefined)}
                        yearsInRetirement={parseInt(yearsInRetirement)}
                        open={Boolean(showRetirementGoalDrawer)}
                        goalData={currentGoalData}
                    />
                    <WealthGoals
                        selectedGoal={showSelectedGoalTypeDrawer}
                        handleClose={() => setShowSelectedGoalTypeDrawer(undefined)}
                        open={Boolean(showSelectedGoalTypeDrawer)}
                        goalData={currentGoalData}
                    />
                </>
            ) : (
                <>
                    <WealthGoals
                        selectedGoal={showSelectedGoalTypeDrawer}
                        handleClose={() => setShowSelectedGoalTypeDrawer(undefined)}
                        open={Boolean(showSelectedGoalTypeDrawer)}
                    />
                    <RetirementGoals
                        selectedGoal={showRetirementGoalDrawer}
                        handleClose={() => setShowRetirementGoalDrawer(undefined)}
                        open={Boolean(showRetirementGoalDrawer)}
                    />
                </>
            )}
        </>
    );
};

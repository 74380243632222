const white = '#FFFFFF';
const black = '#000000';
const onTrack = '#6DC09D';
const offTrack = '#FF4C57';
const atRisk = '#FFB884';
const blue = '#416AF4';
const lightBlue = '#0443FF';

const grey = {
    100: '#E6E6E6',
    200: '#BFBFBF',
    300: '#8C8C8C',
    400: '#595959',
    500: '#333333'
};

const neutral = {
    white: '#FFFFFF',
    black: '#000000',
    lightGrey: '#F4F4F4',
    grey
};

const skyBlue = {
    100: '#DDEAFF',
    200: '#91B9FF',
    300: '#5291FF',
    400: '#1446E1',
    500: '#DCE9FF'
};

const teal = {
    100: '#D3FBF7',
    200: '#72DBD5',
    300: '#00A096',
    400: '#00847D',
    500: '#CCE6E5'
};

const orange = {
    100: '#FFE0D2',
    200: '#FF9665',
    300: '#FF6035',
    400: '#BE3200'
};

const fuchsia = {
    100: '#FAE4FD',
    200: '#F0B4F9',
    300: '#CB6EEE',
    400: '#A525D0'
};

const berry = {
    100: '#FFDDE6',
    200: '#F589A2',
    300: '#F04F74',
    400: '#DC0546'
};

const purple = {
    100: '#E7DFFE',
    200: '#B39AF5',
    300: '#8760EF',
    400: '#4B00B6'
};

const primary = {
    skyBlue: '#3769FF',
    teal: '#00BFB3',
    purple: '#6730E3',
    orange: '#E24100',
    fuchsia: '#C042EA',
    berry: '#FF0F52'
};

/**
 *
 * PLEASE DO NOT add any custom colors here, this object should follow PAT UI Web Colors, use customPalette (line 148) instead!
 * https://www.figma.com/design/UpcV1oLi53lwSGM3iDdZWT/AE-Design-Library?node-id=6-141&node-type=FRAME&t=l44bL1kUsUMzq4Tt-0
 *
 */
export const PATPalette = {
    neutral,
    primary,
    secondary: {
        skyBlue,
        teal,
        orange,
        fuchsia,
        berry,
        purple,
        lightBlue,
        grey
    },
    semantic: {
        text: {
            primary: neutral.black,
            secondary: grey[500],
            tertiary: grey[400],
            quaternary: neutral.white
        },
        icon: {
            primary: neutral.black,
            secondary: grey[500],
            tertiary: grey[400],
            quaternary: neutral.white
        },
        stroke: {
            primary: grey[100],
            secondary: grey[200],
            tertiary: grey[300],
            quaternary: neutral.black
        },
        fill: {
            primary: neutral.white,
            secondary: neutral.lightGrey,
            tertiary: neutral.grey[100],
            quaternary: '#FAFAFA'
        }
    },
    interaction: {
        positive: teal[400],
        negative: berry[400],
        noChange: neutral.black,
        information: skyBlue[300],
        success: teal[300],
        error: berry[400],
        warning: orange[300],
        link: primary.skyBlue,
        default: neutral.black,
        hover: skyBlue[100],
        focused: skyBlue[300],
        pressed: skyBlue[400],
        disabled1: neutral.lightGrey,
        disabled2: grey[200],
        disabled3: grey[400]
    },
    assetAllocation: {
        allocation: orange[300],
        alternative: skyBlue[300],
        cash: teal[400],
        commodities: primary.fuchsia,
        equity: primary.teal,
        fixedIncome: primary.purple
    }
};

export const customPalette = {
    universalCard: {
        subTextFontColor: '#595959'
    },
    customGray: {
        85: '#D9D9D9',
        93: '#EDEDED'
    }
};

const palette = {
    common: {
        black,
        white,
        onTrack,
        offTrack,
        atRisk,
        blue
    },
    primary: {
        extraLight: '#DDEAFF',
        light: '#DDEAFF',
        main: '#3769FF',
        dark: '#1446E1',
        contrastText: white
    },
    secondary: {
        light: '#000000',
        main: '#000000',
        dark: '#000000',
        contrastText: white
    },
    success: {
        contrastText: white,
        dark: '#006600',
        main: '#006600',
        light: '#006600'
    },
    info: {
        contrastText: '#005ea5',
        dark: white,
        main: white,
        light: white
    },
    warning: {
        contrastText: white,
        dark: '#FF6035',
        main: '#7986cb',
        light: '#7986cb'
    },
    error: {
        light: '#e57373',
        main: '#CC0000',
        dark: '#d32f2f',
        contrastText: white,
        background: 'rgba(230,0,0,0.06)'
    },
    grey: {
        50: '#fafafa',
        100: '#f5f5f5',
        200: '#eeeeee',
        300: '#595959',
        400: '#bdbdbd',
        500: '#9e9e9e',
        600: '#767676',
        700: '#616161',
        800: '#424242',
        900: '#333333',
        A100: '#d5d5d5',
        A200: '#aaaaaa',
        A400: '#303030',
        A700: '#616161',
        A800: '#454545'
    },
    text: {
        primary: black,
        secondary: '#3769FF',
        disabled: '#9a9a9a',
        hint: '#aaaaaa',
        grey: '#726e70'
    },
    button: {
        grey: '#918c8b',
        green: '#5abc2a',
        blue: '#3769FF'
    },
    background: {
        paper: white,
        default: white,
        light: '#E1F3F8'
    },
    action: {
        active: 'rgba(0, 0, 0, 0.54)',
        hover: 'rgba(0, 0, 0, 0.08)',
        hoverOpacity: 0.08,
        selected: 'rgba(0, 0, 0, 0.14)'
        // disabled: '#767676',
        // disabledBackground: '#767676'
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    type: 'light',
    icon: '#005ea5',
    table: {
        header: '#ddeaff',
        row: {
            even: '#fafafa',
            odd: '#fefefe',
            hover: '#eff5ff'
        },
        score: {
            50: '#DC0546',
            75: '#FF9665',
            100: '#00847D'
        }
    },
    slider: {
        background: '#3769FF',
        rail: '#91B9FF',
        label: '#333333'
    },
    goal: {
        formFields: {
            background: '#DDEAFF',
            errorBackground: '#FFDDE6',
            errorLabel: '#FF0F52'
        },
        heading: {
            background: {
                content: '#698EFF',
                savingGoal: '#E1E9FF',
                clientInfo: '#B2C6FF',
                riskTolerance: '#C8D6FF'
            }
        },
        button: {
            disabled: '#E8E8E8'
        }
    },
    charts: {
        glidePath: {
            equity: '#B39AF5',
            fixedIncome: '#72DBD5',
            pie: {
                equity: '#9370E2',
                fixedIncome: '#00BFB3'
            },
            line: '#086bf7',
            label: {
                equity: '#9370E2',
                fixedIncome: '#00BFB3'
            }
        },
        portfolioComposition: {
            equity: '#9370E2',
            fixedIncome: '#00BFB3'
        },
        incomeOverTime: {
            retirement: '#E7EDFF',
            social: '#717FAE',
            pension: '#D9D9D9'
        }
    },
    wealthPath: {
        goodProb: '#00847D',
        moderateProb: '#FF9665',
        badProb: '#DC0546',
        yAxisPlotLine: '#000',
        tick: '#000',
        axisLabel: '#767676',
        title: '#000',
        crosshair: '#000',
        accumulationLine: '#9370E2',
        drawdownLine: '#C042EA',
        gridLine: '#fff'
    },
    recommendation: {
        amount: '#3769FF',
        amountBorderBottom: '#3769FF'
    },
    layout: {
        primary: '#3769FF'
    },
    noGoal: {
        infoTextColor: '#595959',
        iconBackgroundColor: '#ddeaff80'
    }
};

export default palette;

import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { hex2rgba } from '../../common/helper';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { updateClient } from '../../features/client';
import { BasicButton, ComboSelect } from '../../components/atoms';
import { DrawerComp } from '../../components/molecules';
import { ReactComponent as AccountCircle } from '../../assets/icons/account_circle.svg';
import { customPalette } from '../../themes/palette';

export const GridContainer: any = styled(Grid)(() => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    minHeight: '100vh'
}));

export const LeftContainer: any = styled(GridContainer)(({ theme }) => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    backgroundColor: theme.palette.layout.primary,
    alignItems: 'flex-start',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 10%'
}));

export const RightContainer: any = styled(GridContainer)(({ theme }) => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    background: `${theme.palette.layout.primary} 0.5`,
    backgroundColor: `${hex2rgba(theme.palette.layout.primary, 0.5)}`,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px'
}));

export const ErrorSpan = styled(Typography)(() => ({
    color: 'red',
    display: 'flex',
    marginTop: '5px'
}));

export const ItemContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& a': {
        color: theme.palette.background.paper
    }
}));

export const FormContainer: any = styled(GridContainer)(({ theme }) => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    backgroundColor: theme.palette.layout.white,
    padding: 30,
    borderRadius: 10,
    marginTop: '20px',
    maxWidth: '330px',
    minHeight: '75vh',
    border: '1px solid'
}));

export const Button = styled(BasicButton)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    minWidth: '35%',
    color: theme.palette.primary.main,
    textTransform: 'capitalize'
}));

export const WelcomText = styled(Typography)(({ theme }) => ({
    color: theme.palette.background.default
}));

const AddRelationshipDisclaimerContainer = styled('div')(() => ({
    marginTop: 'auto'
}));

const StyledDrawerBodyContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
}));

const StyledRequiredText = styled(Typography)(() => ({
    variant: 'body1',
    fontStyle: 'italic',
    color: customPalette.universalCard.subTextFontColor
}));
interface FormInput {
    riskProfile: string;
}

interface EditRiskProfileDrawerProps {
    openDrawer: boolean;
    setOpenDrawer: any;
    onEditSuccess?: () => void;
}

export const RiskProfileDrawer = (props: EditRiskProfileDrawerProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { openDrawer, setOpenDrawer, onEditSuccess } = props;
    const adviceClient = useAppSelector((state) => state.adviceClient);
    const { loading, error } = adviceClient;
    const { orgInfo } = useAppSelector((state) => state.global);
    const options = orgInfo?.riskProfileAex?.en;
    const formattedOptions = options?.map((opt: any) => ({ value: opt?.key, label: opt?.label }));

    const {
        control,
        formState: { errors, isValid, isDirty },
        handleSubmit
    } = useForm<FormInput>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            riskProfile: adviceClient?.riskProfile
        }
    });

    const onSubmit = async (values: FormInput) => {
        const updatedAdviceClient = {
            ...adviceClient,
            riskProfile: values.riskProfile
        };
        await dispatch(updateClient(updatedAdviceClient));
        setOpenDrawer(false);
        onEditSuccess && onEditSuccess();
    };

    const dataContainer = (
        <>
            <Grid paddingTop={0}>
                <StyledRequiredText>{t('TEXT_ALL_REQUIRED_FIELDS')}</StyledRequiredText>
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ItemContainer sx={{ alignItems: 'flex-start', width: '100%' }}>
                    <Typography variant="body1">Set Risk Tolerance *</Typography>
                    <Controller
                        name="riskProfile"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: {
                                value: true,
                                message: t('TEXT_THIS_IS_REQUIRED_FIELD_FULLNAME')
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, name } }) => (
                            <ComboSelect
                                options={formattedOptions}
                                onSelectChange={onChange}
                                onBlur={onBlur}
                                selectedValue={value}
                                name={name}
                            />
                        )}
                    />
                    {errors.riskProfile ? (
                        <ErrorSpan variant="subtitle2" role="alert">
                            {errors.riskProfile.message}
                        </ErrorSpan>
                    ) : null}
                </ItemContainer>
            </form>
        </>
    );
    return (
        <>
            {openDrawer && (
                <DrawerComp
                    onSubmit={handleSubmit(onSubmit)}
                    loading={loading}
                    error={!!errors.riskProfile || error}
                    header={t('CLIENT_PROFILE_RISK_TOLERANCE_HELPER')}
                    drawerIcon={<AccountCircle />}
                    open={openDrawer}
                    handleClose={() => {
                        setOpenDrawer(false);
                    }}
                    disableSubmit={!isValid || !isDirty}
                >
                    <StyledDrawerBodyContainer>
                        {dataContainer}{' '}
                        <AddRelationshipDisclaimerContainer>
                            <Typography fontSize={10}>{t('DRAWER_ADD_INCOME_SOURCE_DESCRIPTION')}</Typography>
                        </AddRelationshipDisclaimerContainer>
                    </StyledDrawerBodyContainer>
                </DrawerComp>
            )}
        </>
    );
};

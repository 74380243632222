import React from 'react';
import { Typography, styled, useTheme } from '@mui/material';

interface ListItem {
    label: string;
    value: string | number;
}

export interface ListProps {
    title?: string;
    data: ListItem[];
}

const VerticalLine = styled('div')({
    width: '100%',
    border: ' 1px solid black',
    marginTop: '15px',
    marginBottom: '20px'
});

const Row = styled('div')({
    display: 'flex',
    alignItems: 'center'
});

const GreyKey = styled(Typography)(() => ({
    fontSize: '16px',
    color: '#00000080',
    minWidth: '107px',
    fontWeight: '500'
}));

const StyledValue = styled(Typography)(() => ({
    fontWeight: '600',
    whiteSpace: 'break-spaces',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '62%',
    fontSize: 16
}));

const GridContainer = styled('div')(() => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
    minWidth: '100%'
}));

const List: React.FC<ListProps> = ({ title, data = [] }) => {
    const theme = useTheme();
    return (
        <div>
            {title && (
                <Typography variant="h5" gutterBottom>
                    {title}
                    <VerticalLine />
                </Typography>
            )}
            <GridContainer>
                {data.map((item, key) => (
                    <Row key={key} sx={{ alignItems: 'flex-start' }}>
                        <GreyKey theme={theme}>{item.label}</GreyKey>
                        <StyledValue>{item.value}</StyledValue>
                    </Row>
                ))}
            </GridContainer>
        </div>
    );
};
export default List;

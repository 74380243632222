import { PropsWithChildren } from 'react';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import ClickAwayListener from '@mui/material/ClickAwayListener';

export type DropdownProps = {
    onClose(): void;
    anchorEl?: null | HTMLElement;
    placement?: PopperPlacementType;
    disableClickAway?: boolean;
    disablePortal?: boolean;
};

type Props = PropsWithChildren<DropdownProps>;

export const Dropdown = ({
    onClose,
    children,
    anchorEl,
    placement = 'bottom-end',
    disableClickAway = false,
    disablePortal = false
}: Props) => {
    if (!anchorEl) {
        return null;
    }

    const ClickAwayComponent = disableClickAway ? 'div' : ClickAwayListener;

    return (
        <Popper
            open={!!anchorEl}
            anchorEl={anchorEl}
            role={undefined}
            placement={placement}
            transition
            disablePortal={disablePortal}
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
            {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                    <div style={{ padding: 6 }}>
                        <ClickAwayComponent onClickAway={() => onClose()}>
                            <div>{children}</div>
                        </ClickAwayComponent>
                    </div>
                </Grow>
            )}
        </Popper>
    );
};

import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet, useNavigate, Navigate } from 'react-router-dom';

import { Spinner } from '../components/atoms';
import { useAppSelector } from '../common/hooks';

const allowedPathsForAdvisor = ['/clients', '/createclient', '/creategoals', '/reviewplan'];

export const RequiredAuth: React.FC = () => {
    const okta = useOktaAuth();
    const navigate = useNavigate();
    const isAuthenticated = okta?.authState?.isAuthenticated;
    const user = useAppSelector((state) => state.auth.user);

    useEffect(() => {
        if (okta?.authState && !isAuthenticated) {
            const originalUri = toRelativeUrl(window.location.origin, window.location.href);
            okta?.oktaAuth.setOriginalUri(originalUri);
            navigate(originalUri);
        }
    }, [okta?.authState, isAuthenticated]);

    if (!isAuthenticated) {
        return <Spinner enabled />;
    }

    if (
        user.userRole !== 'admin' &&
        user.userRole !== 'ftadmin' &&
        !allowedPathsForAdvisor.includes(window.location.pathname)
    ) {
        return <Navigate to="/clients" />;
    }

    return <Outlet />;
};

import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../common/hooks';
import { resetAdviceAdminReducer } from '../../../features/adviceAdmin';
import { advisorConsoleActions } from '../../../features/advisorConsole';
import { toggleSessionExpiredModal } from '../../../features/auth/authSlice';
import { initializeAdviceClient } from '../../../features/client';
import { initializeGoals } from '../../../features/client-goals';
import { AlertDialog } from '../AlertDialog/AlertDialog';

const countDownSecondsConst = 115;
const warningTimeConst = 900 - countDownSecondsConst;
const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

function Timeout() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [warningTime, setWarningTime] = useState(warningTimeConst);
    const [popupOpen, setPopupOpen] = useState(false);
    const [countdownTime, setCountdownTime] = useState(countDownSecondsConst);
    const { oktaAuth, authState } = useOktaAuth();
    const isAuthenticated = authState?.isAuthenticated;

    useEffect(() => {
        events.forEach((event) => {
            window.addEventListener(event, () => {
                setWarningTime(warningTimeConst);
            });
        });
        return () => {
            events.forEach((event) => {
                window.removeEventListener(event, () => {
                    setWarningTime(warningTimeConst);
                });
            });
        };
    }, [events, warningTimeConst]);

    useEffect(() => {
        if (!isAuthenticated || popupOpen) return;
        const counter = setInterval(() => setWarningTime(warningTime - 1), 1000);

        if (!popupOpen && warningTime === 0) {
            setPopupOpen(true);
            setCountdownTime(countDownSecondsConst);
        }
        return () => {
            clearInterval(counter);
        };
    }, [warningTime, popupOpen, isAuthenticated]);

    useEffect(() => {
        if (!isAuthenticated || !popupOpen) return;
        const countdownCounter = window.setInterval(() => setCountdownTime(countdownTime - 1), 1000);

        if (countdownTime === 0) {
            dispatch(toggleSessionExpiredModal(true));
            signout();
        }
        return () => {
            clearInterval(countdownCounter);
        };
    }, [popupOpen, countdownTime, isAuthenticated]);

    // Reset all reducer to their initial states
    const clearAllStateData = async () => {
        dispatch(advisorConsoleActions.resetAdviceConsoleReducer());
        dispatch(initializeGoals());
        dispatch(resetAdviceAdminReducer());
        dispatch(initializeAdviceClient());
    };

    const signout = async () => {
        handleDismiss();
        oktaAuth.tokenManager.clear();
        clearAllStateData();
        navigate('/');
    };

    const handleDismiss = () => {
        setPopupOpen(false);
        setWarningTime(warningTimeConst);
        setCountdownTime(countDownSecondsConst);
    };

    if (!isAuthenticated) return null;

    return (
        <AlertDialog
            type="confirmation"
            open={popupOpen}
            handleClose={handleDismiss}
            content={`${t('TIMEOUT_MODAL_CONTENT', { remainingSeconds: countdownTime })}`}
            title={t('TIMEOUT_MODAL_TITLE')}
            aria-label="stay-signed-in-session-expired-modal"
            data-testid="session-expired-modal"
            cancelButtonLabel={t('TIMEOUT_MODAL_SIGNOUT_BUTTON')}
            confirmButtonLabel={t('TIMEOUT_MODAL_STAY_SIGNIN_BUTTON')}
            onCancellation={signout}
            onConfirmation={handleDismiss}
        />
    );
}

export default Timeout;

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Grid, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { Typography } from '../../components/atoms';
import palette, { PATPalette } from '../../themes/palette';

const StyledContainer = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: '400px',
    border: `1px solid ${PATPalette.neutral.grey[100]}`,
    borderRadius: theme.spacing(2),
    filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.05))',
    padding: '24px 16px',
    display: 'flex',
    flexDirection: 'column'
}));

const StyledHeaderContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[500]}`
}));

const StyledRightControls = styled('div')(({ theme }) => ({
    padding: `0 ${theme.spacing(0.5)}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const StyledNumberBadge = styled('div')(({ theme }) => ({
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    height: theme.spacing(3),
    width: theme.spacing(3),
    borderRadius: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(0.5)
}));

const StyledNumberBadgeOpened = styled('div')(({ theme }) => ({
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    borderRadius: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(0.5)
}));

const StyledChevron = styled('div')(() => ({
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const StyledBodyContainer = styled('div')(({ theme }) => ({
    borderBottomLeftRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(0.5)}`
}));

const StyledAccountContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 600,
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:last-child': {
        marginBottom: 0
    }
}));

const StyledAccountName = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
}));

const StyledLabel = styled(Typography)(() => ({
    fontWeight: 600,
    fontSize: '16px',
    variant: 'body1',
    margin: 0
}));

const StyledGridIcon = styled(Grid)(() => ({
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    padding: '4px',
    borderRadius: '50%',
    background: palette.primary.extraLight,
    aspectRatio: 1
}));

const StyledRelationshipLabel = styled(Typography)(() => ({
    variant: 'h5',
    fontWeight: 600,
    color: 'inherit'
}));

const StyledLabelAccounts = styled(Typography)(() => ({
    fontSize: '12px',
    color: 'inherit'
}));

export interface RelationshipProps {
    age?: string;
    firstName: string;
    relationship: string;
    isSelected: boolean;
    // eslint-disable-next-line no-unused-vars
    onClick?: (name: string) => void;
    disabled?: boolean;
    readOnly?: boolean;
}

export interface RelationshipsListProps {
    relationshipsData: Record<string, RelationshipProps>;
    // eslint-disable-next-line no-unused-vars
    onAccountChange?: (data: Record<string, RelationshipProps>) => void;
    title: string;
    disabled?: boolean;
    readOnly?: boolean;
    icon?: React.ReactNode;
}

const Relationship = ({
    firstName,
    relationship,
    isSelected,
    onClick,
    disabled,
    readOnly = false
}: RelationshipProps) => {
    return (
        <StyledAccountContainer onClick={() => !disabled && !readOnly && onClick?.(firstName)}>
            <StyledAccountName>
                {!readOnly &&
                    (isSelected ? (
                        <CheckCircleIcon color={disabled ? 'disabled' : 'primary'} />
                    ) : (
                        <CheckCircleOutlineIcon color="primary" />
                    ))}
                <StyledLabel label={firstName} color={disabled ? "neutral.grey.500" : 'inherit'} />
            </StyledAccountName>
            <StyledLabel label={relationship} color={disabled ? "neutral.grey.500" : 'inherit'} />
        </StyledAccountContainer>
    );
};

export const RelationshipsList = ({
    onAccountChange,
    relationshipsData,
    title,
    disabled,
    readOnly = false,
    icon
}: RelationshipsListProps) => {
    const [accounts, setAccounts] = useState({ ...relationshipsData });
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const onAccountSelect = (firstName: string) => {
        const copyOfAccounts = { ...accounts };
        const selectedAccount = copyOfAccounts[firstName];
        if (selectedAccount) {
            selectedAccount.isSelected = !selectedAccount.isSelected;
            setAccounts(copyOfAccounts);
            onAccountChange?.(copyOfAccounts);
        }
    };

    useEffect(() => {
        setAccounts(relationshipsData);
    }, [relationshipsData]);

    return (
        <StyledContainer>
            <StyledHeaderContainer>
                <Grid container alignItems="center" gap="12px">
                    {icon && (
                        <StyledGridIcon container item>
                            {icon}
                        </StyledGridIcon>
                    )}
                    <Typography label={title} variant="h5" />
                </Grid>
                <StyledRightControls>
                    <StyledChevron onClick={() => setDropdownOpen(!isDropdownOpen)}>
                        <ChevronRightIcon color="primary" />
                    </StyledChevron>
                </StyledRightControls>
            </StyledHeaderContainer>
            {isDropdownOpen ? (
                <>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <StyledRelationshipLabel label="Relationships" />
                        <StyledNumberBadgeOpened>
                            <StyledLabelAccounts label={Object.keys(accounts)?.length} variant="body2" />
                        </StyledNumberBadgeOpened>
                    </Grid>
                    <StyledBodyContainer>
                        {Object.values(accounts)?.map((source) => (
                            <Relationship
                                key={source.firstName}
                                firstName={source.firstName}
                                relationship={source.relationship}
                                isSelected={source.isSelected}
                                onClick={(firstName) => {
                                    onAccountSelect(firstName);
                                }}
                                disabled={disabled}
                                readOnly={readOnly}
                            />
                        ))}
                    </StyledBodyContainer>
                </>
            ) : (
                <Grid container justifyContent="space-between" alignItems="center">
                    <StyledRelationshipLabel label="Relationships" />
                    <StyledNumberBadge>
                        <StyledLabelAccounts label={Object.keys(accounts)?.length} variant="body1" />
                    </StyledNumberBadge>
                </Grid>
            )}
        </StyledContainer>
    );
};

import { Grid } from '@mui/material';
import { AssetType, DoughnutChartDataType } from 'common/types';
import { useEffect, useState } from 'react';
import { PORTFOLIO_COMPOSITION_COLOR } from '../../../common/constants';
import DoughnutChart from './DoughnutChart';
import PortfolioCompositionList from './PortfolioCompositionList';
// import { syncAdviceGoals } from '../../../features/client-goals';

const { allPortfoliosBaseColors, equityColor, fixedIncomeColor, alternativesColor } = PORTFOLIO_COMPOSITION_COLOR;

const PortfolioCompositionContainer = ({
    recommendedPortfolioId,
    allMarketPortfolios
}: {
    recommendedPortfolioId: number;
    allMarketPortfolios: Array<any>;
}) => {
    const portfolio = allMarketPortfolios;
    const [doughnutChartData, setDoughnutChartData] = useState<DoughnutChartDataType[]>([]);
    // const adviceGoals = useAppSelector((state) => state.adviceGoals);
    // const dispatch = useAppDispatch();

    const getSelectedPortfolioData = (recommendedPortfolioId: number) => {
        let _selectedPortfolio = portfolio?.filter((_sPort) => _sPort.portfolioId === recommendedPortfolioId);
        return _selectedPortfolio[0];
    };

    useEffect(() => {
        getSelectedPortfolioData(recommendedPortfolioId);
    }, [recommendedPortfolioId]);

    // Function to prepare data for Doughnut Chart
    const getCurrentPortfolioComposition = (type: AssetType, colorArr: string[]): DoughnutChartDataType => {
        const output: any = getSelectedPortfolioData(recommendedPortfolioId)
            .assets?.filter((item: any) => type === item.type)
            ?.map((_port: any) => {
                let arrayForSort = [..._port?.subAssets];
                const currentPortfolioComposition = arrayForSort
                    ?.sort((a: any, b: any) => b.allocatedValue - a.allocatedValue)
                    ?.map((item: any, _index: number) => ({
                        name: item.tickerName,
                        y: item.allocatedValue,
                        color: colorArr[_index],
                        subAssetClass: item.subAssetClass,
                        ticker: item.ticker,
                        tickerLink: item.tickerLink
                    }));
                return currentPortfolioComposition;
            });
        return output;
    };

    // Function to prepare data for PortfolioCompositionList
    let portfolioData = getSelectedPortfolioData(recommendedPortfolioId).assets?.map(
        (_port: any, portIndex: number) => {
            const groupBy = (key: any) => (array: any) =>
                // @ts-ignore
                array.reduce((objectsByKeyValue, obj) => {
                    const value = obj[key];
                    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
                    return objectsByKeyValue;
                }, {});

            const groupBySubAssetClass = groupBy('subAssetClass');
            const PortfolioGroupedBySubAssetClass = groupBySubAssetClass(_port.subAssets);

            const calculateTotalAllocationValue = (data: any) => {
                // @ts-ignore
                const total = data.reduce((init, { allocatedValue }) => init + allocatedValue, 0);
                return total.toFixed(2);
            };

            const calculateColor = (_gpIndex: number) => {
                if (portIndex === 0) {
                    return equityColor[_gpIndex];
                } else if (portIndex === 1) {
                    return fixedIncomeColor[_gpIndex];
                } else if (portIndex === 2) {
                    return alternativesColor[_gpIndex];
                }
            };

            const finalPortfolioData = Object.entries(PortfolioGroupedBySubAssetClass)
                .map(([name, data]) => ({
                    name,
                    data,
                    totalAllocatedvalue: calculateTotalAllocationValue(data)
                }))
                .sort((a: any, b: any) => b.totalAllocatedvalue - a.totalAllocatedvalue)
                .map((_item, _indexColor) => ({ ..._item, color: calculateColor(_indexColor) }));
            return finalPortfolioData;
        }
    );

    // Grouped Portfolio Composition + add Color to the same
    const groupedPortfolio = getSelectedPortfolioData(recommendedPortfolioId).assets?.map(
        (_type: any, _index: number) => ({
            type: _type.type,
            totalAllocatedvalue: `${_type.totalAllocatedvalue}%`,
            color: allPortfoliosBaseColors[_index],
            groupedSubAssets: portfolioData[_index]
        })
    );

    useEffect(() => {
        let allPortfolioData = [
            getCurrentPortfolioComposition('Equity', equityColor),
            getCurrentPortfolioComposition('Fixed Income', fixedIncomeColor),
            getCurrentPortfolioComposition('Alternatives', alternativesColor)
        ].flat(Infinity);
        const chartData = [...allPortfolioData];
        setDoughnutChartData(chartData);
    }, [recommendedPortfolioId]);

    return (
        <Grid container item direction="row" xs={12} spacing={2} mt={1} mb={3}>
            <Grid item xs={4}>
                <DoughnutChart doughnutChartData={doughnutChartData} groupedPortfolio={groupedPortfolio} />
            </Grid>
            <Grid item xs={8}>
                <PortfolioCompositionList portfolioListData={groupedPortfolio} />
            </Grid>
        </Grid>
    );
};

export default PortfolioCompositionContainer;

import { styled, useTheme } from '@mui/material';
import { Typography } from '../Typography/Typography';

type ChipMessageType = {
    message: string;
    boldInfo?: string;
};

const StyledChipMessageContainer = styled('div')(({ theme }) => ({
    borderRadius: '6px',
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.light,
    display: 'flex',
    alignItems: 'center',
    padding: '0.6rem'
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: '13px',
    lineHeight: '18px',
    fontWeight: '500',
    padding: 0,
    margin: 0,
    paddingLeft: '5px'
}));

const StyledBoldTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: '13px',
    lineHeight: '18px',
    fontWeight: '600',
    padding: 0,
    margin: 0,
    paddingLeft: '3px'
}));

export const ChipMessage = ({ message, boldInfo }: ChipMessageType) => {
    const theme = useTheme();
    return (
        <StyledChipMessageContainer theme={theme}>
            <StyledTypography label={message} />
            <StyledBoldTypography label={boldInfo} />
        </StyledChipMessageContainer>
    );
};

export default ChipMessage;

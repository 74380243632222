import { InputAdornment } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useState } from 'react';
import {
    formatCurrency,
    isAlphaNumSpacesValid,
    isAlphaNumValid,
    isAlphaOnlyValid,
    isAlphaSpacesOnlyValid,
    isEmailValid,
    isNumberInRange,
    isPhoneNumberValid
} from '../../../common/helper';

export interface TextBoxProps {
    type?:
        | 'text'
        | 'password'
        | 'number'
        | 'email'
        | 'alphanumeric'
        | 'alphanumericspaces'
        | 'phonenumber'
        | 'alphaonly'
        | 'currency'
        | 'alphaspacesonly';
    icon?: JSX.Element | string;
    iconPlacement?: 'start' | 'end';
    min?: number;
    max?: number;
    step?: number;
}

export const TextboxInput = (props: TextBoxProps & TextFieldProps) => {
    const { error, onChange, type, icon, iconPlacement, hidden, min, max, step = 1 } = props;
    const [hasError, setHasError] = useState(false);
    const prepareInputProps = () => {
        let propset = {};
        if (iconPlacement === 'start')
            propset = {
                ...propset,
                startAdornment: <InputAdornment position="start">{icon}</InputAdornment>
            };
        if (iconPlacement === 'end')
            propset = {
                ...propset,
                endAdornment: <InputAdornment position="end">{icon}</InputAdornment>
            };
        if (type === 'number')
            propset = {
                ...propset,
                slotProps: {
                    input: {
                        step: step
                    }
                },
                inputProps: {
                    min,
                    max
                }
            };
        return propset;
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Validations
        switch (type) {
            case 'number':
                if (min && max) {
                    setHasError(!isNumberInRange(Number(e.target.value), min, max));
                }
                break;
            case 'email':
                setHasError(!isEmailValid(e.target.value));
                break;
            case 'alphanumeric':
                setHasError(!isAlphaNumValid(e.target.value));
                break;
            case 'alphanumericspaces':
                setHasError(!isAlphaNumSpacesValid(e.target.value));
                break;
            case 'alphaonly':
                setHasError(!isAlphaOnlyValid(e.target.value));
                break;
            case 'alphaspacesonly':
                setHasError(!isAlphaSpacesOnlyValid(e.target.value));
                break;
            case 'phonenumber':
                setHasError(!isPhoneNumberValid(e.target.value));
                break;
            case 'currency':
                setHasError(!formatCurrency(e.target.value));
                break;
            default:
                setHasError(false);
        }
        if (onChange) onChange(e);
    };
    if (hidden) return <></>;
    return (
        <TextField
            error={error || hasError}
            onChange={handleChange}
            InputProps={prepareInputProps()}
            {...props}
        />
    );
};

import { uipApiInstance } from '../index';

export const fetchClients = async (data: { tenant: string }) => {
    try {
        const response = await uipApiInstance.get('/api/advice/getproposals?tenant=' + data.tenant);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

import { ReactComponent as AttentionIcon } from '../../../assets/icons/attention.svg';
import { Typography } from '../../atoms';
import { InfoTooltip } from '../InfoTooltip';
import { useTranslation } from 'react-i18next';
import { GoalPriorityEnum } from '../../../common/types';
import styled from '@emotion/styled';

type Props = {
    color: string;
    isOnTarget: boolean;
    goalPriority: GoalPriorityEnum;
    thresholdValue: number;
};

export const ProbabilityOfSuccessLegend = ({
    color,
    isOnTarget,
    goalPriority,
    thresholdValue
}: Props) => {
    const { t } = useTranslation();

    const infoText = t(`PROBABILITY_OF_SUCCESS_HELPER_` + goalPriority?.toUpperCase());

    return (
        <Container>
            <Border color={color} />
            <Header>
                {!isOnTarget && (
                    <AttentionIcon
                        width={18}
                        height={18}
                        color={color}
                        style={{ marginRight: '8px', color }}
                    />
                )}
                <Typography variant="body.overline" sx={{ textTransform: 'uppercase', color }}>
                    {t(isOnTarget ? 'PLAN_IS_ON_TARGET' : 'PLAN_NEEDS_ATTENTION')}
                </Typography>
            </Header>
            <Description>
                <Typography variant="body.large" sx={{ display: 'ruby' }}>
                    {t(isOnTarget ? 'PLAN_ON_TARGET_DESC' : 'PLAN_INCREASE_MORE_GOAL')}

                    <InfoTooltip message={infoText} />
                </Typography>
            </Description>

            {!isOnTarget && (
                <Typography
                    variant="body.large"
                    t="THREADSHOLD_VALUE_MESSAGE"
                    tProps={{ goalType: goalPriority, thresholdValue }}
                ></Typography>
            )}
        </Container>
    );
};

const Container = styled('div')(() => ({
    position: 'relative',
    left: 0,
    width: '308px',
    paddingLeft: '12px',
    paddingBottom: '2px',
    marginBottom: '20px'
}));

const Border = styled('span')<{ color: string }>(({ color }) => ({
    background: color,
    width: '4px',
    height: '100%',
    borderRadius: '15px',
    position: 'absolute',
    left: 0,
    top: 0
}));

const Header = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px'
}));

const Description = styled('div')(() => ({
    marginBottom: '10px',
    display: 'block'
}));

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function BreadcrumbsComponent({ breadcrumbs }: any) {
    return (
        <Stack sx={{ margin: '20px 0' }}>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" sx={{ fontSize: '22px' }} />}
                aria-label="breadcrumb"
            >
                {breadcrumbs}
            </Breadcrumbs>
        </Stack>
    );
}

export default BreadcrumbsComponent;
import { useAppSelector } from '../../../common/hooks';

interface CurrencyFormatterPropsType {
    amount: number;
}

const CurrencyFormatter = ({ amount }: CurrencyFormatterPropsType) => {
    const { countryCode, currencySymbol, language } = useAppSelector((state) => state.global.globalConfig);

    let currency = `${currencySymbol === ' ' ? 'USD' : currencySymbol}`;
    let locale = `${language}-${countryCode === ' ' ? 'US' : countryCode}`;
    let maximumFractionDigits = 0;

    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        maximumFractionDigits
    });

    return <>{formatter.format(amount)}</>;
};

export default CurrencyFormatter;

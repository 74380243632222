import { Theme } from '@mui/material';

export default function Radio(theme: Theme) {
    return {
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: '#ccc',
                    paddingTop: 0,
                    paddingBottom: 0,
                    '&.Mui-checked': {
                        color: theme.palette.common.black
                    },
                    '&.Mui-disabled': {
                        color: theme.palette.action.disabled
                    }
                }
            }
        }
    };
}

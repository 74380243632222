import { Logo, Typography } from '../../atoms';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PATPalette } from '../../../themes/palette';

export interface PDFHeaderProps {
    pageName?: string;
}

const PDFPageTitle = styled('span')(() => ({
    display: 'flex',
    alignItems: 'center'
}));

const PDFPageHeading = styled('span')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
}));

export const PDFHeader = ({ pageName }: PDFHeaderProps) => {
    const { t } = useTranslation();
    return (
        <div className="proposal-pdf pdf-content-container hidden-pdf-container">
            <div id="hidden-pdf-page" className="pdf-page">
                <PDFPageHeading>
                    <PDFPageTitle>
                        <Typography
                            color="primary.skyBlue"
                            variant="body.small"
                            label={`${t('GOE_FULL_TEXT')} /`}
                            sx={{ paddingRight: '8px' }}
                        />
                        <Typography color="primary.skyBlue" variant="body.smallDemibold" label={pageName || ''} />
                    </PDFPageTitle>
                    <Logo color={PATPalette.primary.skyBlue} includeBranding={false} />
                </PDFPageHeading>
            </div>
        </div>
    );
};

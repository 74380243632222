import { Icon, TextboxInput } from '../../../atoms';
import { PATPalette } from '../../../../themes/palette';
import roundedClose from '../../../../assets/icons/rounded_close.svg';
import styled from '@emotion/styled';
import { AdminFilterDropdown, OptionType } from '../Dropdown';
import { useEffect, useRef, useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';

type Props = {
    // eslint-disable-next-line
    onChange(value?: OptionType<string>): void;
    value?: OptionType;
    clients: OptionType<string>[];
};

export const SearchInput = ({ onChange, value, clients }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isActive = !!anchorEl;

    const [text, setText] = useState('');

    useEffect(() => {
        setText(value?.label ?? '');
    }, [value]);

    const clientsOptions = clients.filter(({ value }) => value?.startsWith(text?.toUpperCase()));

    const handleChange = (value?: OptionType<string>) => {
        onChange(value);

        setAnchorEl(null);
    };

    const clear = () => {
        setText('');
        handleChange(undefined);
    };

    const inputContainerRef = useRef<null | HTMLDivElement>(null);

    const handleOpen = () => {
        setAnchorEl(inputContainerRef.current);
    };

    return (
        <>
            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <div>
                    <InputContainer isDropdownActive={isActive} ref={inputContainerRef}>
                        <TextboxInput
                            fullWidth
                            onFocus={handleOpen}
                            InputProps={{
                                startAdornment: (
                                    <Icon id="search" size={15} fill={PATPalette.primary.skyBlue} />
                                ),
                                endAdornment: text?.length ? (
                                    <ClearButton type="button" onClick={clear}>
                                        <img src={roundedClose} />
                                    </ClearButton>
                                ) : null
                            }}
                            value={text}
                            placeholder="Search by Client Name"
                            onChange={(e) => {
                                setText(e.target.value);
                            }}
                            autoComplete="off"
                        />
                    </InputContainer>

                    <AdminFilterDropdown
                        placement="bottom-start"
                        maxHeight={180}
                        onClose={() => {
                            // doesn't do anything on purpose
                        }}
                        options={clientsOptions}
                        onSelectOption={handleChange}
                        filterText={text}
                        selectedOption={value?.value}
                        anchorEl={anchorEl}
                        width={anchorEl?.getBoundingClientRect?.()?.width}
                        disableClickAway
                    />
                </div>
            </ClickAwayListener>
        </>
    );
};

const InputContainer = styled('div')<{ isDropdownActive: boolean }>(
    ({ isDropdownActive = false, theme }) => ({
        position: 'relative',
        input: {
            paddingLeft: '3px !important',
            color: '#000',
            ...theme.typography.tables.rows,
            ':placeholder': {
                color: '#000 !important'
            }
        },
        ...(isDropdownActive && {
            zIndex: 130
        })
    })
);

const ClearButton = styled('button')(() => ({
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    width: '17px',
    height: '17px',

    '> img': {
        width: '17px',
        height: '17px'
    }
}));

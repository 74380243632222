import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Typography, styled } from '@mui/material';
import { PATPalette } from '../../../themes/palette';

export interface SpinnerProps {
    enabled?: boolean;
    message?: string;
    size?: 'small' | 'large';
}

export const Spinner = ({ enabled, message, size = 'large' }: SpinnerProps) => {
    if (!enabled) {
        return null;
    }

    const sizePixels = size === 'small' ? 40 : 64;

    return (
        <StyledSpinner sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Stack spacing={2} direction="row">
                <Box sx={{ position: 'relative' }}>
                    <CircularProgress
                        variant="determinate"
                        sx={{
                            color: () => PATPalette.neutral.grey[200]
                        }}
                        size={sizePixels}
                        thickness={4}
                        value={100}
                    />
                    <CircularProgress
                        size={sizePixels}
                        thickness={4}
                        sx={{
                            position: 'absolute',
                            left: 0,
                            color: () => PATPalette.primary.skyBlue
                        }}
                    />
                </Box>
            </Stack>
            <Typography variant="h5">{message}</Typography>
        </StyledSpinner>
    );
};

const StyledSpinner = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'rgba(255,255,255,0.4)',
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0
}));

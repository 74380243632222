import emotionStyled from '@emotion/styled';
import { PATPalette } from '../../themes/palette';

type Props = {
    isDisabled: boolean;
    icon?: React.ReactNode | React.ReactNode[];
    size?: number;
};

export const HeaderIcon = ({ isDisabled, icon, size }: Props) => {
    return (
        <Container isDisabled={isDisabled} size={size}>
            {icon}
        </Container>
    );
};

const Container = emotionStyled('div')<{ isDisabled: boolean; size?: number }>(
    ({ isDisabled, size = 32 }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: size,
        height: size,
        borderRadius: '100%',
        background: isDisabled ? PATPalette.neutral.grey[100] : PATPalette.secondary.skyBlue[100],

        '> svg': {
            color: isDisabled ? PATPalette.neutral.grey[300] : PATPalette.secondary.skyBlue[400]
        }
    })
);

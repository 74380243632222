import Typography from '@mui/material/Typography';
import AddFinancialUser from '../../containers/add-financial-user';
import BreadcrumbsComponent from '../../components/molecules/Breadcrumbs/Breadcrumbs';
import { Container } from '@mui/material';

const FinancialAdvisor = () => {
    const breadcrumbs = [
        <Typography
            key="1"
            variant="body1"
            sx={{
                color: 'primary.main',
                fontSize: '22px',
                cursor: 'pointer',
                '&:hover': {
                    textDecoration: 'underline 1px',
                    textUnderlineOffset: '3px'
                }
            }}
        >
            Dashboard
        </Typography>,
        <Typography key="2" variant="body1" sx={{ color: 'primary.main', fontSize: '22px' }}>
            Add New Advisor
        </Typography>
    ];
    return (
        <Container>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} />
            <AddFinancialUser />
        </Container>
    );
};

export default FinancialAdvisor;

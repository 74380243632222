import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material';
import React from 'react';
import { Icon } from '../Icon/Icon';

export interface IconButtonProps extends MuiIconButtonProps {
    iconName: React.ReactNode;
    disabled?: boolean;
    arialabel?: string;
    bordered?: boolean;
}

export const IconButton = ({ iconName, arialabel, disabled = false, bordered = false, ...rest }: IconButtonProps) => {
    return (
        <MuiIconButton
            disabled={disabled}
            sx={{ border: bordered ? `2px solid #aaa` : '', borderRadius: bordered ? '50px' : '' }}
            aria-label={arialabel}
            {...rest}
        >
            {typeof iconName === 'string' ? <Icon id={iconName} /> : iconName}
        </MuiIconButton>
    );
};

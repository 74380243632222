import { Theme } from '@mui/material';

export default function Paper(theme: Theme) {
    return {
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: theme.spacing(1),
                    boxShadow: `0px 2px 8px 0px rgba(0, 0, 0, 0.05) !important`
                },
                paper: {
                    borderRadius: theme.spacing(1)
                }
            }
        }
    };
}

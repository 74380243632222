import { Theme } from '@mui/material';

export default function Checkbox(theme: Theme) {
    return {
        MuiCheckbox: {
            defaultProps: {
                disableRipple: true,
                backgroundColor: theme.palette.primary.main
            }
        }
    };
}

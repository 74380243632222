import { Theme } from '@mui/material';

export default function Tab(theme: Theme) {
    return {
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    padding: `${theme.spacing(3)} 0`
                }
            }
        }
    };
}

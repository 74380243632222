import { useEffect, useState } from 'react';
// TODO:: Import Typography from /atoms/Typography
import { Box, styled, Typography, typographyClasses } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../common/hooks';
import { getDashboardMetrics } from '../../../features/advisor-admin';

const SummaryMetricsContainer = styled(Box)(() => ({
    width: '100%',
    height: '175px',
    border: '1px solid #E5E5E5',
    display: 'flex'
}));

const MetricsContainer = styled(Box)(() => ({
    width: '70%',
    height: '175px',
    display: 'flex',
    justifyContent: 'space-around'
}));

const StyledBox = styled(Box)(() => ({
    width: '189px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}));

const StyledMetric = styled(Typography)(() => ({
    color: '#3769FF',
    fontSize: '30px',
    fontWeight: 500,
    lineHeight: '16px'
}));

const StyledMetricLabel = styled(Typography)(() => ({
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '16px',
    paddingTop: '25px',
    color: '#797979'
}));

const StyledTypography = styled(Typography)(() => ({
    [`&.${typographyClasses.root}`]: {
        fontSize: '22px',
        lineHeight: '16px',
        fontWeight: 600,
        color: '#000000',
        margin: '30px 0 20px 0'
    }
}));

interface SummaryMetricsTypes {
    clients: number;
    goals: number;
    new_clients: number;
}

export default function SummaryMetrics() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [metrics, setMetrics] = useState<SummaryMetricsTypes | null>(null);

    useEffect(() => {
        (async () => {
            const res = await dispatch(getDashboardMetrics());

            setMetrics(res.payload);
        })();
    }, []);

    return (
        <>
            <StyledTypography variant="h2">{t('SUMMARY_METRICS_HEADER')}</StyledTypography>
            <SummaryMetricsContainer>
                <MetricsContainer>
                    <StyledBox>
                        <StyledMetric>{metrics?.clients || 'N/A'}</StyledMetric>
                        <StyledMetricLabel>{t('SUMMARY_METRICS_NO_OF_CLIENTS')}</StyledMetricLabel>
                    </StyledBox>
                    <StyledBox>
                        <StyledMetric>{metrics?.goals || 'N/A'}</StyledMetric>
                        <StyledMetricLabel>{t('SUMMARY_METRICS_TOTAL_GOALS')}</StyledMetricLabel>
                    </StyledBox>
                    <StyledBox>
                        <StyledMetric>{metrics?.new_clients || 'N/A'}</StyledMetric>
                        <StyledMetricLabel>{t('SUMMARY_METRICS_NEW_CLIENTS')}</StyledMetricLabel>
                    </StyledBox>
                </MetricsContainer>
            </SummaryMetricsContainer>
        </>
    );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Tab, { TabProps } from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList, { TabListProps } from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/system';
import { Grid } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import palette, { PATPalette } from '../../../themes/palette';
import { GoalType } from '../GoalType/GoalType';
import { GoalTypeEnum } from 'common/types';

type VariantType = {
    variant: TabVariant;
};

export const StyledTabList = styled(TabList)<TabListProps & { showBorderBottom: boolean }>(({ showBorderBottom }) => ({
    display: 'flex',
    width: '100%',
    borderBottom: showBorderBottom ? `1px solid ${PATPalette.neutral.grey[200]}` : '0'
}));

const StyledTab = styled(Tab)<TabProps & VariantType>(({ theme, variant }) => ({
    ...(variant === TabVariant.PRIMARY_TAB && {
        '&.Mui-selected': {
            backgroundColor: palette.primary.extraLight
        },
        color: theme.palette.primary.dark
    }),
    ...(variant === TabVariant.GOALS_TAB && {
        '&:not(:last-child)': {
            borderRight: '1px solid #ccc'
        },
        color: theme.palette.secondary.main,
        '&.Mui-selected': {
            color: theme.palette.primary.dark
        }
    }),
    ...(variant === TabVariant.COMPACT_TAB && {
        color: theme.palette.secondary.main,
        '&.Mui-selected': {
            color: theme.palette.primary.dark
        },
        padding: 0,
        margin: 0,
        marginRight: theme.spacing(4),
        maxWidth: 'max-content',
        alignItems: 'flex-start'
    }),
    fontSize: '1.25rem',
    textTransform: 'none',
    flexGrow: 1
}));

const StyledLimitedText = styled('span')({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100%'
});

export enum TabVariant {
    // eslint-disable-next-line no-unused-vars
    PRIMARY_TAB = 'primary',
    // eslint-disable-next-line no-unused-vars
    GOALS_TAB = 'goalsTab',
    // eslint-disable-next-line no-unused-vars
    COMPACT_TAB = 'compactTab'
}

export interface TabsProps {
    tabPanels: {
        enabled: boolean;
        title: string;
        hidden: boolean;
        children: React.ReactNode;
        type?: GoalTypeEnum;
        needFix?: boolean;
    }[];
    value?: string;
    // eslint-disable-next-line no-unused-vars
    onChange?: (event: React.SyntheticEvent, newValue: string) => void;
    variant?: TabVariant;
}

const GoalsTab = ({
    title,
    type,
    needFix,
    shorten
}: {
    title: string;
    type?: GoalTypeEnum;
    needFix: boolean;
    shorten: boolean;
}) => {
    return (
        <Grid display="flex" alignItems="center" gap={1}>
            <Grid display="flex" alignItems="center" justifyContent="center" width={32} height={32} borderRadius="50%">
                {type && <GoalType type={type} />}
            </Grid>
            {shorten ? (
                <StyledLimitedText>{title.length > 6 ? title.slice(0, 6) + '...' : title}</StyledLimitedText>
            ) : (
                <span>{title}</span>
            )}

            {needFix && <InfoIcon sx={{ width: '16px', height: '16px', color: palette.warning.dark }} />}
        </Grid>
    );
};

export const Tabs = ({ tabPanels, onChange, value = '0', variant = TabVariant.PRIMARY_TAB }: TabsProps) => {
    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <StyledTabList
                    onChange={onChange}
                    aria-label="tab lists"
                    showBorderBottom={variant === TabVariant.COMPACT_TAB}
                >
                    {tabPanels
                        .filter(({ hidden }) => !hidden)
                        .map(({ title, enabled, type, needFix }, index) => {
                            return (
                                <StyledTab
                                    variant={variant}
                                    label={
                                        variant === TabVariant.PRIMARY_TAB || variant === TabVariant.COMPACT_TAB ? (
                                            title
                                        ) : (
                                            <GoalsTab
                                                title={title}
                                                type={type}
                                                needFix={!!needFix}
                                                shorten={tabPanels.length > 3}
                                            />
                                        )
                                    }
                                    key={`${title}-${index}`}
                                    value={index.toString()}
                                    disabled={!enabled}
                                />
                            );
                        })}
                </StyledTabList>

                {tabPanels
                    .filter(({ hidden }) => !hidden)
                    .map(({ children, title }, index) => (
                        <TabPanel key={`${title}-${index}`} value={index.toString()}>
                            {children}
                        </TabPanel>
                    ))}
            </TabContext>
        </Box>
    );
};

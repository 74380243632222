import { useEffect } from 'react';
import { useAppSelector } from '../../common/hooks';
import { Spinner } from '../../components/atoms/Spinner/Spinner';
import ReviewPlanDetails from '../../containers/review-plan-details';

export const ReviewPlanDetailsStep = ({ updateStep }: any) => {
    const adviceGoals = useAppSelector((state) => state.adviceGoals);
    useEffect(() => {
        window.history.replaceState(null, '', '/reviewplan');
    }, []);
    return adviceGoals?.response?.length > 0 ? (
        <ReviewPlanDetails updateStep={updateStep} />
    ) : (
        <Spinner enabled={true} />
    );
};

import { useTranslation } from 'react-i18next';
import { DrawerComp } from '../../components/molecules';
import FundingSource from './FundingSource';
import { ReactComponent as CashIcon } from '../../assets/icons/cash.svg';

const FundingSourcesList = (props: any) => {
    const { openDrawer, setOpenDrawer } = props;
    const { t } = useTranslation();
    return (
        <DrawerComp
            open={openDrawer}
            header={t('HEADER_FUNDING_SOURCES_LIST')}
            drawerIcon={<CashIcon />}
            handleClose={(flag) => {
                setOpenDrawer(flag);
            }}
            onSubmit={() => setOpenDrawer(false)}
            buttonlabel={t('CLOSE_LABEL')}
            askConfirm={false}
        >
            <FundingSource />
        </DrawerComp>
    );
};

export default FundingSourcesList;

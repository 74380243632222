import sortingArrowDown from '../../../../assets/icons/sorting_arrow_down.svg';
import styled from '@emotion/styled';

const Thead = styled('thead')(() => ({
    background: '#F1F5FB',
    border: 'none',
    height: '56px'
}));

const Th = styled('th')<{ width: number }>(({ theme, width }) => ({
    background: '#F1F5FB',
    border: 'none',
    paddingLeft: '16px',
    textAlign: 'left',
    width,
    ...theme.typography.tables.headers
}));

export const Header = () => {
    return (
        <Thead>
            <tr>
                <Th width={72}></Th>
                <Th width={114}>STATUS</Th>
                <Th width={200} style={{ paddingLeft: 0 }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px'
                        }}
                    >
                        <img width={12} height={12} src={sortingArrowDown} alt="Sort" />
                        CLIENT / GOAL NAME
                    </div>
                </Th>
                {/* <Th width={120}>PORTFOLIO APPROACH</Th> */}
                <Th width={120}>RISK / GOAL PRIORITY</Th>
                <Th width={120}>PORTFOLIO VALUE</Th>
                <Th width={112}>NUMBER OF GOALS</Th>
                <Th width={150}>PROBABILITY OF SUCCESS</Th>
                <Th width={150}>EQUITY / FIXED INCOME</Th>
                <Th width={150}>PLAN CREATION DATE</Th>
                <Th width={122} style={{ textAlign: 'center', padding: '0 24px' }}>
                    ACTIONS
                </Th>
            </tr>
        </Thead>
    );
};

import { styled } from '@mui/material/styles';
import { getInitials } from '../../../utils';
import PersonIcon from '@mui/icons-material/Person';
import './avatar.component.scss';
import { PATPalette } from '../../../themes/palette';

export interface AvatarProps {
    enabled?: boolean;
    name: string;
    size?: 'large' | 'medium' | 'small';
}

export const Avatar = ({ name, size = 'large', enabled = true }: AvatarProps) => {
    const StyledmenuItemLogo = styled('div')(({ theme }) => ({
        width: size === 'large' ? '80px' : size === 'medium' ? '50px' : '32px',
        height: size === 'large' ? '80px' : size === 'medium' ? '50px' : '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: size === 'large' ? '30px' : size === 'medium' ? '18px' : '12px',
        fontWeight: '600',
        borderRadius: '50%',
        background: theme.palette.primary.light,
        color: PATPalette.primary.skyBlue
    }));

    const renderAvatar = (name: string) => {
        if (enabled && name && name !== '') {
            return <StyledmenuItemLogo>{getInitials(name)}</StyledmenuItemLogo>;
        }
        return (
            <StyledmenuItemLogo>
                <PersonIcon fontSize="large" />
            </StyledmenuItemLogo>
        );
    };
    return <div className="avatar-container">{renderAvatar(name)}</div>;
};
